import React from 'react';
import './cockpitLamp.css';

function CockpitLamp({ icon, isActive, lampName }) {
    return (
        <div className="text-center">
            <div className={"light-display " + (isActive == 1 ? "active" : "inactive")} title={lampName}>
                <span className={"icon " + icon} />
            </div>
        </div>
    );
}

export default CockpitLamp;