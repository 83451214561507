import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "/api/dashboard";

const DashboardService = {

    getFleetOverview: function () {
        return axiosInstance.get(
            `${URL_API}/fleetoverview`
        );
    },

    getLatestErrors: function () {
        return axiosInstance.get(
            `${URL_API}/latesterrors`
        );
    },

    getSeenVehiclesHistory: function (since, until) {
        return axiosInstance.get(
            `${URL_API}/seenvehicleshistory`,
            {
                params: {
                    since: since,
                    until: until
                }
            }
        );
    },

    getTotalErrorsHistory: function (since, until) {
        return axiosInstance.get(
            `${URL_API}/totalerrorshistory`,
            {
                params: {
                    since: since,
                    until: until
                }
            }
        );
    }

}

export default DashboardService;