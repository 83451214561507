import { loadMessages, locale } from 'devextreme/localization';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';

const languageUtils = {

    getLocale: function () {
        var language = localStorage.getItem('locale');

        if (language === 'en-GB') {
            moment.locale('en-gb');
        } else if(language === 'de-DE') {
            moment.locale('de');
        }

        return language;
    },

    setLocale: function (language) {
        localStorage.setItem('locale', language);

        if (language === 'en-GB') {
            moment.locale('en-gb');
        } else {
            moment.locale('de');
        }
    },

    loadDictionaries: function (dictionaries, language) {
        for (var i = 0; i < dictionaries.length; i++) {
            loadMessages(dictionaries[i]);
        }

        locale(language);
    },

    stringFormat: function (message, str1, str2) {
        var result = message.replace("{0}", str1);

        if (str2 != null) {
            result = result.replace("{1}", str2);
        }

        return result;
    },

    localizeNumber: function (number, language) {
        return number.toLocaleString(language);
    }
}

export default languageUtils;