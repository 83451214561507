const telltaleNames = {
    "en-GB": {
        "1": "Cooling air conditioning",
        "2": "High beam, main beam",
        "3": "Low beam, dipped beam",
        "4": "Turn signals",
        "5": "Hazard warning",
        "6": "Provision for the disabled or handicapped persons",
        "7": "Parking Brake",
        "8": "Brake failure/brake system malfunction",
        "9": "Hatch open",
        "10": "Fuel level",
        "11": "Engine coolant temperature",
        "12": "Battery charging condition",
        "13": "Engine oil",
        "14": "Position lights,side lights",
        "15": "Front fog light",
        "16": "Rear fog light",
        "17": "Park Heating",
        "18": "Engine / Mil indicator",
        "19": "Service, call for maintenance",
        "20": "Transmission fluid temperature",
        "21": "Transmission failure/malfunction",
        "22": "Anti-lock brake system failure",
        "23": "Worn brake linings",
        "24": "Windscreen washer fluid/windshield washer fluid",
        "25": "Tire failure/malfunction",
        "26": "Malfunction/general failure",
        "27": "Engine oil temperature",
        "28": "Engine oil level",
        "29": "Engine coolant level",
        "30": "Steering fluid level",
        "31": "Steering failure",
        "32": "Height Control (Levelling)",
        "33": "Retarder",
        "34": "Engine Emission system failure (Mil indicator)",
        "35": "ESC indication",
        "36": "Brake lights",
        "37": "Articulation",
        "38": "Stop Request",
        "39": "Pram request",
        "40": "Bus stop brake",
        "41": "AdBlue level",
        "42": "Raising",
        "43": "Lowering",
        "44": "Kneeling",
        "45": "Engine compartment temperature",
        "46": "Auxillary air pressure",
        "47": "Air filter clogged",
        "48": "Fuel filter differential pressure",
        "49": "Seat belt",
        "50": "EBS",
        "51": "Lane departure indication",
        "52": "Advanced emergency braking system",
        "53": "ACC",
        "54": "Trailer connected",
        "55": "ABS Trailer 1,2",
        "56": "Airbag",
        "57": "EBS Trailer 1,2",
        "58": "Tachograph indication",
        "59": "ESC switched off",
        "60": "Lane departure warning switched off",
        "61": "Engine emission filter (Soot Filter)",
        "62": "Electric motor failures",
        "63": "AdBlue tampering",
        "64": "Multiplex System",
        "65": "Battery pack",
        "66": "High voltage system caution",
        "67": "Battery pack temperature",
        "68": "Limited performance electric motor",
        "69": "Battery pack cooling",
        "70": "Reserved for FMS-Standard",
        "71": "Reserved for FMS-Standard",
        "72": "Reserved for FMS-Standard",
        "73": "Reserved for FMS-Standard",
        "74": "Reserved for FMS-Standard",
        "75": "Reserved for FMS-Standard"
    },
    "de-DE": {
        "1": "Klimaanlage kühlen",
        "2": "Fernlicht",
        "3": "Abblendlicht",
        "4": "Blinker",
        "5": "Warnblinklicht",
        "6": "Vorsorge für Menschen mit körperlicher Einschränkung",
        "7": "Parkbremse",
        "8": "Störung Bremssystem",
        "9": "Heckklappe offen",
        "10": "Tankfüllstand",
        "11": "Motorkühltemperatur",
        "12": "Batterieladezustand",
        "13": "Motorölstand",
        "14": "Stand- und Seitenlichter",
        "15": "Nebelscheinwerfer vorne",
        "16": "Nebelscheinwerfer hinten",
        "17": "Standheizung",
        "18": "Motorkontrollleuchte",
        "19": "Service nötig, Werkstatt benachrichtigen",
        "20": "Getriebeöltemperatur",
        "21": "Störung Getriebe",
        "22": "Störung Antiblockiersystem (ABS)",
        "23": "Verschleiß Bremsbeläge",
        "24": "Scheibenwaschwasser",
        "25": "Störung Reifen",
        "26": "Störung, Prüfung notwendig",
        "27": "Motoröltemperatur",
        "28": "Füllstand Motoröl",
        "29": "Füllstand Kühlflüssigkeit",
        "30": "Füllstand Servolenkflüssigkeit",
        "31": "Störung Lenkung",
        "32": "(Niveau-) Höhenkontrolle",
        "33": "Kupplung",
        "34": "Störung Abgassystem",
        "35": "Warnung Elektronisches Stabilisierungssystem ESC",
        "36": "Bremsleuchten",
        "37": "Gelenkverbindung",
        "38": "Nach Möglichkeit stoppen",
        "39": "Kinderwagen berücksichtigen",
        "40": "Handbremse",
        "41": "Füllstand AdBlue",
        "42": "Erhöhen",
        "43": "Ablassen",
        "44": "Absenken",
        "45": "Motorraumtemperatur",
        "46": "Drucklevel Zusatzluft",
        "47": "Luftfilter verschmutzt",
        "48": "Drucklevel Kraftstoffdifferenzial",
        "49": "Sicherheitsgurt",
        "50": "Elektronisches Bremssystem",
        "51": "Spurhaltewarnung",
        "52": "Notbremssystem (AEBS)",
        "53": "Abstandsgeregelter Tempomat (AAC)",
        "54": "Anhänger angeschlossen",
        "55": "Antiblockiersystem (ABS), Anhänger 1,2",
        "56": "Airbag",
        "57": "Elektronisches Bremssystem (EBS), Anhänger 1,2",
        "58": "Warnung Tachograph",
        "59": "Elektronische Stabilitätsregelung (ESC) ausgeschaltet",
        "60": "Spurhalteassistent ausgeschaltet",
        "61": "Abgas- / Rußfilter",
        "62": "Störung Elektrischer Motor",
        "63": "AdBlue Manipulation",
        "64": "Multiplex-System",
        "65": "Akkupack",
        "66": "Vorsicht Hochspannungssystem",
        "67": "Akkutemperatur",
        "68": "Elektromotor mit eingeschränkter Leistung",
        "69": "Akku-Kühlung",
        "70": "Reserviert für FMS-Standard",
        "71": "Reserviert für FMS-Standard",
        "72": "Reserviert für FMS-Standard",
        "73": "Reserviert für FMS-Standard",
        "74": "Reserviert für FMS-Standard",
        "75": "Reserviert für FMS-Standard",
    }
};

export default telltaleNames;