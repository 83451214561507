const feedbackMessages = {
    "en-GB": {
        // C
        "Confirm_Delete": "Do you wish to delete {0}?", // {0}: Element name

        // F
        "Failure_ConnectBroker": "Could not connect to the Broker.",
        "Failure_DataRetrieve": "Could not retrieve data.",
        "Failure_DataRetrieveForWidget": "Could not retrieve data for {0}.", // {0}: Widget name
        "Failure_Delete": "Could not delete {0}.", // {0}: Element name
        "Failure_Login": "Could not log you in.",
        "Failure_Save": "Could not save {0}.", // {0}: Element name
        "Failure_Update": "Could not update {0}.", // {0}: Element name

        // S
        "Success_Delete": "{0} was successfully deleted.", // {0}: Element name
        "Success_Save": "{0} was successfully saved.", // {0}: Element name
        "Success_Update": "{0} was successfully updated.", // {0}: Element name

        // W
        "Warning_AssociatedSchedules": "The selected Filter has Schedules associated and cannot be deleted.",
        "Warning_CultureChange": "You will change the language for this web application. This action will reload the current page. If you have other pages opened, please refresh those pages to fully change the display language. Do you want to continue this action?",
        "Warning_RequiredReportName": "You must provide a Report name.",
        "Warning_RequiredUsers": "You must provide at least one User.",
    },
    "de-DE": {
        // C
        "Confirm_Delete": "Möchte Sie {0} löschen?",

        // F
        "Failure_ConnectBroker": "Keine Verbindung zum Broker",
        "Failure_DataRetrieve": "Daten konnten nicht abgerufen werden.",
        "Failure_DataRetrieveForWidget": "Daten für {0} konnten nicht abgerufen werden.", // {0}: Widget name
        "Failure_Delete": "Konnte {0} nicht löschen.",
        "Failure_Login": "Fehler beim einloggen.",
        "Failure_Save": "Konnte {0} nicht speichern.",
        "Failure_Update": "Konnte {0} nicht aktualisieren.",

        // S
        "Success_Delete": "{0} erfolgreich gelöscht.",
        "Success_Save": "{0} erfolgreich gespeichert.",
        "Success_Update": "{0} erfolgreich aktualisiert.",

        // W
        "Warning_AssociatedSchedules": "Der ausgewählte Filter ist mit Zeitplänen verknüpft und kann nicht gelöscht werden.",
        "Warning_CultureChange": "Ihre Anzeigesprache wird geändert, die aktuelle Seite wird neu geladen. Wenn Sie noch weitere Seiten geöffnet haben, müssen Sie diese zum Übernehmen der neuen Sprache manuell aktualisieren. Wollen Sie fortfahren?",
        "Warning_RequiredReportName": "Ein Name muss angegeben werden",
        "Warning_RequiredUsers": "Mindestens ein Benutzer muss angegeben werden.",
    }
};

export default feedbackMessages;