import React, { useContext, useEffect, useState } from 'react';
import ReportsService from '../../../services/reports.service';
import TokenContext from '../../../utils/contexts/token-context';
import DataGrid, { Column, Paging, Scrolling, Sorting, Selection, Editing, Lookup } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import { formatMessage } from 'devextreme/localization';
import UserService from '../../../services/user.service';
import List from 'devextreme-react/list';
import ArrayStore from 'devextreme/data/array_store';
import devextremeUtils from '../../../utils/configs/devextremeUtils';
import languageUtils from '../../../utils/configs/languageUtils';
import DataSource from 'devextreme/data/data_source';

function EmailAlert({
    getEmailReports,
    selectedReportId,
    setSelectedReportId
}) {

    const tokenObj = useContext(TokenContext);

    const dataGridRef_Dtc = React.createRef();
    const dataGridRef_Measurement = React.createRef();
    const dataGridRef_String = React.createRef();

    const [listOfDtcs, setListOfDtcs] = useState([]);
    const [listOfMeasurements, setListOfMeasurements] = useState([]);
    const [listOfStrings, setListOfStrings] = useState([]);

    const [dataSource_Dtc, setDataSource_Dtc] = useState([]);
    const [dataSource_Measurement, setDataSource_Measurement] = useState([]);
    const [dataSource_String, setDataSource_String] = useState([]);
    const [dataSource_Users, setDataSource_Users] = useState([]);
    const [emailReportName, setEmailReportName] = useState("");

    const [selectedItemKeys_Users, setSelectedItemKeys_Users] = useState([]);
    const [selectedRowKeys_Dtc, setSelectedRowKeys_Dtc] = useState([]);
    const [selectedRowKeys_Measurement, setSelectedRowKeys_Measurement] = useState([]);
    const [selectedRowKeys_String, setSelectedRowKeys_String] = useState([]);

    const dataSource_Conditions = [
        {
            "id": 0,
            "label": formatMessage("Ignore")
        },
        {
            "id": 1,
            "label": formatMessage("Blue")
        },
        {
            "id": 2,
            "label": formatMessage("Orange")
        },
        {
            "id": 3,
            "label": formatMessage("Red")
        }
    ];

    useEffect(() => {
        getListOfDtcs();
        getListOfMeasurements();
        getListOfStrings();
        getListOfUsers();
    }, [tokenObj.token]);

    useEffect(() => {
        if (selectedReportId !== null) {
            getEmailReport(selectedReportId);
        } else {
            cleanFormFields();
        }
    }, [selectedReportId]);

    function getListOfDtcs() {
        ReportsService.getListOfDtcs()
            .then(response => {
                let responseData = response.data;

                setListOfDtcs(responseData.data);

                const dataSource = new DataSource({
                    store: new ArrayStore({
                        data: responseData.data,
                        key: 'messageText'
                    })
                });

                setDataSource_Dtc(dataSource);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Faults: DTCs.");
                }
            });
    }

    function getListOfMeasurements() {
        ReportsService.getListOfMeasurements()
            .then(response => {
                let responseData = response.data;

                setListOfMeasurements(responseData.data);

                const dataSource = new DataSource({
                    store: new ArrayStore({
                        data: responseData.data,
                        key: 'messageText'
                    })
                });

                setDataSource_Measurement(dataSource);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Faults: Measurements.");
                }
            });
    }

    function getListOfStrings() {
        ReportsService.getListOfStrings()
            .then(response => {
                let responseData = response.data;

                setListOfStrings(responseData.data);

                const dataSource = new DataSource({
                    store: new ArrayStore({
                        data: responseData.data,
                        key: 'messageText'
                    })
                });

                setDataSource_String(dataSource);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Faults: Strings.");
                }
            });
    }

    function getListOfUsers() {
        UserService.getUsers()
            .then(response => {
                let responseData = response.data;

                let source = [];

                for (let i = 0; i < responseData.length; i++) {
                    source.push({
                        'id': responseData[i].userId,
                        'text': responseData[i].userShortName
                    })
                };

                const dataSource = new ArrayStore({
                    key: 'id',
                    data: source
                });

                setDataSource_Users(dataSource);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Users.");
                }
            });
    }

    function getEmailReport(reportId) {
        ReportsService.getEmailReport(reportId)
            .then(response => {
                let responseData = response.data;

                setEmailReportName(responseData.reportName);

                let selectedUsers = responseData.users.map((user) => user.userId);
                setSelectedItemKeys_Users(selectedUsers);

                // #region DTCs
                let selectedDtcs = responseData.faults.dtc;

                let allDtcs = listOfDtcs;

                for (let i = 0; i < selectedDtcs.length; i++) {
                    for (let j = 0; j < allDtcs.length; j++) {
                        if (selectedDtcs[i].messageText === allDtcs[j].messageText) {
                            allDtcs[j] = selectedDtcs[i];
                        }
                    }
                }

                const newDataSource_Dtc = new DataSource({
                    store: new ArrayStore({
                        data: allDtcs,
                        key: 'messageText'
                    })
                });
                setDataSource_Dtc(newDataSource_Dtc);

                let selectedDtcKeys = responseData.faults.dtc.map((measurement) => measurement.messageText);
                setSelectedRowKeys_Dtc(selectedDtcKeys);
                // #endregion

                // #region Measurements
                let selectedMeasurements = responseData.faults.measurement;

                let allMeasurements = listOfMeasurements;

                for (let i = 0; i < selectedMeasurements.length; i++) {
                    for (let j = 0; j < allMeasurements.length; j++) {
                        if (selectedMeasurements[i].messageText === allMeasurements[j].messageText) {
                            allMeasurements[j] = selectedMeasurements[i];
                        }
                    }
                }

                const newDataSource_Measurement = new DataSource({
                    store: new ArrayStore({
                        data: allMeasurements,
                        key: 'messageText'
                    })
                });
                setDataSource_Measurement(newDataSource_Measurement);

                let selectedMeasurementKeys = responseData.faults.measurement.map((measurement) => measurement.messageText);
                setSelectedRowKeys_Measurement(selectedMeasurementKeys);
                // #endregion

                // #region Strings
                let selectedStrings = responseData.faults.string;

                let allStrings = listOfStrings;

                for (let i = 0; i < selectedStrings.length; i++) {
                    for (let j = 0; j < allStrings.length; j++) {
                        if (selectedStrings[i].messageText === allStrings[j].messageText) {
                            allStrings[j] = selectedStrings[i];
                        }
                    }
                }

                const newDataSource_String = new DataSource({
                    store: new ArrayStore({
                        data: allStrings,
                        key: 'messageText'
                    })
                });
                setDataSource_String(newDataSource_String);

                let selectedStringKeys = responseData.faults.string.map((string) => string.messageText);
                setSelectedRowKeys_String(selectedStringKeys);
                // #endregion
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error(`Could not retrieve Email Report Id = ${reportId}.`);
                }
            });
    }

    function onValueChanged_Condition(cell, e) {
        cell.setValue(e.value);
    }

    function editCellRender_Condition(cell) {
        let onValueChanged = onValueChanged_Condition.bind(this, cell);
        return (
            <SelectBox
                defaultValue={cell.value}
                {...cell.column.lookup}
                onValueChanged={onValueChanged}
            />
        );
    }

    function onOptionChanged_Users(e) {
        let selectedOptions = [];

        if (e.name === 'selectedItemKeys') {
            selectedOptions = e.value;
            setSelectedItemKeys_Users(selectedOptions);
        }
    }

    function cleanReportEmailAlertPopup() {
        getEmailReports();
        cleanFormFields();
        setSelectedReportId(null);
    }

    function cleanFormFields() {
        setEmailReportName("");

        setSelectedItemKeys_Users([]);
        setSelectedRowKeys_Dtc([]);
        setSelectedRowKeys_Measurement([]);
        setSelectedRowKeys_String([]);

        getListOfDtcs();
        getListOfMeasurements();
        getListOfStrings();
        getListOfUsers();
    }

    function onSelectionChanged_Dtc(data) {
        setSelectedRowKeys_Dtc(data.selectedRowKeys);
    }

    function onSelectionChanged_Measurement(data) {
        setSelectedRowKeys_Measurement(data.selectedRowKeys);
    }

    function onSelectionChanged_String(data) {
        setSelectedRowKeys_String(data.selectedRowKeys);
    }

    function saveUpdateEmailReport(method) {
        const dataGridDtc = dataGridRef_Dtc.current.instance;
        const dataGridMeasurement = dataGridRef_Measurement.current.instance;
        const dataGridString = dataGridRef_String.current.instance;

        let selectedRowsData_Dtc = dataGridDtc.getSelectedRowsData();
        let selectedRowsData_Measurement = dataGridMeasurement.getSelectedRowsData();
        let selectedRowsData_String = dataGridString.getSelectedRowsData();

        let faultsList = [];

        for (let i = 0; i < selectedRowsData_Dtc.length; i++) {
            faultsList.push(selectedRowsData_Dtc[i]);
        }

        for (let i = 0; i < selectedRowsData_Measurement.length; i++) {
            faultsList.push(selectedRowsData_Measurement[i]);
        }

        for (let i = 0; i < selectedRowsData_String.length; i++) {
            faultsList.push(selectedRowsData_String[i]);
        }

        if (selectedItemKeys_Users.length === 0) {
            devextremeUtils.displayNotification(formatMessage("Warning_RequiredUsers"), 'warning');
        } else if (emailReportName === "") {
            devextremeUtils.displayNotification(formatMessage("Warning_RequiredReportName"), 'warning');
        } else {
            switch (method) {
                case "save":
                    ReportsService.addEmailReport(emailReportName, selectedItemKeys_Users, faultsList)
                        .then(response => {
                            devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Save"), formatMessage("EmailReport")), 'success');
                            cleanReportEmailAlertPopup();
                        })
                        .catch(error => {
                            if (error.response !== undefined && error.response.status !== 401) {
                                console.error("Could not save Email Report.");
                                devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Failure_Save"), formatMessage("EmailReport")), 'error');
                            }
                        });
                    break;

                case "update":
                    ReportsService.updateEmailReport(selectedReportId, emailReportName, selectedItemKeys_Users, faultsList)
                        .then(response => {
                            devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Update"), formatMessage("EmailReport")), 'success');
                            cleanReportEmailAlertPopup();
                        })
                        .catch(error => {
                            if (error.response !== undefined && error.response.status !== 401) {
                                console.error("Could not update Email Report.");
                                devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Failure_Update"), formatMessage("EmailReport")), 'error');
                            }
                        });
                    break;
            }
        }
    }

    function deleteEmailReport() {
        if (window.confirm(languageUtils.stringFormat(formatMessage("Confirm_Delete"), formatMessage("EmailReport")))) {
            ReportsService.deleteEmailReport(selectedReportId)
                .then(response => {
                    devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Delete"), formatMessage("EmailReport")), 'success');
                    cleanReportEmailAlertPopup();
                })
                .catch(error => {
                    if (error.response !== undefined && error.response.status !== 401) {
                        console.error(`Could not delete Report Id = ${selectedReportId}.`)
                    }
                });
        }
    }

    return (
        <React.Fragment>

            <div className="container-fluid h-100">
                <div className="row h-100">

                    <div className="col-sm-9 h-100">

                        <h3>{formatMessage("DTC")}</h3>

                        <DataGrid
                            ref={dataGridRef_Dtc}
                            remoteOperations={true}
                            dataSource={dataSource_Dtc}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            hoverStateEnabled={false}
                            rowAlternationEnabled={false}
                            showRowLines={true}
                            height={'30%'}
                            width={'100%'}
                            selectedRowKeys={selectedRowKeys_Dtc}
                            onSelectionChanged={onSelectionChanged_Dtc}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                allowAdding={false}
                            />
                            <Paging defaultPageSize={5} />
                            <Sorting mode="single" />
                            <Scrolling
                                mode="virtual"
                                rowRenderingMode="standard"
                                useNative={true}
                            />
                            <Selection
                                mode="multiple"
                                selectAllMode={"allPages"}
                                showCheckBoxesMode={"always"}
                            />
                            <Column
                                dataField="spn"
                                caption={formatMessage("SPN")}
                                width={'10%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="fmi"
                                caption={formatMessage("FMI")}
                                width={'10%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="messageText"
                                caption={formatMessage("Description")}
                                width={'50%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="errorCode"
                                caption={formatMessage("FaultCode")}
                                width={'10%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="condition"
                                caption={formatMessage("Condition")}
                                editCellRender={editCellRender_Condition}
                                width={'20%'}
                            >
                                <Lookup
                                    dataSource={dataSource_Conditions}
                                    displayExpr="label"
                                    valueExpr="id"
                                />
                            </Column>
                        </DataGrid>

                        <br /><br />

                        <h3>{formatMessage("Measurements")}</h3>

                        <DataGrid
                            ref={dataGridRef_Measurement}
                            dataSource={dataSource_Measurement}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            hoverStateEnabled={false}
                            rowAlternationEnabled={false}
                            showRowLines={true}
                            height={'30%'}
                            width={'100%'}
                            selectedRowKeys={selectedRowKeys_Measurement}
                            onSelectionChanged={onSelectionChanged_Measurement}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                allowAdding={false}
                            />
                            <Paging defaultPageSize={5} />
                            <Sorting mode="single" />
                            <Scrolling
                                mode="virtual"
                                rowRenderingMode="standard"
                                useNative={true}
                            />
                            <Selection
                                mode="multiple"
                                selectAllMode={"allPages"}
                                showCheckBoxesMode={"always"}
                            />
                            <Column
                                dataField="messageText"
                                caption={formatMessage("Description")}
                                width={'80%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="condition"
                                caption={formatMessage("Condition")}
                                width={'20%'}
                                editCellRender={editCellRender_Condition}
                            >
                                <Lookup
                                    dataSource={dataSource_Conditions}
                                    displayExpr="label"
                                    valueExpr="id"
                                />
                            </Column>
                        </DataGrid>

                        <br /><br />

                        <h3>{formatMessage("String")}</h3>

                        <DataGrid
                            ref={dataGridRef_String}
                            dataSource={dataSource_String}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            hoverStateEnabled={false}
                            rowAlternationEnabled={false}
                            showRowLines={true}
                            height={'30%'}
                            width={'100%'}
                            selectedRowKeys={selectedRowKeys_String}
                            onSelectionChanged={onSelectionChanged_String}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                allowAdding={false}
                            />
                            <Paging defaultPageSize={5} />
                            <Sorting mode="single" />
                            <Scrolling
                                mode="virtual"
                                rowRenderingMode="standard"
                                useNative={true}
                            />
                            <Selection
                                mode="multiple"
                                selectAllMode={"allPages"}
                                showCheckBoxesMode={"always"}
                            />
                            <Column
                                dataField="messageText"
                                caption={formatMessage("Description")}
                                width={'80%'}
                                allowEditing={false}
                            />
                            <Column
                                dataField="condition"
                                caption={formatMessage("Condition")}
                                width={'20%'}
                                editCellRender={editCellRender_Condition}
                            >
                                <Lookup
                                    dataSource={dataSource_Conditions}
                                    displayExpr="label"
                                    valueExpr="id"
                                />
                            </Column>
                        </DataGrid>

                    </div>

                    <div className="col-sm-3 h-100">

                        <h3>{formatMessage("Users")}</h3>

                        <List
                            dataSource={dataSource_Users}
                            showSelectionControls={true}
                            selectionMode="multiple"
                            onOptionChanged={onOptionChanged_Users}
                            selectedItemKeys={selectedItemKeys_Users}
                            useNativeScrolling={true}
                            height={'200px'}
                        />

                        <br /><br />

                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-sm-12">
                                    <label>{formatMessage("ReportName")}:</label>
                                    <input type="text" className="form-control w-100" value={emailReportName} onChange={e => setEmailReportName(e.target.value)} />
                                </div>
                            </div>

                            <br />

                            {
                                selectedReportId === null &&
                                <div className="row">
                                    <div className="col-sm-12 text-left">
                                        <button className="btn btn-primary" onClick={() => { saveUpdateEmailReport("save") }}>
                                            <span className="icon icon-save" /> {formatMessage("Save")}
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                selectedReportId !== null &&
                                <div className="row">

                                    <div className="col-sm-6">
                                        <button className="btn btn-secondary" onClick={deleteEmailReport}>
                                            <span className="icon icon-delete" /> {formatMessage("Delete")}
                                        </button>
                                    </div>

                                    <div className="col-sm-6">
                                        <button className="btn btn-primary" onClick={() => { saveUpdateEmailReport("update") }}>
                                            <span className="icon icon-edit" /> {formatMessage("Update")}
                                        </button>
                                    </div>

                                </div>
                            }

                        </div>

                    </div>

                </div>
            </div>

        </React.Fragment>
    );

}

export default EmailAlert;