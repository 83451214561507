import React, { useContext, useEffect } from 'react';
import { formatMessage } from 'devextreme/localization';
import './dashboard.css';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import FleetOverview from './FleetOverview';
import NumberOfVehiclesShowedUp from './NumberOfVehiclesShowedUp';
import TotalErrors from './TotalErrors';
import LatestErrors from './LatestErrors';
import LanguageContext from '../../utils/contexts/language-context';

function Dashboard() {
    const languageObj = useContext(LanguageContext);
    const pageTitleObj = useContext(PageTitleContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("Dashboard"));
    }, [languageObj.language]);

    return (
        <div className="vhm-dashboard-content">

            <div className="container-fluid h-100" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <div className="row h-100">

                    <div className="col-sm-12 col-md-12 col-lg-6 vhm-dashboard-widget">
                        <div className="vhm-dashboard-widget-content">
                            <FleetOverview />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 vhm-dashboard-widget">
                        <LatestErrors />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 vhm-dashboard-widget">
                        <div className="vhm-dashboard-widget-content">
                            <NumberOfVehiclesShowedUp />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 vhm-dashboard-widget">
                        <div className="vhm-dashboard-widget-content">
                            <TotalErrors />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Dashboard;