import axios from 'axios';

const URL_API = "/global";

const GlobalService = {

    getApplicationCulture: function () {
        return axios.get(
            `${URL_API}/getapplicationculture`
        );
    },

    getDefaultCulture: function () {
        return axios.get(
            `${URL_API}/getdefaultculture`
        );
    },

    getMeasurementSystem: function () {
        return axios.get(
            `${URL_API}/getmeasurementsystem`
        );
    }

}

export default GlobalService;