import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/user";

const UserService = {

    getUsers: function () {
        return axiosInstance.get(
            `${URL_API}/user`);
    }

};

export default UserService;