import React, { useContext, useEffect, useRef, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import loginCss from './login.module.css';
import loginMessages from '../../language/resources/login';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import AuthenticationService from '../../services/authentication.service';
import feedbackMessages from '../../language/resources/feedback';
import TokenContext from '../../utils/contexts/token-context';
import LanguageContext from '../../utils/contexts/language-context';

function Login() {
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages, loginMessages, feedbackMessages], languageObj.language);

    const [userName, _setUserName] = useState();
    const [password, _setPassword] = useState();
    const [spinnerClass, setSpinnerClass] = useState("progress-indicator progress-indicator--spinner");

    let userNameRef = useRef(userName);
    let passwordRef = useRef(password);

    function setUserName(data) {
        userNameRef.current = data;
        _setUserName(data);
    }

    function setPassword(data) {
        passwordRef.current = data;
        _setPassword(data);
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return function cleanup() {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    function handleSubmit() {
        setSpinnerClass("progress-indicator progress-indicator--spinner progress-indicator--active"); // activates loading spinner

        AuthenticationService.login(userNameRef.current, passwordRef.current)
            .then(response => {
                let responseData = response.data;
                tokenObj.setToken(responseData.token);
            })
            .catch(error => {
                console.error(error);
                devextremeUtils.displayNotification(formatMessage("Failure_Login"), 'error');
                setSpinnerClass("progress-indicator progress-indicator--spinner"); // deactivates loading spinner
            });
    }

    return (
        <div className={loginCss.vhmLoginPage}>
            <div className={loginCss.loginContainer}>

                <article className={loginCss.loginArticle}>
                    <form className="form-bs rounded-corners drop-shadow">

                        <header className="col-sm-12 text-center">
                            <h1>
                                <span className="icon icon-root"></span> {formatMessage("App_Name")}
                            </h1>
                        </header>

                        <br />
                        <br />

                        <fieldset className="col-sm-12">
                            <legend>{formatMessage("Login")}</legend>

                            <div className="form-group">
                                <label htmlFor="userName">{formatMessage("User")}</label>
                                <input type="text" className="form-control" id="userName" placeholder={formatMessage("UserName")} onChange={e => setUserName(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">{formatMessage("Password")}</label>
                                <input type="password" className="form-control" id="password" placeholder={formatMessage("Password")} onChange={e => setPassword(e.target.value)} />
                            </div>

                        </fieldset>

                        <footer className="col-sm-12">
                            <div className="row">

                                <div className="col-sm-6 text-left">
                                    <div className={spinnerClass}>
                                        <div className="progress-indicator__progress-identifier"></div>
                                    </div>
                                </div>

                                <div className="col-sm-6 text-right">
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                        <span className="icon icon-login"></span> {formatMessage("Login")}
                                    </button>
                                </div>

                            </div>
                        </footer>
                    </form>
                </article>

            </div>
        </div>
    );
}

export default Login;