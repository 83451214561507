import React, { useContext, useEffect, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import { Split } from '@geoffcox/react-splitter';
import LanguageContext from '../../utils/contexts/language-context';
import VehiclesGrid from './VehiclesGrid';
import Filter from './Filter';
import TokenContext from '../../utils/contexts/token-context';
import FleetService from '../../services/fleet.service';
import moment from 'moment';

function Fleet() {
    const languageObj = useContext(LanguageContext);
    const pageTitleObj = useContext(PageTitleContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("Fleet"));
    }, [languageObj.language]);

    const [manufacturersList, setManufacturersList] = useState([]);
    const [selectedManufacturerIds, setSelectedManufacturerIds] = useState(null);
    const [contractorsList, setContractorsList] = useState([]);
    const [selectedContractorIds, setSelectedContractorIds] = useState(null);

    const [gridDataSource, setGridDataSource] = useState([]);
    const [listDataSource, setListDataSource] = useState([]);

    const dateNow = moment();

    useEffect(() => {
        getManufacturers();
        getContractors();
    }, [tokenObj.token, languageObj.language]);

    useEffect(() => {
        getVehiclesDataSource(selectedManufacturerIds, selectedContractorIds);
    }, [tokenObj.token, selectedManufacturerIds, selectedContractorIds]);

    useEffect(() => {
        generateDataSourceForList();
    }, [manufacturersList, contractorsList]);

    function generateDataSourceForList() {
        let finalList = [
            {
                key: formatMessage("Manufacturers"),
                items: manufacturersList
            },
            {
                key: formatMessage("Contractors"),
                items: contractorsList
            }
        ];

        setListDataSource(finalList);
    }

    function getManufacturers() {
        FleetService.getManufacturers()
            .then(response => {
                let responseData = response.data;

                let manufacturersList = [];

                for (let i = 0; i < responseData.data.length; i++) {
                    manufacturersList.push(
                        {
                            id: responseData.data[i].key,
                            label: responseData.data[i].value
                        }
                    );
                }

                setManufacturersList(manufacturersList);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Manufacturers.");
                }
            });
    }

    function getContractors() {
        FleetService.getContractors()
            .then(response => {
                let responseData = response.data;

                let contractorsList = [];

                for (let i = 0; i < responseData.data.length; i++) {
                    contractorsList.push(
                        {
                            id: responseData.data[i].key,
                            label: responseData.data[i].value
                        }
                    );
                }

                setContractorsList(contractorsList);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Contractors.");
                }
            });
    }

    function getVehiclesDataSource(manufacturerIds, contractorIds) {
        FleetService.getVehicles(contractorIds, manufacturerIds)
            .then(response => {
                let responseData = response.data.data;

                for (let i = 0; i < responseData.length; i++) {
                    let lastData = moment(responseData[i].lastData);
                    let diffLastData = moment.duration(dateNow.diff(lastData));
                    let diffMinutes = diffLastData.asMinutes();

                    if (diffMinutes <= 15) {
                        responseData[i].isOnline = true;
                    } else {
                        responseData[i].isOnline = false;
                    }
                }

                setGridDataSource(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Vehicles.");
                }
            });
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <Split
                    splitterSize='10px'
                    splitterSize='10px'
                    minPrimarySize='15%'
                    initialPrimarySize='20%'
                    minSecondarySize='45%'
                    defaultSplitterColors={{ color: '#D4D4D4' }}
                >
                    <div className="col-12 h-100 pr-0">
                        <Filter
                            listDataSource={listDataSource}

                            setSelectedManufacturerIds={setSelectedManufacturerIds}
                            setSelectedContractorIds={setSelectedContractorIds}
                        />
                    </div>

                    <div className="col-12 h-100">
                        <VehiclesGrid
                            gridDataSource={gridDataSource}
                            manufacturersList={manufacturersList}
                        />
                    </div>
                </Split>
            </div>
        </div>
    );
}

export default Fleet;