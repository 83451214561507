import React, { useEffect, useRef, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import DataGrid, { Column, Scrolling, Paging, Sorting } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

export function wrapVehicleDetailsGrid(Component, vehicleChildComponents) {
    return (props) => {
        return (<Component vehicleChildComponents={vehicleChildComponents} {...props} />)
    };
}

function VehicleDetailsGrid({ vehicleChildComponents }) {
    const [isLoadVisible, setIsLoadVisible] = useState(true);

    useEffect(() => {
        setIsLoadVisible(!isLoadVisible);
    }, [vehicleChildComponents]);

    return (
        <React.Fragment>
            <LoadPanel
                visible={isLoadVisible}
                showIndicator={true}
                showPane={true}
            />

            <DataGrid
                dataSource={vehicleChildComponents}
                remoteOperations={true}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={false}
                width={'100%'}
                wordWrapEnabled={true}
            >
                <Paging defaultPageSize={5} />
                <Sorting mode="single" />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                />
                <Column
                    dataField="name"
                    dataType="string"
                    caption={formatMessage("Name")}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="manufacturer"
                    dataType="string"
                    caption={formatMessage("Manufacturer")}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="type"
                    dataType="string"
                    caption={formatMessage("TypeName")}
                    cssClass="child-table-cell"
                />
            </DataGrid>
        </React.Fragment>
    );

}

export default VehicleDetailsGrid;