import React, { useState } from 'react';
import DataGrid, { Column, Scrolling, FilterRow, Paging, Sorting, Lookup, LoadPanel, MasterDetail, Selection } from 'devextreme-react/data-grid';
import { formatMessage } from 'devextreme/localization';
import VehicleDetailsGrid, { wrapVehicleDetailsGrid } from './VehicleDetailsGrid';
import '../../utils/customStylingForDx/dxDataGrid.css';
import FleetService from '../../services/fleet.service';

function VehiclesGrid({
    gridDataSource,
    manufacturersList
}) {

    const [vehicleChildComponents, setVehicleChildComponents] = useState([]);

    function getVehicleChildComponents(vehicleId) {
        FleetService.getVehicleComponents(vehicleId)
            .then(response => {
                let responseData = response.data;

                setVehicleChildComponents(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of Vehicle Child Components.");
                }
            });
    }

    function onSelectionChanged(e) {
        e.component.collapseAll(-1);

        let selectedKey = e.currentSelectedRowKeys[0];
        e.component.expandRow(selectedKey);

        getVehicleChildComponents(selectedKey);
    }

    function cellRender_Status(cellData) {
        let isOnline = cellData.data.isOnline;

        if (isOnline) {
            return (<div>{formatMessage("Online")}</div>);
        }

        return (<div>{formatMessage("Offline")}</div>);
    }

    function calculateFilterExpression_Status(value, selectedFilterOperation, target) {
        if (value.toLowerCase() === formatMessage("Online").toLowerCase()) {
            return [["isOnline", "=", true]];
        }

        if (value.toLowerCase() === formatMessage("Offline").toLowerCase()) {
            return [["isOnline", "=", false]];
        }

        return [["isOnline", "=", value]];
    }

    function calculateSortValue_Status(rowData) {
        return rowData.isOnline;
    }

    return (
        <React.Fragment>

            <div className="h-100 custom-dxDataGrid">
                <DataGrid
                    dataSource={gridDataSource}
                    remoteOperations={true}
                    columnAutoWidth={false}
                    columnHidingEnabled={false}
                    showColumnLines={false}
                    hoverStateEnabled={false}
                    rowAlternationEnabled={false}
                    showRowLines={true}
                    height={'100%'}
                    width={'100%'}
                    keyExpr="componentId"
                    focusedRowEnabled={true}
                    onSelectionChanged={onSelectionChanged}
                >
                    <Selection mode="single" />
                    <Paging defaultPageSize={20} />
                    <Sorting mode="single" />
                    <Scrolling
                        mode="virtual"
                        rowRenderingMode="standard"
                        useNative={true}
                    />
                    <LoadPanel enabled={true} />
                    <FilterRow
                        visible={true}
                        showOperationChooser={false}
                    />
                    <Column
                        dataField="manufacturerLabel"
                        dataType="string"
                        caption={formatMessage("Manufacturer")}
                    >
                        <Lookup
                            dataSource={manufacturersList}
                            valueExpr="label"
                            displayExpr="label"
                        />
                    </Column>
                    <Column
                        dataField="fleetName"
                        dataType="string"
                        caption={formatMessage("Name")}
                    />
                    <Column
                        dataField="year"
                        dataType="string"
                        caption={formatMessage("ModelYear")}
                        width={100}
                    />
                    <Column
                        dataField="technicalId"
                        dataType="string"
                        caption={formatMessage("TechnicalId")}
                    />
                    <Column
                        dataField="operationalId"
                        dataType="string"
                        caption={formatMessage("OperationalId")}
                    />
                    <Column
                        dataField="status"
                        dataType="string"
                        width={80}
                        caption={formatMessage("Status")}
                        cellRender={cellRender_Status}
                        calculateFilterExpression={calculateFilterExpression_Status}
                        calculateSortValue={calculateSortValue_Status}
                    />
                    <Column
                        dataField="lastChange"
                        dataType="datetime"
                        caption={formatMessage("LastChange")}
                    />
                    <Column
                        dataField="lastData"
                        dataType="datetime"
                        caption={formatMessage("LastData")}
                    />
                    <MasterDetail
                        enabled={false}
                        component={wrapVehicleDetailsGrid(VehicleDetailsGrid, vehicleChildComponents)}
                    />
                </DataGrid>
            </div>

        </React.Fragment>
    );
}

export default VehiclesGrid;