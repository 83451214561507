import React, { useContext } from 'react';
import DataGrid, { Column, Scrolling, Paging, Sorting, MasterDetail, Selection } from 'devextreme-react/data-grid';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import 'jspdf-autotable';
import HistoryGridDetail, { wrapHistoryGridDetail } from './HistoryGridDetail';
import { LoadPanel } from 'devextreme-react/load-panel';

function HistoryGrid({
    dataGridRef,
    gridDataSource,
    since,
    until,
    ecus,
    errorLevel
}) {

    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    function onRowClick(e) {
        let expanded = e.isExpanded;

        if (expanded) {
            e.component.collapseRow(e.key);
        } else {
            e.component.expandRow(e.key);
        }
    }

    function expandButtonCell(e) {
        return (
            <div className="text-right">
                <button type="button" className="btn btn-secondary btn-expand">
                    <span className={"icon " + (e.row.isExpanded? "icon-direction-down" : "icon-direction-right") + " pr-0"} />
                    </button>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div id="grid_History" className="h-100">
                <DataGrid
                    ref={dataGridRef}
                    dataSource={gridDataSource}
                    remoteOperations={true}
                    columnAutoWidth={false}
                    columnHidingEnabled={false}
                    showColumnLines={false}
                    hoverStateEnabled={false}
                    rowAlternationEnabled={false}
                    showRowLines={true}
                    height={'100%'}
                    width={'100%'}
                    focusedRowEnabled={true}
                    onRowClick={onRowClick}
                >
                    <Selection mode="multiple" showCheckBoxesMode="none" />
                    <Paging defaultPageSize={10} />
                    <Sorting mode="single" />
                    <Scrolling
                        mode="virtual"
                        rowRenderingMode="standard"
                        useNative={true}
                        legacyMode={true}
                    />
                    <LoadPanel enabled={true} />
                    <Column
                        dataField="fleetName"
                        dataType="string"
                        caption={formatMessage("FleetName")}
                    />
                    <Column
                        dataField="opTechnicalId"
                        dataType="string"
                        caption={formatMessage("TechnicalId")}
                    />
                    <Column
                        dataField="manufacturerName"
                        dataType="string"
                        caption={formatMessage("Manufacturer")}
                    />
                    <Column
                        dataField="vehicleName"
                        dataType="string"
                        caption={formatMessage("VehicleName")}
                    />
                    <Column
                        dataField="year"
                        dataType="string"
                        width={'10%'}
                        caption={formatMessage("Year")}
                    />
                    <Column
                        width={'8%'}
                        cellRender={expandButtonCell}
                    />
                    <MasterDetail
                        enabled={false}
                        component={wrapHistoryGridDetail(HistoryGridDetail, since, until, ecus, errorLevel)}
                    />
                </DataGrid>
            </div>
        </React.Fragment>
    );
}

export default HistoryGrid;