import { formatMessage } from 'devextreme/localization';
import React, { useContext } from 'react';
import feedbackMessages from '../../language/resources/feedback';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import LanguageContext from '../../utils/contexts/language-context';
import DataGrid, { Column, Scrolling, Paging } from 'devextreme-react/data-grid';
import isoImages from '../../utils/dictionaries/isoImages';

function Lamps({
    dataSource
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    function cellRender_IsoLamp(cellData) {
        let isoLampValue = cellData.value;

        if (isoLampValue != null && isoLampValue != "") {
            let isoLampImageBase64 = isoImages[isoLampValue];

            return (
                <div>
                    <img src={`data:image/png;base64,${isoLampImageBase64}`} className="img-fluid img-iso-lamp" />
                </div>
            );
        }

        return (
            <></>
        );
    }

    return (
        <React.Fragment>
            <DataGrid
                dataSource={dataSource}
                remoteOperations={true}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={true}
                height={'200px'}
                width={'100%'}
            >
                <Paging defaultPageSize={5} />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                />
                <Column
                    dataField="condition"
                    dataType="string"
                    width="25%"
                    caption={formatMessage("Condition")}
                />
                <Column
                    dataField="name"
                    dataType="string"
                    width="60%"
                    caption={formatMessage("Name")}
                />
                <Column
                    dataField="isoLamp"
                    dataType="string"
                    width="15%"
                    caption={formatMessage("IsoLamp")}
                    cellRender={cellRender_IsoLamp}
                />
            </DataGrid>
        </React.Fragment>
    );
}

export default Lamps;