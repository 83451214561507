const loginMessages = {
    "en-GB": {
        "Login": "Log in",
        "Password": "Password",
        "User": "User",
        "UserName": "User Name",
    },
    "de-DE": {
        "Login": "Einloggen",
        "Password": "Passwort",
        "User": "Benutzer",
        "UserName": "Benutzername"
    }
}

export default loginMessages;