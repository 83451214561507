import React from 'react';

function NavSettingsItem({ id, icon, tabTitle, onClick, isDisabled, customClass }) {

    let buttonClassName = "btn btn-flat d-inline-flex align-items-center";

    if (isDisabled) {
        buttonClassName += " disabled";
    }

    return (
        <li className="nav-item">
            <button className={buttonClassName + " " + customClass} id={id} onClick={onClick}>
                <span className={"pr-0 icon " + icon}></span>
                {tabTitle}
            </button>
        </li>
    );

}

export default NavSettingsItem;