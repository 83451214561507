import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import './navbar.css';

function NavItem({ id, icon, href, tabTitle, isDisabled }) {

    let match = useRouteMatch({
        path: href,
        exact: true
    });

    let buttonClassName = "btn btn-secondary d-inline-flex align-items-center";

    if (match) {
        buttonClassName += " active";
    }

    if (isDisabled) {
        buttonClassName += " disabled";

        return (
            <li className="nav-item">
                <button className={buttonClassName} id={id}>
                    <span className={"icon " + icon}></span>
                    {tabTitle}
                </button>
            </li>
        )
    }

    return (
        <li className="nav-item">
            <Link to={href}>
                <button className={buttonClassName} id={id}>
                    <span className={"icon " + icon}></span>
                    {tabTitle}
                </button>
            </Link>
        </li>
    )
};

export default NavItem;