import React, { useContext, useState } from 'react';
import LanguageContext from '../../../utils/contexts/language-context';
import languageUtils from '../../../utils/configs/languageUtils';
import globalMessages from '../../../language/resources/global';
import feedbackMessages from '../../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import { Popup } from 'devextreme-react/popup';
import HistoryService from '../../../services/history.service';
import devextremeUtils from '../../../utils/configs/devextremeUtils';

function SaveFilter({
    isPopupVisible,
    setPopupVisibility,
    onFilterSaved,
    since,
    until,
    ecus,
    type,
    manufacturer,
    year,
    fleetIds,
    severity
}) {

    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const [filterName, setFilterName] = useState(null);

    function saveFilter() {
        let fleetIdsArray = [];

        if (fleetIds && fleetIds != "") {
            fleetIdsArray = fleetIds?.split(',').map(entry => { return +entry }); // parseFloat
        }

        HistoryService.saveFilter(filterName, since, until, ecus, type, manufacturer, year, fleetIdsArray, severity)
            .then(response => {
                setPopupVisibility(false);
                devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Save"), formatMessage("Filter")), 'success');
                setFilterName(null);
                onFilterSaved();
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not save Filter.");
                    devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Failure_Save"), formatMessage("Filter")), 'error');
                }
            });
    }

    function onHiding_SaveFilterPopup() {
        setPopupVisibility(false);
    }

    return (
        <React.Fragment>
            <Popup
                visible={isPopupVisible}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title={formatMessage("SaveFilter")}
                width={'40%'}
                height={'25%'}
                onHiding={onHiding_SaveFilterPopup}
            >
                <div className="popup-content h-100">
                    <div className="form-group">
                        <label>{formatMessage("FilterName")}</label>
                        <input type="text" className="form-control" onChange={e => setFilterName(e.target.value)} />
                    </div>

                    <br /><br />

                    <div className="text-right">
                        <button className="btn btn-primary" onClick={saveFilter}>
                            {formatMessage("Save")}
                        </button>
                    </div>
                </div>

            </Popup>
        </React.Fragment>
    );

}

export default SaveFilter;