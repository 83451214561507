import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/fleet";

const FleetService = {

    getManufacturers: function () {
        return axiosInstance.get(
            `${URL_API}/manufacturers`
        );
    },

    getContractors: function () {
        return axiosInstance.get(
            `${URL_API}/contractors`
        );
    },

    getVehicles: function (contractorIds, manufacturerIds) {
        return axiosInstance.get(
            `${URL_API}/vehicles`,
            {
                params: {
                    contractorIds: contractorIds,
                    manufacturerIds: manufacturerIds
                }
            }
        );
    },

    getVehicleComponents: function (vehicleId) {
        return axiosInstance.get(
            `${URL_API}/vehiclecomponents`,
            {
                params: {
                    vehicleId: vehicleId
                }
            }
        );
    }

}

export default FleetService;