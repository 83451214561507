import React from 'react';

import { formatMessage } from 'devextreme/localization';

import globalMessages from '../../language/resources/global';
import languageUtils from '../configs/languageUtils';

languageUtils.loadDictionaries([globalMessages]);

// defaults
const PageTitleContext = React.createContext({
    pageTitle: formatMessage("App_Name"),
    setPageTitle: () => { }
});

export default PageTitleContext;