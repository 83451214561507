import React, { useContext, useEffect, useState } from 'react';
import SelectBox from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';
import Chart, {
    ArgumentAxis,
    ValueAxis,
    Label,
    CommonSeriesSettings,
    Grid,
    Size,
    Series,
    Border,
    Tooltip,
    Legend,
    Tick
} from 'devextreme-react/chart';
import { formatMessage } from 'devextreme/localization';
import moment from 'moment';
import feedbackMessages from '../../language/resources/feedback';
import languageUtils from '../../utils/configs/languageUtils';
import DashboardService from '../../services/dashboard.service';
import TokenContext from '../../utils/contexts/token-context';
import LanguageContext from '../../utils/contexts/language-context';
import globalMessages from '../../language/resources/global';

function NumberOfVehiclesShowedUp() {
    const tokenObj = useContext(TokenContext);
    const languageObj = useContext(LanguageContext);

    const [dataSource, setDataSource] = useState([]);
    const [chartSize, setChartSize] = useState(300);
    const [amountOfDays, setAmountOfDays] = useState(7);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    let chartContainerRef = React.createRef();

    const timeFrames = [
        {
            "Id": 7,
            "Name": formatMessage("LastWeek")
        },
        {
            "Id": 14,
            "Name": languageUtils.stringFormat(formatMessage("LastXWeeks"), 2)
        },
        {
            "Id": 28,
            "Name": languageUtils.stringFormat(formatMessage("LastXWeeks"), 4)
        }
    ];

    const store_TimeFrames = new ArrayStore({
        key: 'Id',
        data: timeFrames
    });

    function getNumberOfVehiclesShowedUpData(amountOfDays) {
        let today = moment().endOf('day'); // entire day, until 23h59m59s
        let until = moment(today).format('X');

        let target = moment(today).subtract(amountOfDays, 'd');
        target = target.startOf('day'); // entire target day since 00h00m00s
        let since = moment(target).format('X');

        DashboardService.getSeenVehiclesHistory(since, until)
            .then(response => {
                let responseData = response.data.data;

                responseData.sort(function (a, b) {
                    return a.date.localeCompare(b.date);
                });

                for (let i = 0; i < responseData.length; i++) {
                    for (let j = 0; j < responseData[i].data.length; j++) {
                        if (responseData[i].data[j].fleetName.toUpperCase() === "Unassigned".toUpperCase()) {
                            responseData[i].data[j].fleetName = formatMessage("Unassigned");
                        }
                    }

                    let formatedDate = moment(responseData[i].date, "AAAA-MM-DDThh:mm:ss").format("MM.DD ddd");
                    responseData[i].date = formatedDate;
                }

                setDataSource(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    const errorMessage = languageUtils.stringFormat(formatMessage("Failure_DataRetrieveForWidget"), formatMessage("NumberOfVehiclesShowedUp"));
                    console.error(errorMessage);
                }
            });
    }

    useEffect(() => {
        getNumberOfVehiclesShowedUpData(amountOfDays);

        let chartheight = chartContainerRef.current.clientHeight;
        setChartSize(chartheight);
    }, [tokenObj.token, languageObj.language, amountOfDays]);

    function onValueChanged(e) {
        setAmountOfDays(e.value);
    }

    function customizeTooltip(data) {
        let chartData = data.point.data;

        let detailsHtml = '';
        for (let i = 0; i < chartData.data.length; i++) {
            detailsHtml += '<tr><td>' + chartData.data[i].fleetName + '</td><td class="text-right">' + chartData.data[i].ocurrences + '</td></tr>';
        }

        return {
            html:
                '<div class="h-100 w-100">' +
                '<div class="text-center">' +
                '<span class="icon icon-vehicle-bus"></span> ' + formatMessage("FleetOverview") + ' <br/>' +
                '</div>' +
                '<br/>' +
                '<div><table class="w-100">' +
                '<tr><td><b>' + formatMessage("Total") + '</b></td><td class="text-right"><b>' + chartData.totals + '</b></td></tr>' +
                detailsHtml +
                '</table></div>' +
                '</div>'
        };
    }

    return (
        <div className="h-100 w-100">

            <div className="row h-15">
                <div className="col-sm-6 col-md-8 col-lg-8">
                    <h2>{formatMessage("ActiveVehicles")}</h2>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 text-right">
                    <SelectBox dataSource={store_TimeFrames}
                        displayExpr="Name"
                        valueExpr="Id"
                        defaultValue={timeFrames[0].Id}
                        onValueChanged={onValueChanged}
                    />
                </div>
            </div>

            <div className="h-85" ref={chartContainerRef}>
                <Chart
                    id="chart_NumberOfVehiclesShowedUp"
                    dataSource={dataSource}
                >
                    <Size
                        height={chartSize}
                    />
                    <Legend
                        visible={false}
                    />
                    <ArgumentAxis>
                        <Label
                            overlappingBehavior="hide"
                            wordWrap="normal"
                        />
                        <Tick visible={false} />
                    </ArgumentAxis>
                    <ValueAxis
                        name="AmountOfVehicles"
                        position="left"
                        color={"#FFFFFF"}
                    >
                        <Tick visible={false} />
                        <Grid visible={true} />
                    </ValueAxis>
                    <CommonSeriesSettings
                        argumentField="date"
                        type="bar"
                        barOverlapGroup={true}
                        barPadding={0.4}
                    />
                    <Series
                        type="bar"
                        valueField="totals"
                        name="Amount"
                        axis="AmountOfVehicles"
                        color="#63A6DF"
                    />
                    <Tooltip
                        enabled={true}
                        shared={true}
                        location="edge"
                        customizeTooltip={customizeTooltip}
                    >
                        <Border
                            color="#63A6DF"
                            visible={true}
                            width={2}
                            dashStyle="solid"
                        />
                    </Tooltip>
                </Chart>
            </div>

        </div>
    );
}

export default NumberOfVehiclesShowedUp;