import React, { useContext } from 'react';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import ToolbarItem from './ToolbarItem';
import LanguageContext from '../../utils/contexts/language-context';

function Toolbar() {
    const languageObj = useContext(LanguageContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const tabs = [
        <ToolbarItem key="toolbar_Dashboard" icon="icon-dashboard" tabTitle={formatMessage("Dashboard")} href="/dashboard"></ToolbarItem>,
        <ToolbarItem key="toolbar_ControlBoard" icon="icon-control-center" tabTitle={formatMessage("ControlBoard")} href="/controlboard"></ToolbarItem>,
        <ToolbarItem key="toolbar_History" icon="icon-media-replay" tabTitle={formatMessage("History")} href="/history"></ToolbarItem>,
        <ToolbarItem key="toolbar_Reports" icon="icon-vehicle-supervisor" tabTitle={formatMessage("Reports")} href="/reports"></ToolbarItem>,
        <ToolbarItem key="toolbar_RealTimeCockpit" icon="icon-speeding" tabTitle={formatMessage("RealTimeCockpit")} isDisabled={true}></ToolbarItem>,
        <ToolbarItem key="toolbar_Recordings" icon="icon-speeding" tabTitle={formatMessage("Recordings")} isDisabled={true}></ToolbarItem>,
        <ToolbarItem key="toolbar_Fleet" icon="icon-database" tabTitle={formatMessage("Fleet")} isDisabled={true}></ToolbarItem>
    ];

    return (
        <div className="toolbar-navigation">
            {tabs}
        </div>
    );
}

export default Toolbar;