import React, { useEffect, useState } from "react";
import DataGrid, { Column, LoadPanel, MasterDetail, Paging, Scrolling, Sorting } from "devextreme-react/data-grid";
import { useContext } from "react";
import languageUtils from "../../utils/configs/languageUtils";
import LanguageContext from "../../utils/contexts/language-context";
import { formatMessage } from 'devextreme/localization';
import "./controlBoard.css";
import devextremeUtils from "../../utils/configs/devextremeUtils";
import ControlBoardService from "../../services/controlBoard.service";
import moment from 'moment';
import CustomStore from "devextreme/data/custom_store";
import ErrorDetailTemplate, { wrapErrorDetailTemplate } from "./ErrorDetailTemplate";
import globalMessages from "../../language/resources/global";
import _ from 'lodash-uuid';

function VehicleErrorsGrid({
    vehicleId,
    workingHours,
    showErrors,
    showActiveFleet
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const [errorsGridDataSource, setErrorsGridDataSource] = useState([]);

    const [timelineOpenKey, setTimelineOpenKey] = useState(null);

    useEffect(() => {
        if (vehicleId === -1) {
            setErrorsGridDataSource([]);
        } else {
            createStore_VehicleErrors(workingHours);
        }
    }, [vehicleId, showErrors, showActiveFleet]);

    function createStore_VehicleErrors(workingHours) {
        let vehicleErrorsGrid = new CustomStore({
            key: 'customKey',
            load: function (loadOptions) {
                let params = {};
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && devextremeUtils.isNotEmpty(loadOptions[i])) {
                        params[i] = loadOptions[i];
                    }
                });

                let encodedParams = btoa(JSON.stringify(params));

                const today = moment(); // now
                let until = moment(today).format('X');

                let target = moment(today).subtract(workingHours, 'hours');
                let since = moment(target).format('X');

                let errorState = "Any";

                return ControlBoardService.getActiveErrors(vehicleId, errorState, since, until, encodedParams)
                    .then(response => {
                        let responseData = response.data;
                        let errorsData = responseData.data;

                        for (let i = 0; i < errorsData.length; i++) {
                            let formatedDate = moment(errorsData[i].lastChange);
                            errorsData[i].lastChange = formatedDate;
                            errorsData[i].customKey = _.uuid();
                        }

                        return {
                            data: errorsData,
                            totalCount: responseData.totalCount
                        }
                    })
                    .catch(error => {
                        if (error.response !== undefined && error.response.status !== 401) {
                            console.error("Could not retrieve data for Vehicle Errors Grid.");
                        }
                    });
            }
        });

        setErrorsGridDataSource(vehicleErrorsGrid);
    }

    function errorLevelCell(cellData) {
        let statusValue = cellData.value;

        let cellContent = <span></span>;

        if (statusValue) {
            switch (statusValue) {
                case 0: // OK
                    cellContent = <span className="icon icon-ok error-status-icon ok"></span>
                    break;

                case 1: // WARNING
                    cellContent = <span className="icon icon-warning error-status-icon warning"></span>
                    break;

                case 2: // ERROR
                    cellContent = <span className="icon icon-error error-status-icon error"></span>
                    break;

                case 3: // CRITICAL
                    cellContent = <span className="icon icon-warning error-status-icon critical"></span>
                    break;
            }
        }

        return (
            <div className="text-center">
                {cellContent}
            </div>
        );
    }

    function onCellPrepared(e) {
        if (e.rowType === 'data') {
            e.cellElement.classList.add('custom-dxdatagrid-row');
        }

        if (e.rowType === 'header') {
            e.cellElement.classList.add('custom-dxdatagrid-header');
        }
    }

    function openTimelineButtonCell(e) {
        return (
            <div>
                <button type="button" className="btn btn-secondary btn-timeline"
                    onClick={() => setTimelineOpenKey(
                        prev => {
                            if (prev == e.key) {
                                return null;
                            }

                            return e.key;
                        }

                    )}><span className={"icon " + (timelineOpenKey == e.key ? "icon-direction-down" : "icon-direction-right") + " pr-0"} /></button>
            </div>
        );
    }

    return (
        <div id="grid_VehicleErrors" className="h-100">
            <DataGrid
                dataSource={errorsGridDataSource}
                remoteOperations={true}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={true}
                key="customKey"
                height={'100%'}
                width={'100%'}
                onCellPrepared={onCellPrepared}
            >
                <Paging defaultPageSize={5} />
                <Sorting mode="single" />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                    legacyMode={true}
                />
                <LoadPanel enabled={true} />
                <Column
                    width={'8%'}
                    cellRender={openTimelineButtonCell}
                />
                <Column
                    dataField="lastChange"
                    dataType="datetime"
                    format="longTime"
                    defaultSortOrder="desc"
                    editorOptions={{ type: 'time' }}
                    caption={formatMessage("LastChange")}
                />
                <Column
                    dataField="componentType"
                    dataType="string"
                    caption={formatMessage("Component")}
                    width={'20%'}
                />
                <Column
                    dataField="errorCode"
                    dataType="string"
                    caption={formatMessage("ErrorCode")}
                    width={'20%'}
                />
                <Column
                    dataField="errorLevel"
                    dataType="string"
                    caption={formatMessage("Status")}
                    cellRender={errorLevelCell}
                />
                <MasterDetail
                    autoExpandAll={true}
                    enabled={false}
                    render={wrapErrorDetailTemplate(ErrorDetailTemplate, workingHours, timelineOpenKey)}
                />
            </DataGrid>
        </div>
    );
}

export default VehicleErrorsGrid;