import React from 'react';
import './toolbar.css';

function ToolbarItem({ href, icon, tabTitle, isDisabled }) {

    let buttonClass = "button button--toolbar stretch toolbar-text";

    if (isDisabled) {
        buttonClass += " disabled";
    }

    return (
        <div className="toolbar-quick-link">
            <a className={buttonClass} href={href}>
                <span className={"icon " + icon}></span> {tabTitle}
            </a>
        </div>
    );
}

export default ToolbarItem;