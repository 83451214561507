import React, { useContext, useEffect, useState } from 'react';
import HistoryService from '../../../services/history.service';
import languageUtils from '../../../utils/configs/languageUtils';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TokenContext from '../../../utils/contexts/token-context';
import LanguageContext from '../../../utils/contexts/language-context';
import moment from 'moment';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../../language/resources/global';
import feedbackMessages from '../../../language/resources/feedback';
import devextremeUtils from '../../../utils/configs/devextremeUtils';
import DataGrid, { Column, Paging, Scrolling, LoadPanel } from 'devextreme-react/data-grid';

function HistoryFilterSchedule() {

    const tokenObj = useContext(TokenContext);

    const dateNow = moment();

    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const [scheduleGridDataSource, setScheduleGridDataSource] = useState([]);
    const [savedFiltersDataSource, setSavedFiltersDataSource] = useState([]);

    const [selectedStartDate, setSelectedStartDate] = useState(dateNow);
    const [selectedSince, setSelectedSince] = useState(dateNow.format('X'));
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedFilterId, setSelectedFilterId] = useState(null);

    const frequencyOptions = [
        {
            "seconds": 3600,
            "name": languageUtils.stringFormat(formatMessage("XHour"), 1)
        },
        {
            "seconds": 7200,
            "name": languageUtils.stringFormat(formatMessage("XHours"), 2)
        },
        {
            "seconds": 86400,
            "name": languageUtils.stringFormat(formatMessage("XHours"), 24)
        },
        {
            "seconds": 172800,
            "name": languageUtils.stringFormat(formatMessage("XHours"), 48)
        },
        {
            "seconds": 604800,
            "name": languageUtils.stringFormat(formatMessage("XDays"), 7)
        },
        {
            "seconds": 2592000,
            "name": languageUtils.stringFormat(formatMessage("XDays"), 30)
        }
    ];

    useEffect(() => {
        getSchedules();
        getAllSavedFilters();
    }, [tokenObj.token]);

    function getSchedules() {
        HistoryService.getAllSchedules()
            .then(response => {
                let responseData = response.data;

                setScheduleGridDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve all saved schedules.");
                }
            });
    }

    function getAllSavedFilters() {
        HistoryService.getAllFilters()
            .then(response => {
                let responseData = response.data;

                let filtersList = responseData.data;
                filtersList = filtersList.map((entry) => { return { filterId: entry.filterId, filterName: entry.filterName } });

                setSavedFiltersDataSource(filtersList);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve all saved filters.");
                }
            });
    }

    function cellRender_Frequency(cellData) {
        let frequencySeconds = cellData.value;

        let frequency = Math.floor(frequencySeconds / 3600); // hours
        let cellContent = <span>{`${frequency}h`}</span>;

        if (frequency > 48) {
            frequency = Math.floor(frequency / 24);
            cellContent = <span>{`${frequency}d`}</span>;
        }

        return (
            <div className="text-center">
                {cellContent}
            </div>
        );
    }

    function cellRender_StartDate(cellData) {
        let date = cellData.value;
        let formatedDate = moment(date).format("DD.MM.YYYY");

        return (
            <div className="text-center">
                {formatedDate}
            </div>
        );
    }

    function cellRender_DeleteSchedule(cellData) {
        let scheduleId = cellData.key;

        return (
            <div className="text-right">
                <button className="pr-0 btn btn-secondary" onClick={() => deleteSchedule(scheduleId)}>
                    <span className="icon icon-error" />
                </button>
            </div>
        );
    }

    function deleteSchedule(scheduleId) {
        if (window.confirm(languageUtils.stringFormat(formatMessage("Confirm_Delete"), formatMessage("Schedule")))) {
            HistoryService.deleteSchedule(scheduleId)
                .then(response => {
                    devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Delete"), formatMessage("Schedule")), 'success');
                    onScheduleDelete();
                })
                .catch(error => {
                    if (error.response !== undefined && error.response.status !== 401) {
                        console.error(`Could not delete Schedule Id = ${scheduleId}.`);
                    }
                });
        }
    }

    function onScheduleDelete() {
        getSchedules();
    }

    function onValueChanged_StartDate(e) {
        let startDate = e.value;
        let since = null;

        if (startDate != null) {
            startDate = moment(startDate).toDate();
            since = moment(startDate).format('X');
        }

        setSelectedSince(since);
    }

    function onSelectionChanged_Frequency(e) {
        setSelectedFrequency(e.selectedItem.seconds);
    }

    function onSelectionChanged_SavedFilter(e) {
        setSelectedFilterId(e.selectedItem.filterId);
    }

    function saveSchedule() {
        HistoryService.saveSchedule(selectedFilterId, selectedEmail, selectedSince, selectedFrequency)
            .then(response => {
                devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Save"), formatMessage("Schedule")), 'success');
                onSavedSchedule();
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error(`Could not save Schedule.`);
                    devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Failure_Save"), formatMessage("Schedule")), 'error');
                }
            });
    }

    function onSavedSchedule() {
        getSchedules();
        cleanFormFields();
    }

    function cleanFormFields() {
        setSelectedEmail("");
        setSelectedFilterId(null);
        setSelectedFrequency(null);
        setSelectedStartDate(dateNow);
    }

    return (
        <React.Fragment>

            <h2>{formatMessage("ScheduledExports")}</h2>

            <DataGrid
                dataSource={scheduleGridDataSource}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={true}
                height={'200px'}
                width={'100%'}
                keyExpr={'filterScheduleId'}
            >
                <Paging defaultPageSize={4} />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                />
                <LoadPanel enabled={true} />
                <Column
                    dataField="filterName"
                    dataType="string"
                    caption={formatMessage("FilterName")}
                />
                <Column
                    dataField="periodicity"
                    dataType="string"
                    caption={formatMessage("Frequency")}
                    cellRender={cellRender_Frequency}
                />
                <Column
                    dataField="startTime"
                    dataType="datetime"
                    caption={formatMessage("StartDate")}
                    cellRender={cellRender_StartDate}
                />
                <Column
                    dataField="parameters"
                    dataType="string"
                    caption={formatMessage("To")}
                />
                <Column
                    cellRender={cellRender_DeleteSchedule}
                />
            </DataGrid>

            <br /><br />

            <h2>{formatMessage("CreateNewScheduledExport")}</h2>

            <h3>{formatMessage("Frequency")}</h3>
            <SelectBox
                items={frequencyOptions}
                value={selectedFrequency}
                width={"100%"}
                displayExpr="name"
                valueExpr="seconds"
                onSelectionChanged={onSelectionChanged_Frequency}
            />

            <br /><br />

            <h3>{formatMessage("StartDate")}</h3>
            <DateBox
                value={selectedStartDate}
                type="datetime"
                width={"100%"}
                onValueChanged={onValueChanged_StartDate}
            />

            <br />
            <h3>{formatMessage("To")}</h3>
            <input type="text" value={selectedEmail} className="form-control" onChange={e => setSelectedEmail(e.target.value)} />

            <br />

            <h3>{formatMessage("Filter")}</h3>
            <SelectBox
                value={selectedFilterId}
                items={savedFiltersDataSource}
                width={"100%"}
                displayExpr="filterName"
                valueExpr="filterId"
                onSelectionChanged={onSelectionChanged_SavedFilter}
            />

            <br /><br />

            <div className="text-right">
                <button className="btn btn-primary" onClick={saveSchedule}>
                    {formatMessage("Apply")}
                </button>
            </div>

        </React.Fragment>
    );
}

export default HistoryFilterSchedule;