import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "/api/account";

const AuthenticationService = {

    login: function (userName, password) {
        return axiosInstance.post(
            `${URL_API}/login`,
            {
                Username: userName,
                Password: password
            }
        );
    },

    logout: function (token) {
        return axiosInstance.post(
            `${URL_API}/logout`,
            null,
            {
                params: {
                    token: token
                }
            }
        );
    },

    refreshToken: function () {
        console.info("Refreshing token...");

        return axiosInstance.post(
            `${URL_API}/refreshtoken`,
            null,
            {
                skipAuthRefresh: true,
                withCredentials: true
            }
        );
    }

}

export default AuthenticationService;