import React, { useContext, useEffect, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import LanguageContext from '../../utils/contexts/language-context';
import './controlBoard.css';
import '../Dashboard/dashboard.css';
import ControlBoardService from '../../services/controlBoard.service';
import TokenContext from '../../utils/contexts/token-context';
import feedbackMessages from '../../language/resources/feedback';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import VehicleInfo from './VehicleInfo';
import VehicleErrorsGrid from './VehicleErrorsGrid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Split } from "@geoffcox/react-splitter";
import VehiclesGrid from './VehiclesGrid';
import VehiclesGridToolbar from './VehiclesGridToolbar';

function ControlBoard() {
    const pageTitleObj = useContext(PageTitleContext);
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    const vehiclesGridRef = React.createRef();

    const [gridDataSource, setGridDataSource] = useState([]);
    const [vehicleDetails, setVehicleDetails] = useState(null);

    const [showErrors, setShowErrors] = useState(false);
    const [showActiveFleet, setShowActiveFleet] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(-1);
    const [selectedVehicleRowIndex, setSelectedVehicleRowIndex] = useState(0);
    const [workingHours, setWorkingHours] = useState(24);

    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);

    useEffect(() => {
        languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);
        setIsLanguageLoaded(true);

        getWorkingHours();
    }, [tokenObj.token]);

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("ControlBoard"));
    }, [languageObj.language]);

    useEffect(() => {
        getVehiclesInfo();
    }, [tokenObj.token, showActiveFleet, showErrors]);

    function getVehiclesInfo() {
        let vehicleGridDataStore = new CustomStore({
            key: 'componentId',
            load: function (loadOptions) {
                let params = {};
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && devextremeUtils.isNotEmpty(loadOptions[i])) {
                        params[i] = loadOptions[i];
                    }
                });

                let encodedParams = btoa(JSON.stringify(params));

                return ControlBoardService.getVehiclesInfo(showActiveFleet, showErrors, encodedParams)
                    .then(response => {
                        let responseData = response.data;
                        let vehiclesList = responseData.data;

                        for (let i = 0; i < vehiclesList.length; i++) {
                            let formatedDate = moment(vehiclesList[i].lastModification);
                            vehiclesList[i].lastModification = formatedDate;
                        }
                        return {
                            data: vehiclesList,
                            totalCount: responseData.totalCount
                        }
                    })
                    .catch(error => {
                        if (error.response !== undefined && error.response.status !== 401) {
                            console.error("Could not retrieve data for Vehicles Grid.");
                        }
                    });
            }
        });

        setGridDataSource(vehicleGridDataStore);
    }

    function getWorkingHours() {
        ControlBoardService.getWorkingHours()
            .then(response => {
                let responseData = response.data;
                setWorkingHours(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve Working Hours.");
                }
            });
    }

    return (
        <React.Fragment>
            {
                isLanguageLoaded ?
                    <div className="container-fluid h-100">
                        <div className="row h-8">
                            <VehiclesGridToolbar
                                vehiclesGridRef={vehiclesGridRef}
                            />
                        </div>

                        <div className="row h-92">
                            <Split splitterSize='10px' defaultSplitterColors={{ color: '#D4D4D4' }}>

                                <div className="h-100 col-12 latest-errors-error-grid pr-0">
                                    <VehiclesGrid
                                        vehiclesGridRef={vehiclesGridRef}
                                        gridDataSource={gridDataSource}
                                        selectedVehicle={selectedVehicle}
                                        selectedVehicleRowIndex={selectedVehicleRowIndex}
                                        showErrors={showErrors}
                                        showActiveFleet={showActiveFleet}
                                        setSelectedVehicle={setSelectedVehicle}
                                        setSelectedVehicleRowIndex={setSelectedVehicleRowIndex}
                                        setVehicleDetails={setVehicleDetails}
                                        setShowErrors={setShowErrors}
                                        setShowActiveFleet={setShowActiveFleet}
                                        workingHours={workingHours}
                                    />
                                </div>

                                <div className="h-100 col-12 vehicle-grid-details">
                                    {
                                        vehicleDetails &&
                                        (
                                            <div className="container-fluid h-100">

                                                <div className="row vehicle-info h-12">
                                                    <div className="col-sm-12 details h-100">
                                                        <VehicleInfo info={vehicleDetails} />
                                                    </div>
                                                </div>

                                                <div className="row vehicle-info h-88">
                                                    <VehicleErrorsGrid
                                                        vehicleId={selectedVehicle}
                                                        workingHours={workingHours}
                                                        showErrors={showErrors}
                                                        showActiveFleet={showActiveFleet} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </Split>
                        </div>
                    </div>

                    : <LoadPanel
                        visible={!isLanguageLoaded}
                        showIndicator={true}
                        showPane={true}
                    />
            }
        </React.Fragment>
    );
}

export default ControlBoard;