import { formatMessage } from 'devextreme/localization';
import React, { useContext, useEffect, useState } from 'react';
import feedbackMessages from '../../language/resources/feedback';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import LanguageContext from '../../utils/contexts/language-context';
import DataGrid, { Column, Scrolling, LoadPanel, Selection } from 'devextreme-react/data-grid';
import { Chart, Series, ArgumentAxis, Legend, Label, ValueAxis, Tick, Grid, Size, Animation, CommonSeriesSettings, Point } from 'devextreme-react/chart';

function Performance({
    dataSource
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const [chartDataSource, setChartDataSource] = useState([]);

    const [focusedRowIndex_Parameter, setFocusedRowIndex_Parameter] = useState(0);
    const [selectedParameter, setSelectedParameter] = useState(null);

    useEffect(() => {
        if (selectedParameter !== null) {
            let index = dataSource.findIndex(x => x.parameterName == selectedParameter);

            if (index !== -1) {
                setChartDataSource([...dataSource[index].valuesList]);
            }
        }
    }, [dataSource]);

    function onFocusedRowChanged_Parameter(e) {
        if (e.row == null) {
            return;
        }

        let rowIndex = e.row.Index;
        setFocusedRowIndex_Parameter(rowIndex);

        let focusedParameter = e.component.option('focusedRowKey');
        setSelectedParameter(focusedParameter);

        let index = dataSource.findIndex(x => x.parameterName == focusedParameter);

        if (index !== -1) {
            setChartDataSource(dataSource[index].valuesList);
        }
    }

    function cellRender_Value(cellData) {
        let data = cellData.data;
        let lastValueIndex = data.valuesList.length - 1;

        return (
            <div>{data.valuesList[lastValueIndex].value} {data.parameterUnits}</div>
        );
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-6">

                        <DataGrid
                            dataSource={dataSource}
                            keyExpr="parameterName"
                            remoteOperations={true}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={false}
                            showRowLines={true}
                            height={'250px'}
                            width={'100%'}
                            focusedRowEnabled={true}
                            focusedRowIndex={focusedRowIndex_Parameter}
                            onFocusedRowChanged={onFocusedRowChanged_Parameter}
                        >
                            <Selection mode="single" />
                            <Scrolling
                                mode="virtual"
                                rowRenderingMode="standard"
                                useNative={true}
                            />
                            <Column
                                dataField="parameterName"
                                dataType="string"
                                caption={formatMessage("Parameter")}
                            />
                            <Column
                                dataField="value"
                                dataType="string"
                                caption={formatMessage("Value")}
                                cellRender={cellRender_Value}
                            />
                        </DataGrid>

                    </div>

                    <div className="col-6">

                        <Chart
                            dataSource={chartDataSource}
                        >
                            <Animation
                                enabled={false}
                            />
                            <Size height={250} />
                            <Series
                                argumentField="timestamp"
                                valueField="value"
                                color="#56B150"
                            />
                            <CommonSeriesSettings>
                                <Point
                                    visible={true}
                                    size={8}
                                    hoverMode="none"
                                />
                            </CommonSeriesSettings>
                            <ArgumentAxis>
                                <Label
                                    overlappingBehavior="hide"
                                    wordWrap="normal"
                                />
                                <Tick visible={false} />
                            </ArgumentAxis>
                            <ValueAxis>
                                <Tick visible={false} />
                                <Grid visible={true} />
                            </ValueAxis>
                            <Legend visible={false} />
                        </Chart>

                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default Performance;