import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/controlboard";

const ControlBoardService = {

    getActiveErrors: function (vehicleId, errorState, since, until, filter) {
        return axiosInstance.get(
            `${URL_API}/vehicleerrors/${vehicleId}`,
            {
                params: {
                    errorState: errorState,
                    since: since,
                    until: until,
                    filter: filter
                }
            }
        );
    },

    getVehiclesInfo: function (showActiveFleet, showErrors, filter) {
        return axiosInstance.get(
            `${URL_API}/vehicles`,
            {
                params: {
                    showActiveFleet: showActiveFleet,
                    showErrors: showErrors,
                    filter: filter
                }
            }
        );
    },

    getVehicleDetails: function (vehicleId) {
        return axiosInstance.get(
            `${URL_API}/vehicledetails/${vehicleId}`
        );
    },

    getErrorPeriods: function (vehicleId, errorCode, errorLevel, since, until) {
        return axiosInstance.get(
            `${URL_API}/errorperiods`,
            {
                params: {
                    vehicleId: vehicleId,
                    errorCode: errorCode,
                    errorLevel: errorLevel,
                    since: since,
                    until: until
                }
            }
        );
    },

    getWorkingHours: function () {
        return axiosInstance.get(
            `${URL_API}/workinghours`);
    }

}

export default ControlBoardService;