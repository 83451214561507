import React, { useRef, useState, useEffect, useContext } from 'react';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapMatching from '@mapbox/mapbox-sdk/services/map-matching';
import 'mapbox-gl/dist/mapbox-gl.css';


function RouteMap({
    dataSource
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);
    const accessToken = 'pk.eyJ1IjoidmhtdGVzdDIyIiwiYSI6ImNqdnh2bWdnMTA4cDA0OXBreXd3M3JwamcifQ.mVvG6m-YIr-55KOYHCO71w';

    mapboxgl.accessToken = accessToken;
    const mapMatchingClient = MapMatching({ accessToken });

    const mapContainer = useRef(null);
    const map = useRef(null);

    // rawDataSource stores the not-snapped raw data
    const rawDataSource = useRef([]);

    const [mapType, setMapType] = useState("streets-v11");

    let gpsData = {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': []
                }
            }
        ]
    };

    useEffect(() => {
        if (map.current) {
            map.current.remove();
        }

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/' + mapType,
            zoom: 0
        });

        if (dataSource.length > 0) {
            map.current.jumpTo({ 'center': dataSource[dataSource.length - 1], 'zoom': 18 });
        }

        map.current.on('load', () => {
            addSource(dataSource);
            addLayer();
        });
    }, [mapType]);

    useEffect(() => {
        if (dataSource.length <= 1) { // Empty the raw data when starting
            rawDataSource.current = [];
        }
        if (dataSource.length == 1) {
            rawDataSource.current.push(dataSource[0]);
            let matchedPoints;
            mapMatchingClient.getMatch({ // Get snapped coordinates, we need at least 2 points for querying
                points: [
                    {
                        coordinates: dataSource[0],
                        approach: 'curb'
                    },
                    {
                        coordinates: dataSource[0],
                        approach: 'curb'
                    }
                ],
                geometries: "geojson",
                overview: "false"
            })
            .send()
            .then(response => {
                let responseBody = response.body;
                if (responseBody.code == "Ok" && typeof responseBody.tracepoints !== 'undefined' && responseBody.tracepoints.length > 1) { // Response OK
                    if (responseBody.tracepoints[responseBody.tracepoints.length - 1] == null) { // If the response was NULL, we use the raw coordinates
                        matchedPoints = [dataSource[0]];
                    }
                    else { // We update the dataSource
                        matchedPoints = responseBody.tracepoints[responseBody.tracepoints.length - 1].location;
                        dataSource[0] = matchedPoints;
                    }
                }
                else { // If the response wasn't ok, we use the raw coordinates
                    matchedPoints = [dataSource[0]]
                }
                gpsData.features[0].geometry.coordinates = matchedPoints;

                // setup the viewport
                map.current.jumpTo({ 'center': matchedPoints, 'zoom': 18 });
                map.current.setPitch(30);
            });
            
        }

        if (dataSource.length > 1) {

            // If the current coordinates (in dataSource) are the same as the previous (last element of the rawDataSource), we don't need to query for snapped data, we use the same
            if (rawDataSource.current[rawDataSource.current.length - 1][0] == dataSource[dataSource.length - 1][0] && rawDataSource.current[rawDataSource.current.length - 1][1] == dataSource[dataSource.length - 1][1]) {
                rawDataSource.current.push(dataSource[dataSource.length - 1]);

                // After copying the new coordinates to the raw data source, we substitute them for the previously corrected coordinates
                dataSource[dataSource.length - 1] = dataSource[dataSource.length - 2];

                gpsData.features[0].geometry.coordinates = dataSource;

                if (map.current.getSource('trace') == undefined) {
                    // if source trace is undefined, map had no time to load between messages
                    return;
                }

                map.current.getSource('trace').setData(gpsData);
                map.current.panTo(dataSource[dataSource.length - 1]);
            }
            else {
                rawDataSource.current.push(dataSource[dataSource.length - 1]);
                let coords = dataSource.slice(-10); // We use the last 10 points for calculating the snapped data
                const pointsArray = [];

                // We need to create the structure of the query
                for (const coord of coords) {
                    pointsArray.push({ coordinates: coord, approach: 'curb' });
                }

                // If there's only one point, we have to duplicate it because the API accepts a minimum of 2
                if (pointsArray.length == 1) {
                    let point = pointsArray[0];
                    pointsArray.push(point);
                }
                mapMatchingClient.getMatch({ // Get snapped coordinates
                    points: pointsArray,
                    geometries: "geojson",
                    overview: "false"
                })
                .send()
                .then(response => {
                    let responseBody = response.body;
                    
                    if (responseBody.code == "Ok" && typeof responseBody.tracepoints !== 'undefined' && responseBody.tracepoints.length > 1) { // Response OK
                        let matchedPoints = [];
                        let i = responseBody.tracepoints.length;
                        for (const point of responseBody.tracepoints) {
                             // If the response had some NULL points because it couldn't find a match, we use the original coordinates, stored in the raw data source
                            if (point == null) {

                                matchedPoints.push(rawDataSource.current[rawDataSource.current.length - i]);
                            }
                            else {
                                matchedPoints.push(point.location);
                            }
                            i--;
                        }

                        // We substitute the points of the data source with the corrected points of the response
                        const pointsLength = matchedPoints.length;
                        dataSource.splice(-pointsLength, pointsLength, ...matchedPoints);
                    }

                    gpsData.features[0].geometry.coordinates = dataSource;

                    if (map.current.getSource('trace') == undefined) {
                        // if source trace is undefined, map had no time to load between messages
                        return;
                    }

                    map.current.getSource('trace').setData(gpsData);
                    map.current.panTo(dataSource[dataSource.length - 1]);
                });
            }
                        
        }
    }, [dataSource]);

    function addSource(coordinates) {
        if (coordinates.length !== 0) {
            gpsData.features[0].geometry.coordinates = coordinates;
        }

        map.current.addSource('trace', { type: 'geojson', data: gpsData });
    }

    function addLayer() {
        map.current.addLayer({
            'id': 'trace',
            'type': 'line',
            'source': 'trace',
            'paint': {
                'line-color': '#63A6DF',
                'line-width': 7
            }
        });
    }

    return (
        <React.Fragment>
            <div className="map-type-switcher">

                <div className="btn-group" role="group">
                    <button type="button" className={mapType === "streets-v11" ? "btn btn-primary" : "btn btn-secondary"} onClick={() => { setMapType("streets-v11") }}>
                        {formatMessage("Streets")}
                    </button>
                    <button type="button" className={mapType === "satellite-v9" ? "btn btn-primary" : "btn btn-secondary"} onClick={() => { setMapType("satellite-v9") }}>
                        {formatMessage("Satellite")}
                    </button>
                </div>

            </div>

            <div ref={mapContainer} className="map-container" />
        </React.Fragment>
    );
}

export default RouteMap;