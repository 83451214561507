import React, { useContext, useEffect, useRef, useState } from 'react';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import List from 'devextreme-react/list';
import TreeView from 'devextreme-react/tree-view';
import './history.css';
import HistoryService from '../../services/history.service';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import TextBox from 'devextreme-react/text-box';

export function ErrorStatusItem(data) {
    let status = data.key;

    switch (status) {
        case 1: // Warning
            return (
                <div>
                    <span className="icon icon-warning error-status-icon warning"></span> {formatMessage("Warning")}
                </div>
            );

        case 2: // Error
            return (
                <div>
                    <span className="icon icon-error error-status-icon error"></span> {formatMessage("Error")}
                </div>
            );

        case 3: // Critical
            return (
                <div>
                    <span className="icon icon-warning error-status-icon critical"></span> {formatMessage("Critical")}
                </div>
            );
    }
}

export function ErrorStatusField(data) {
    if (data != null) {

        switch (data.key) {
            case 1:
                return (
                    <div className="custom-status-field">
                        <span className="icon icon-warning error-status-icon warning"></span>
                        <div className="customStatus_TextBox">
                            <TextBox
                                className="status-name"
                                defaultValue={formatMessage("Warning")}
                                readOnly={true} />
                        </div>
                    </div>
                );

            case 2:
                return (
                    <div className="custom-status-field">
                        <span className="icon icon-error error-status-icon error"></span>
                        <div className="customStatus_TextBox">
                            <TextBox
                                className="status-name"
                                defaultValue={formatMessage("Error")}
                                readOnly={true} />
                        </div>
                    </div>
                );

            case 3:
                return (
                    <div className="custom-status-field">
                        <span className="icon icon-warning error-status-icon critical"></span>
                        <div className="customStatus_TextBox">
                            <TextBox
                                className="status-name"
                                defaultValue={formatMessage("Critical")}
                                readOnly={true} />
                        </div>
                    </div>
                );
        }
    }

    return (
        <div className="custom-status-field">
            <TextBox
                className="status-name"
                defaultValue={formatMessage("Any")}
                readOnly={true} />
        </div>
    );
}

function Filter({
    selectedVehicleId,
    selectedManufacturerId,
    selectedTypeId,
    selectedYearOfConstruction,
    selectedFleetIds,
    selectedFleetIdsForSaving,
    selectedSince,
    selectedUntil,
    selectedEcus,
    selectedErrorStatus,

    fleetSelectionDataSource,
    vehiclesDataSource,
    manufacturersDataSource,
    typesDataSource,
    yearsOfConstructionDataSource,
    ecusDataSource,
    errorStatusDataSource,

    selectedStartDate,
    selectedEndDate,
    dateRange,

    setFleetSelectionDataSource,
    setSelectedFleetIds,
    setSelectedFleetIdsForSaving,
    setFleetNodes,
    setDateRange,
    setSelectedStartDate,
    setSelectedEndDate,
    setSelectedSince,
    setSelectedUntil,
    setSelectedVehicleId,
    setSelectedManufacturerId,
    setSelectedManufacturerLabel,
    setSelectedTypeId,
    setSelectedTypeLabel,
    setSelectedYearOfConstruction,
    setSelectedEcus,
    setSelectedErrorStatus,

    resetFleetSelection,
    setResetFleetSelection,

    savedFiltersDataSource,
    getAllSavedFilters,

    currentSavedFilterId,
    setCurrentSavedFilterId,
    setCurrentSavedFilterName,

    resetAllFilters
}) {

    const languageObj = useContext(LanguageContext);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    let ref_FleetSelection = useRef();
    let ref_StartDate = useRef();
    let ref_EndDate = useRef();

    const [ecusArray, setEcusArray] = useState([]);

    useEffect(() => {
        if (resetFleetSelection) {
            ref_FleetSelection.current.instance.unselectAll();
            setResetFleetSelection(false);
            setDateRange(null);
            setEcusArray([]);
        }
    }, [resetFleetSelection]);

    function onSelectionChanged_FleetSelection(e) {
        let treeView = e.component;

        let nodes = treeView.getNodes().map((node) => node.itemData);
        setFleetNodes(nodes);

        nodes = treeView.getSelectedNodes()
            .filter(node => node.itemData.id !== null)
            .map(node => node.itemData.id);

        setSelectedFleetIds(nodes.toString()); // For Apply, show Unassigned

        // Remove null and Unnasigned Fleet
        let index = nodes.indexOf(null);
        if (index > -1) {
            nodes.splice(index, 1);
        }
        index = nodes.indexOf(-1);
        if (index > -1) {
            nodes.splice(index, 1);
        }

        setSelectedFleetIdsForSaving(nodes.toString()); // Remove Unassigned from Saving
    }

    function onItemClick_DateRange(selectedRange) {
        setDateRange(selectedRange);

        let amountOfDays = 7; // default value
        let today = moment();

        switch (selectedRange) {
            case "30days":
                amountOfDays = 30;
                break;

            case "thisyear":
                let startOfYear = moment().startOf('year');
                let result = startOfYear.diff(today, 'days');
                amountOfDays = Math.abs(result);
                break;

            default:
                amountOfDays = 7;
                break;
        }

        let target = moment(today).subtract(amountOfDays, 'd');
        target = target.startOf('day'); // entire target day since 00h00m00s

        let since = moment(target).format('X');
        let until = moment(today).format('X');

        setSelectedSince(since);
        setSelectedUntil(until);

        let startDate = moment(target).toDate();
        let endDate = moment(today).toDate();

        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    }

    function onValueChanged_StartDate(e) {
        let startDate = e.value;
        let since = null;

        if (startDate != null) {
            startDate = moment(startDate).toDate();
            since = moment(startDate).format('X');
        }

        setSelectedStartDate(startDate);
        setSelectedSince(since);
    }

    function onValueChanged_EndDate(e) {
        let endDate = e.value;
        let until = null;

        if (endDate != null) {
            endDate = moment(endDate).toDate();
            until = moment(endDate).format('X');
        }

        setSelectedEndDate(endDate);
        setSelectedUntil(until);
    }

    function onSelectionChanged_VehicleId(e) {
        e.selectedItem === null ? setSelectedVehicleId(null) : setSelectedVehicleId(e.selectedItem.id);
    }

    function onSelectionChanged_Manufacturer(e) {
        if (e.selectedItem === null) {
            setSelectedManufacturerId(null)
            setSelectedManufacturerLabel(null)
        } else {
            setSelectedManufacturerId(e.selectedItem.key);
            setSelectedManufacturerLabel(e.selectedItem.value);
        }
    }

    function onSelectionChanged_Type(e) {
        if (e.selectedItem === null) {
            setSelectedTypeId(null);
            setSelectedTypeLabel(null);
        } else {
            setSelectedTypeId(e.selectedItem.key);
            setSelectedTypeLabel(e.selectedItem.value);
        }
    }

    function onSelectionChanged_YearOfConstruction(e) {
        e.selectedItem === null ? setSelectedYearOfConstruction(null) : setSelectedYearOfConstruction(e.selectedItem.key);
    }

    function onOptionChanged_ECUFilter(e) {
        if (e.name === 'selectedItems') {
            setEcuOptions(e.value)
        }
    }

    function onSelectionChanged_ErrorStatus(e) {
        e.selectedItem === null ? setSelectedErrorStatus(null) : setSelectedErrorStatus(e.selectedItem.key);
    }

    function deleteFilterPopup(filterId) {

        if (filterId === null)
            return;

        let isConfirmed = window.confirm(languageUtils.stringFormat(formatMessage("Confirm_Delete"), formatMessage("Schedule")));

        if (isConfirmed) {
            HistoryService.getAllSchedules()
                .then(response => {
                    let responseData = response.data;
                    const schedulesList = responseData.data;

                    let filtersAssociated = 0;
                    for (let i = 0; i < schedulesList.length; i++) {
                        if (schedulesList[i].filterId === filterId) {
                            filtersAssociated++;
                        }
                    }

                    if (filtersAssociated > 0) {
                        devextremeUtils.displayNotification(formatMessage("Warning_AssociatedSchedules"), 'warning');
                    } else {
                        deleteFilter(filterId);
                    }
                })
                .catch(error => {
                    if (error.response !== undefined && error.response.status !== 401) {
                        console.error("Could not retrieve all saved schedules.");
                    }
                });
        }
    }

    function deleteFilter(filterId) {
        HistoryService.deleteFilter(filterId)
            .then(response => {
                devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Success_Delete"), formatMessage("Filter")), 'success');
                getAllSavedFilters();
                resetAllFilters();
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error(`Could not delete Filter Id = ${filterId}.`);
                    devextremeUtils.displayNotification(languageUtils.stringFormat(formatMessage("Failure_Delete"), formatMessage("Filter")), 'error');
                }
            });
    }

    function getFilter(filterId) {
        HistoryService.getFilter(filterId)
            .then(response => {
                let responseData = response.data;
                loadForm_SavedFilter(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error(`Could not retrieve Filter Id = ${filterId}.`);
                }
            });
    }

    function loadForm_SavedFilter(info) {
        setSelectedStartDate(info.startTime);
        setSelectedEndDate(info.endTime);
        setSelectedYearOfConstruction(info.year);
        setSelectedErrorStatus(info.severity);

        let type = typesDataSource.filter(function (item) { return item.value === info.type; });
        if (type.length > 0) {
            setSelectedTypeId(type[0].key);
            setSelectedTypeLabel(type[0].value);
        } else {
            setSelectedTypeId(null);
            setSelectedTypeLabel(null);
        }

        let manufacturer = manufacturersDataSource.filter(function (item) { return item.value === info.manufacturer; });
        if (manufacturer.length > 0) {
            setSelectedManufacturerId(manufacturer[0].key);
            setSelectedManufacturerLabel(manufacturer[0].value);
        } else {
            setSelectedManufacturerId(null);
            setSelectedManufacturerLabel(null);
        }

        let options = info.ecu.split(',');
        setEcuOptions(options)

        let fleetIds = info.fleetIds;

        HistoryService.getFleets()
            .then(response => {
                let responseData = response.data;

                let data = [];
                data.push(responseData.root);

                for (let i = 0; i < data.length; i++) {
                    data[i].expanded = true;
                }

                if (fleetIds.length !== 0) {
                    for (let i = 0; i < data[0].items.length; i++) {
                        if (fleetIds.includes(data[0].items[i].id)) {
                            data[0].items[i].selected = true;
                        } else {
                            data[0].items[i].selected = false;
                        }
                    }
                }

                setFleetSelectionDataSource(data);
                setFleetNodes(data);
                setSelectedFleetIds(fleetIds.toString());
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Fleet Selection tree view.");
                }
            });
    }

    function onSelectionChanged_SavedFilter(e) {
        if (e.selectedItem !== null) {
            setCurrentSavedFilterId(e.selectedItem.filterId);
            setCurrentSavedFilterName(e.selectedItem.filterName);
            getFilter(e.selectedItem.filterId);
        }
        else {
            setCurrentSavedFilterId(null);
            setCurrentSavedFilterName(null);
        }

    }

    function onContentReady_FleetSelection(e) {
        const scrollWidget = Scrollable.getInstance(e.element.querySelector('.dx-scrollable'));
        scrollWidget.option('useNative', true);
    }

    function setEcuOptions(options) {
        if (options.length > 0) {
            setEcusArray(options);
            let ecus = options.toString();
            setSelectedEcus(ecus);
        }
        else {
            setEcusArray(null);
            setSelectedEcus(null);
        }
    }

    return (
        <React.Fragment>
            <div>
                <h2>{formatMessage("SavedFilter")}</h2>
                <div className="mt-2" style={{ display: "flex" }}>
                    <SelectBox
                        placeholder={formatMessage("Select") + "..."}
                        items={savedFiltersDataSource}
                        value={currentSavedFilterId}
                        width={"95%"}
                        displayExpr="filterName"
                        valueExpr="filterId"
                        onSelectionChanged={onSelectionChanged_SavedFilter}
                        onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                        showClearButton={true}
                    />

                    <button className="btn btn-secondary pr-0" onClick={() => deleteFilterPopup(currentSavedFilterId)}><span className="icon icon-error"></span></button>

                </div>

                <div className="mt-4" />

                <h2>{formatMessage("Fleet")}</h2>
                <div id="treeView_Operators">
                    <TreeView
                        ref={ref_FleetSelection}
                        items={fleetSelectionDataSource}
                        width="100%"
                        keyExpr="treeId"
                        displayExpr="text"
                        itemsExpr="items"
                        selectNodesRecursive={false}
                        showCheckBoxesMode="normal"
                        selectionMode="multiple"
                        selectByClick={true}
                        onSelectionChanged={onSelectionChanged_FleetSelection}
                        onContentReady={onContentReady_FleetSelection}
                    />
                </div>

                <div className="mt-4" />

                <h2>{formatMessage("DateRange")}</h2>
                <div className="btn-group date-range mt-2" role="group">
                    <button type="button" className={dateRange === "7days" ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => onItemClick_DateRange("7days")}>{languageUtils.stringFormat(formatMessage("XDays"), 7)}</button>
                    <button type="button" className={dateRange === "30days" ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => onItemClick_DateRange("30days")}>{languageUtils.stringFormat(formatMessage("XDays"), 30)}</button>
                    <button type="button" className={dateRange === "thisyear" ? "btn btn-secondary active" : "btn btn-secondary"} onClick={() => onItemClick_DateRange("thisyear")}>{formatMessage("ThisYear")}</button>
                </div>

                <br />

                <h3>{formatMessage("StartDate")}</h3>
                <DateBox
                    ref={ref_StartDate}
                    value={selectedStartDate}
                    placeholder={formatMessage("Select") + "..."}
                    type="datetime"
                    width={"100%"}
                    onValueChanged={onValueChanged_StartDate}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="h-spacer-m" />

                <h3>{formatMessage("EndDate")}</h3>
                <DateBox
                    ref={ref_EndDate}
                    value={selectedEndDate}
                    placeholder={formatMessage("Select") + "..."}
                    type="datetime"
                    width={"100%"}
                    onValueChanged={onValueChanged_EndDate}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="mt-4" />
                <h2>{formatMessage("VehicleFilter")}</h2>
                <h3 className="mt-2">{formatMessage("VehicleId")}</h3>
                <SelectBox
                    placeholder={formatMessage("Select") + "..."}
                    items={vehiclesDataSource}
                    value={selectedVehicleId}
                    width={"100%"}
                    displayExpr="operationalId" // This will later be dependent on if config is technicalId or operationalId
                    valueExpr="id"
                    onSelectionChanged={onSelectionChanged_VehicleId}
                    onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="h-spacer-m" />

                <h3>{formatMessage("Manufacturer")}</h3>
                <SelectBox
                    placeholder={formatMessage("Select") + "..."}
                    items={manufacturersDataSource}
                    value={selectedManufacturerId}
                    width={"100%"}
                    displayExpr="value"
                    valueExpr="key"
                    onSelectionChanged={onSelectionChanged_Manufacturer}
                    onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="h-spacer-m" />

                <h3>{formatMessage("Type")}</h3>
                <SelectBox
                    placeholder={formatMessage("Select") + "..."}
                    items={typesDataSource}
                    value={selectedTypeId}
                    width={"100%"}
                    displayExpr="value"
                    valueExpr="key"
                    onSelectionChanged={onSelectionChanged_Type}
                    onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="h-spacer-m" />

                <h3>{formatMessage("YearOfConstruction")}</h3>
                <SelectBox
                    placeholder={formatMessage("Select") + "..."}
                    items={yearsOfConstructionDataSource}
                    value={selectedYearOfConstruction}
                    width={"100%"}
                    displayExpr="value"
                    valueExpr="key"
                    onSelectionChanged={onSelectionChanged_YearOfConstruction}
                    showClearButton={true}
                    className="mt-1"
                />

                <div className="mt-4" />
                <h2>{formatMessage("ErrorStatus")}</h2>
                <SelectBox
                    placeholder={formatMessage("Select") + "..."}
                    items={errorStatusDataSource}
                    defaultValue={selectedErrorStatus}
                    width={"100%"}
                    displayExpr="value"
                    valueExpr="key"
                    placeholder={formatMessage("Any")}
                    onSelectionChanged={onSelectionChanged_ErrorStatus}
                    onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                    showClearButton={true}
                    className="mt-2"
                    itemRender={ErrorStatusItem}
                    fieldRender={ErrorStatusField}
                />

                <div className="mt-4" />
                <h2>{formatMessage("ECUFilter")}</h2>
                <List
                    dataSource={ecusDataSource}
                    width={"100%"}
                    showSelectionControls={false}
                    selectionMode="multiple"
                    onOptionChanged={onOptionChanged_ECUFilter}
                    selectedItemKeys={ecusArray}
                    useNativeScrolling={true}
                    className="mt-2"
                />

            </div>

            <div className="h-spacer-m" />

        </React.Fragment>
    );
}

export default Filter;