import React, { useEffect, useState, useContext } from 'react';
import ControlBoardService from '../../services/controlBoard.service';
import moment from 'moment';
import applicationUtils from '../../utils/configs/applicationUtils';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import TokenContext from '../../utils/contexts/token-context';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import LanguageContext from '../../utils/contexts/language-context';
import './controlBoard.css';

export function wrapErrorDetailTemplate(Component, workingHours, timelineOpenKey) {
    return (props) => {
        return (<Component workingHours={workingHours} timelineOpenKey={timelineOpenKey} {...props}></Component>)
    };
}

function ErrorDetailTemplate({ data, workingHours, timelineOpenKey }) {
    const tokenObj = useContext(TokenContext);
    const languageObj = useContext(LanguageContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const [timelineDataSource, setTimelineDataSource] = useState([]);
    const [timelineScale, setTimelineScale] = useState(8); // 8 hours
    const [timelineCellDuration, setTimelineCellDuration] = useState(480); // 8 hours

    const [timelineIsOpen, setTimelineIsOpen] = useState(false);

    let componentId = data.componentId;
    let message = data.message;
    let errorCode = data.errorCode;
    let errorLevel = data.errorLevel;

    const today = moment(); // now
    let until = moment(today).format('X');

    let target = moment(today).subtract(workingHours, 'hours');
    let since = moment(target).format('X');

    useEffect(() => {
        getErrorPeriods(componentId, errorCode, errorLevel, since, until);
    }, [data, tokenObj.token]);

    useEffect(() => {
        if (data.customKey == timelineOpenKey) {
            setTimelineIsOpen(true);
        } else {
            setTimelineIsOpen(false);
        }
    }, [timelineOpenKey]);

    function getErrorPeriods(componentId, errorCode, errorLevel, since, until) {
        ControlBoardService.getErrorPeriods(componentId, errorCode, errorLevel, since, until)
            .then(response => {
                let responseData = response.data;
                let timeline = responseData.data;

                for (let i = 0; i < timeline.length; i++) {
                    applicationUtils.renameKey(timeline[i], "start", "startDate");
                    applicationUtils.renameKey(timeline[i], "end", "endDate");
                    timeline[i].componentId = componentId;

                    if (timeline[i].endDate === null) { // when error is still Not Resolved
                        let endOfDay = today.endOf('day');
                        let endDate = endOfDay.format('YYYY-MM-DDTkk:mm:ss');
                        timeline[i].endDate = endDate;
                    }
                }

                setTimelineDataSource([...timeline]);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for timeline.");
                }
            });
    }

    function onAppointmentClick(e) {
        e.cancel = true;
    }

    function onAppointmentDblClick(e) {
        e.cancel = true;
    }

    function appointmentTemplate(model) {
        return (
            <div></div>
        );
    }

    const timelineResource = [
        {
            text: "Component",
            id: componentId,
            color: "#F02D28"
        }
    ];

    const currentDate = new Date();
    let schedulerStartDate = null;
    let views = [];

    if (workingHours <= 24) {
        schedulerStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        views.push({ name: "2Days", type: 'timelineDay', intervalCount: 2, startDate: schedulerStartDate })
    } else {
        schedulerStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2);
        views.push({ name: "3Days", type: 'timelineDay', intervalCount: 3, startDate: schedulerStartDate })
    }

    function increaseTimelineScale() {
        switch (timelineScale) {
            case 1:
                setTimelineScale(2);
                setTimelineCellDuration(120);
                break;

            case 2:
                setTimelineScale(4);
                setTimelineCellDuration(240);
                break;

            case 4:
                setTimelineScale(6);
                setTimelineCellDuration(360);
                break;

            case 6:
                setTimelineScale(8);
                setTimelineCellDuration(480);
                break;

            default:
                // Do not change scale.
                break;
        }
    }

    function decreaseTimelineScale() {
        switch (timelineScale) {
            case 8:
                setTimelineScale(6);
                setTimelineCellDuration(360);
                break;

            case 6:
                setTimelineScale(4);
                setTimelineCellDuration(240);
                break;

            case 4:
                setTimelineScale(2);
                setTimelineCellDuration(120);
                break;

            case 2:
                setTimelineScale(1);
                setTimelineCellDuration(60);
                break;

            default:
                // Do not change scale.
                break;
        }
    }

    function onContentReady(e) {
        const scroll = e.component.getWorkSpaceScrollable();
        const scrollInstance = scroll.instance();
        scrollInstance.option('useNative', true);
    }

    return (
        <div>
            <p className="error-details">{message}</p>

            <div className={timelineIsOpen ? "timeline-show" : "timeline-hide"}>

                <Scheduler
                    dataSource={timelineDataSource}
                    crossScrollingEnabled={true}
                    editing={false}
                    defaultCurrentDate={schedulerStartDate}
                    showCurrentTimeIndicator={false}
                    views={views}
                    defaultCurrentView="timelineDay"
                    startDayHour={0}
                    cellDuration={timelineCellDuration}
                    onAppointmentClick={onAppointmentClick}
                    appointmentTemplate={appointmentTemplate}
                    onAppointmentDblClick={onAppointmentDblClick}
                    onContentReady={onContentReady}
                >
                    <Resource
                        dataSource={timelineResource}
                        fieldExpr="componentId"
                        allowMultiple={false}
                    />
                </Scheduler>

                <div className="container-fluid">

                    <div className="row timeline-scale-controls">

                        <div className="scheduler-space" />

                        <div className="text-left">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary btn-scale" onClick={increaseTimelineScale}><span className="icon icon-indeterminate pr-0"></span></button>
                                <button type="button" className="btn btn-secondary btn-scale" onClick={decreaseTimelineScale}><span className="icon icon-create pr-0"></span></button>
                            </div>
                        </div>

                        <div className="text-left pl-2 timeline-hour-label">
                            <label>{timelineScale + " h"}</label>
                        </div>
                    </div>

                </div>

            </div>

            

        </div>
    );

}

export default ErrorDetailTemplate;