import React, { useContext, useEffect, useState } from 'react';
import './history.css';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import applicationUtils from '../../utils/configs/applicationUtils';
import LanguageContext from '../../utils/contexts/language-context';
import TokenContext from '../../utils/contexts/token-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import moment from 'moment';
import HistoryService from '../../services/history.service';
import HistoryGrid from './HistoryGrid';
import Filter from './Filter';
import SaveFilter from './FilterPopups/SaveFilter';
import CustomStore from 'devextreme/data/custom_store';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import { Split } from "@geoffcox/react-splitter";
import HistoryGridToolbar from './HistoryGridToolbar';
import { LoadPanel } from 'devextreme-react/load-panel';

function History() {
    const pageTitleObj = useContext(PageTitleContext);
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const dateNow = moment();
    const dataGridRef = React.createRef();

    const [gridDataSource, setGridDataSource] = useState([]);
    const [fleetNodes, setFleetNodes] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(dateNow);
    const [selectedEndDate, setSelectedEndDate] = useState(dateNow);

    /* Data sources for filter */
    const [fleetSelectionDataSource, setFleetSelectionDataSource] = useState([]);
    const [vehiclesDataSource, setVehiclesDataSource] = useState([]);
    const [manufacturersDataSource, setManufacturersDataSource] = useState([]);
    const [typesDataSource, setTypesDataSource] = useState([]);
    const [yearsOfConstructionDataSource, setYearsOfConstructionDataSource] = useState([]);
    const [ecusDataSource, setEcusDataSource] = useState([]);
    const [errorStatusDataSource, setErrorStatusDataSource] = useState([]);

    /* Filtered data */
    const [selectedFleetIds, setSelectedFleetIds] = useState(null);
    const [selectedFleetIdsForSaving, setSelectedFleetIdsForSaving] = useState(null);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);
    const [selectedManufacturerId, setSelectedManufacturerId] = useState(null);
    const [selectedManufacturerLabel, setSelectedManufacturerLabel] = useState(null);
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [selectedTypeLabel, setSelectedTypeLabel] = useState(null);
    const [selectedYearOfConstruction, setSelectedYearOfConstruction] = useState(null);
    const [selectedEcus, setSelectedEcus] = useState(null);
    const [selectedSince, setSelectedSince] = useState(dateNow.format('X'));
    const [selectedUntil, setSelectedUntil] = useState(dateNow.format('X'));
    const [selectedErrorStatus, setSelectedErrorStatus] = useState(null);

    const [resetFleetSelection, setResetFleetSelection] = useState(false);
    const [savedFiltersDataSource, setSavedFiltersDataSource] = useState([]);

    const [currentSavedFilterName, setCurrentSavedFilterName] = useState(null);
    const [currentSavedFilterId, setCurrentSavedFilterId] = useState(null);

    const [isPopupVisible_SaveFilter, setIsPopupVisible_SaveFilter] = useState(false);

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("History"));
    }, [languageObj.language]);

    useEffect(() => {
        getFleets();
        getErrorStatuses();
        getAllSavedFilters();
    }, [tokenObj.token]);

    useEffect(() => {
        getVehicles(selectedFleetIds, selectedManufacturerId, selectedTypeId, selectedYearOfConstruction);
    }, [selectedFleetIds, selectedManufacturerId, selectedTypeId, selectedYearOfConstruction, tokenObj.token]);

    useEffect(() => {
        getManufacturers(selectedFleetIds, selectedVehicleId, selectedTypeId, selectedYearOfConstruction);
    }, [selectedFleetIds, selectedVehicleId, selectedTypeId, selectedYearOfConstruction, tokenObj.token]);

    useEffect(() => {
        getTypes(selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedYearOfConstruction);
    }, [selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedYearOfConstruction, tokenObj.token]);

    useEffect(() => {
        getYearsOfConstruction(selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedTypeId);
    }, [selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedTypeId, tokenObj.token]);

    useEffect(() => {
        getEcus(selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedTypeId, selectedYearOfConstruction);
    }, [selectedFleetIds, selectedVehicleId, selectedManufacturerId, selectedTypeId, selectedYearOfConstruction, tokenObj.token]);

    useEffect(() => {
        getHistoryData();
    }, [selectedFleetIds, selectedSince, selectedUntil, selectedVehicleId, selectedManufacturerId, selectedTypeId, selectedYearOfConstruction, selectedEcus, selectedErrorStatus, tokenObj.token]);

    useEffect(() => {
        if (currentSavedFilterId === null) {
            resetAllFilters();
        }
    }, [currentSavedFilterId]);

    function getHistoryData() {
        let historyGridDataSource = new CustomStore({
            key: 'componentId',
            load: function (loadOptions) {
                let params = {};
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'sort',
                    'filter',
                    'totalSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && devextremeUtils.isNotEmpty(loadOptions[i])) {
                        params[i] = loadOptions[i];
                    }
                });

                let encodedParams = btoa(JSON.stringify(params));
                return HistoryService.getVehiclesInfo(encodedParams, selectedFleetIds, selectedSince, selectedUntil, selectedVehicleId, selectedManufacturerId,
                    selectedTypeId, selectedYearOfConstruction, selectedErrorStatus, selectedEcus)
                    .then(response => {
                        let responseData = response.data;
                        let vehiclesList = responseData.data;

                        return {
                            data: vehiclesList,
                            totalCount: responseData.totalCount
                        }
                    })
                    .catch(error => {
                        if (error.response !== undefined && error.response.status !== 401) {
                            console.error("Could not retrieve data for History Grid.");
                        }
                    });
            }
        });

        setGridDataSource(historyGridDataSource);
    }

    function getFleets() {
        if (fleetNodes !== null) {
            setFleetSelectionDataSource(fleetNodes);
            return;
        }

        HistoryService.getFleets()
            .then(response => {
                let responseData = response.data;
                responseData.root.items = applicationUtils.sortArray(responseData.root.items, 'text');
                setFleetSelectionDataSource(responseData.root.items);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Fleet Selection tree view.");
                }
            });
    }

    function getVehicles(fleetIds, manufacturerId, componentType, year) {
        HistoryService.getVehicleIdentifiers(fleetIds, manufacturerId, componentType, year)
            .then(response => {
                let responseData = response.data;

                response.data.data = applicationUtils.sortArray(response.data.data, 'vin');
                setVehiclesDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Vehicle ID select box.");
                }
            });
    }

    function getManufacturers(fleetIds, vehicleId, componentType, year) {
        HistoryService.getVehicleManufacturers(fleetIds, vehicleId, componentType, year)
            .then(response => {
                let responseData = response.data;
                response.data.data = applicationUtils.sortArray(response.data.data, 'value');
                setManufacturersDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Manufacturer select box.");
                }
            });
    }

    function getTypes(fleetIds, vehicleId, manufacturerId, year) {
        HistoryService.getVehicleTypes(fleetIds, vehicleId, manufacturerId, year)
            .then(response => {
                let responseData = response.data;
                response.data.data = applicationUtils.sortArray(response.data.data, 'value');
                setTypesDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Type select box.");
                }
            });
    }

    function getYearsOfConstruction(fleetIds, vehicleId, manufacturerId, componentType) {
        HistoryService.getVehicleYears(fleetIds, vehicleId, manufacturerId, componentType)
            .then(response => {
                let responseData = response.data;
                response.data.data = applicationUtils.sortArray(response.data.data, 'value');
                setYearsOfConstructionDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Years Of Construction select box.");
                }
            });
    }

    function getEcus(fleetIds, vehicleId, manufacturerId, componentType, year) {
        HistoryService.getVehicleEcus(fleetIds, vehicleId, manufacturerId, componentType, year)
            .then(response => {
                let responseData = response.data;
                responseData.data = applicationUtils.sortArray(responseData.data, 'value');
                let ecus = responseData.data.map((ecu) => ecu.value);
                setEcusDataSource(ecus);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for ECU Filter list box.");
                }
            });
    }

    function getErrorStatuses() {
        HistoryService.getErrorLevels()
            .then(response => {
                let responseData = response.data.data;

                let idx = responseData.findIndex(e => e.key == 0);
                if (idx != -1) {
                    responseData.splice(idx, 1);
                }
                responseData = applicationUtils.sortArray(responseData, 'value');
                setErrorStatusDataSource(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Error Status select box.");
                }
            });
    }

    function getAllSavedFilters() {
        HistoryService.getAllFilters()
            .then(response => {
                let responseData = response.data;

                let filtersList = responseData.data;
                filtersList = filtersList.map((entry) => { return { filterId: entry.filterId, filterName: entry.filterName } });
                filtersList = applicationUtils.sortArray(filtersList, 'filterName');
                setSavedFiltersDataSource(filtersList);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve all saved filters.");
                }
            });
    }

    function resetAllFilters() {
        setSelectedFleetIds(null);
        setSelectedStartDate(null);
        setSelectedEndDate(null);

        setSelectedVehicleId(null);
        setSelectedManufacturerId(null);
        setSelectedManufacturerLabel(null);
        setSelectedTypeId(null);
        setSelectedTypeLabel(null);
        setSelectedYearOfConstruction(null);
        setSelectedErrorStatus(null);

        setCurrentSavedFilterId(null);
        setCurrentSavedFilterName(null);

        setResetFleetSelection(true);
    }

    function openPopup_SaveFilter() {
        setIsPopupVisible_SaveFilter(true);
    }

    return (
        <React.Fragment>
            <div className="container-fluid h-100">

                <div className="row h-5">
                    <HistoryGridToolbar
                        dataGridRef={dataGridRef}
                        fleetIds={selectedFleetIds}
                        since={selectedSince}
                        until={selectedUntil}
                        vehicleId={selectedVehicleId}
                        manufacturerId={selectedManufacturerId}
                        componentTypeId={selectedTypeId}
                        year={selectedYearOfConstruction}
                        errorLevel={selectedErrorStatus}
                        ecus={selectedEcus}
                        saveFilterPopup={openPopup_SaveFilter}
                        resetAllFilters={resetAllFilters}
                    />
                </div>

                <div className="row h-95">
                    <Split
                        splitterSize='10px'
                        splitterSize='10px'
                        minPrimarySize='15%'
                        initialPrimarySize='20%'
                        minSecondarySize='45%'
                        defaultSplitterColors={{ color: '#D4D4D4' }}
                    >

                        <div className="col-12 h-100 filter-column">
                            <Filter
                                selectedVehicleId={selectedVehicleId}
                                selectedManufacturerId={selectedManufacturerId}
                                selectedTypeId={selectedTypeId}
                                selectedYearOfConstruction={selectedYearOfConstruction}
                                selectedFleetIds={selectedFleetIds}
                                selectedFleetIdsForSaving={selectedFleetIdsForSaving}
                                selectedSince={selectedSince}
                                selectedUntil={selectedUntil}
                                selectedEcus={selectedEcus}
                                selectedErrorStatus={selectedErrorStatus}

                                fleetSelectionDataSource={fleetSelectionDataSource}
                                vehiclesDataSource={vehiclesDataSource}
                                manufacturersDataSource={manufacturersDataSource}
                                typesDataSource={typesDataSource}
                                yearsOfConstructionDataSource={yearsOfConstructionDataSource}
                                ecusDataSource={ecusDataSource}
                                errorStatusDataSource={errorStatusDataSource}

                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                dateRange={dateRange}

                                setFleetSelectionDataSource={setFleetSelectionDataSource}
                                setSelectedFleetIds={setSelectedFleetIds}
                                setSelectedFleetIdsForSaving={setSelectedFleetIdsForSaving}
                                setFleetNodes={setFleetNodes}
                                setDateRange={setDateRange}
                                setSelectedStartDate={setSelectedStartDate}
                                setSelectedEndDate={setSelectedEndDate}
                                setSelectedSince={setSelectedSince}
                                setSelectedUntil={setSelectedUntil}
                                setSelectedVehicleId={setSelectedVehicleId}
                                setSelectedManufacturerId={setSelectedManufacturerId}
                                setSelectedManufacturerLabel={setSelectedManufacturerLabel}
                                setSelectedTypeId={setSelectedTypeId}
                                setSelectedTypeLabel={setSelectedTypeLabel}
                                setSelectedYearOfConstruction={setSelectedYearOfConstruction}
                                setSelectedEcus={setSelectedEcus}
                                setSelectedErrorStatus={setSelectedErrorStatus}

                                resetFleetSelection={resetFleetSelection}
                                setResetFleetSelection={setResetFleetSelection}

                                savedFiltersDataSource={savedFiltersDataSource}
                                getAllSavedFilters={getAllSavedFilters}

                                currentSavedFilterId={currentSavedFilterId}
                                setCurrentSavedFilterId={setCurrentSavedFilterId}
                                setCurrentSavedFilterName={setCurrentSavedFilterName}

                                resetAllFilters={resetAllFilters}
                            />
                        </div>

                        <div className="col-12 h-100 pl-0">
                            <HistoryGrid
                                dataGridRef={dataGridRef}
                                gridDataSource={gridDataSource}
                                since={selectedSince}
                                until={selectedUntil}
                                ecus={selectedEcus}
                                errorLevel={selectedErrorStatus}
                            />
                        </div>

                    </Split>
                </div>
            </div>

            <SaveFilter
                isPopupVisible={isPopupVisible_SaveFilter}
                setPopupVisibility={setIsPopupVisible_SaveFilter}
                onFilterSaved={getAllSavedFilters}
                since={selectedSince}
                until={selectedUntil}
                ecus={selectedEcus}
                type={selectedTypeLabel}
                manufacturer={selectedManufacturerLabel}
                year={selectedYearOfConstruction}
                fleetIds={selectedFleetIdsForSaving}
                severity={selectedErrorStatus}
            />

        </React.Fragment>
    );
}

export default History;