const unitUtils = {

    getUnit: function () {
        var unit = localStorage.getItem('unit');
        return unit;
    },

    setUnit: function (unit) {
        localStorage.setItem('unit', unit);
    },

    getSpeedUnit: function () {
        switch (unitUtils.getUnit()) {
            case "customary":
                return "mph";
            default:
                return "Km/h";
        }
    },
    getDistanceUnit: function () {
        switch (unitUtils.getUnit()) {
            case "customary":
                return "mi";
            default:
                return "Km";
        }
    },


    convertUnits: function (number, unit, decimals = null) {
        switch (unit) {
            case "customary":
                let miles = number * 0.62137119;
                if (decimals == null) {
                    return miles;
                }
                else {
                    return +(Math.round(miles + "e+" + decimals) + "e-" + decimals);
                };
                break;
            default:
                return number;
        }
    },
}

export default unitUtils;