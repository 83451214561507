/// <reference path="../language/resources/feedback.js" />
import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/reports";

const ReportsService = {

    getEmailReports: function () {
        return axiosInstance.get(
            `${URL_API}/email`);
    },

    getEmailReport: function (reportId) {
        return axiosInstance.get(
            `${URL_API}/email/${reportId}`);
    },

    getListOfDtcs: function () {
        return axiosInstance.get(
            `${URL_API}/email/fault/dtc`);
    },

    getListOfMeasurements: function () {
        return axiosInstance.get(
            `${URL_API}/email/fault/measurement`);
    },

    getListOfStrings: function () {
        return axiosInstance.get(
            `${URL_API}/email/fault/string`);
    },

    addEmailReport: function (reportName, userIds, faults) {
        return axiosInstance.post(
            `${URL_API}/email`,
            {
                reportName: reportName,
                faults: faults,
                userIds: userIds
            }
        );
    },

    updateEmailReport: function (reportId, reportName, userIds, faults) {
        return axiosInstance.put(
            `${URL_API}/email/${reportId}`,
            {
                reportName: reportName,
                faults: faults,
                userIds: userIds
            }
        );
    },

    deleteEmailReport: function (reportId) {
        return axiosInstance.delete(
            `${URL_API}/email/${reportId}`
        );
    }

};

export default ReportsService;