import React, { useEffect } from 'react';
import List from 'devextreme-react/list';
import { formatMessage } from 'devextreme/localization';
import '../../utils/customStylingForDx/dxList.css';

function Filter({
    listDataSource,

    setSelectedManufacturerIds,
    setSelectedContractorIds
}) {

    function renderListItem(data) {
        return (
            <div>{data.label}</div>
        );
    }

    function onOptionChanged(args) {
        if (args.name === 'selectedItemKeys') {
            let selectedItems = args.value;

            let selectedManufacturerIds = [];
            let selectedContractorIds = [];

            if (selectedItems.length !== 0) {
                for (let i = 0; i < selectedItems.length; i++) {
                    let keyType = selectedItems[i].key;

                    switch (keyType) {
                        case formatMessage("Manufacturers"):
                            selectedManufacturerIds.push(selectedItems[i].id);
                            break;

                        case formatMessage("Contractors"):
                            selectedContractorIds.push(selectedItems[i].id);
                            break;
                    }
                }
            }

            let manufacturers = selectedManufacturerIds.toString();
            let contractors = selectedContractorIds.toString();

            setSelectedManufacturerIds(manufacturers);
            setSelectedContractorIds(contractors);
        }
    }

    return (
        <React.Fragment>
            <div className="custom-dxList">
                <List
                    dataSource={listDataSource}
                    grouped={true}
                    collapsibleGroups={true}
                    itemRender={renderListItem}
                    selectionMode={"multiple"}
                    onOptionChanged={onOptionChanged}
                />
            </div>
        </React.Fragment>
    );
}

export default Filter;