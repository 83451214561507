import React, { useContext } from 'react';
import TextBox from 'devextreme-react/text-box';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';

function VehiclesGridToolbar({
    vehiclesGridRef
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    function onValueChanged_Search(data) {
        vehiclesGridRef.current.instance.searchByText(data.value);
    }

    return (
        <React.Fragment>
            <div className="col-sm-12">
                <div className="search-container">
                    <TextBox
                        placeholder={formatMessage("Search")}
                        width={'350px'}
                        showClearButton={true}
                        onValueChanged={onValueChanged_Search}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default VehiclesGridToolbar;