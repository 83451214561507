import React, { useContext, useEffect, useState } from 'react';
import DataGrid, { Column, Scrolling, LoadPanel, Sorting } from 'devextreme-react/data-grid';
import { formatMessage, formatNumber } from 'devextreme/localization';
import feedbackMessages from '../../language/resources/feedback';
import languageUtils from '../../utils/configs/languageUtils';
import DashboardService from '../../services/dashboard.service';
import TokenContext from '../../utils/contexts/token-context';
import LanguageContext from '../../utils/contexts/language-context';
import globalMessages from '../../language/resources/global';

function FleetOverview() {
    const tokenObj = useContext(TokenContext);
    const languageObj = useContext(LanguageContext);

    const [dataSource, setDataSource] = useState([]);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    useEffect(() => {
        DashboardService.getFleetOverview()
            .then(response => {
                let responseData = response.data;

                for (let i = 0; i < responseData.data.length; i++) {
                    if (responseData.data[i].fleetName.toUpperCase() === "Unassigned".toUpperCase()) {
                        responseData.data[i].fleetName = formatMessage("Unassigned");
                    }
                }

                setDataSource([responseData.totals, ...responseData.data]);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    const errorMessage = languageUtils.stringFormat(formatMessage("Failure_DataRetrieveForWidget"), formatMessage("FleetOverview"));
                    console.error(errorMessage);
                }
            });
    }, [tokenObj.token]);

    function onCellPrepared(e) {
        if (e.rowIndex === 0) {
            e.cellElement.className = 'fleet-overview-total';
        }
    }

    function percentageCell(data) {
        let value = data.value;
        value = value * 100;

        if (value === 0) {
            return (
                <span>0 %</span>
            );
        }

        let percentage = formatNumber(value, "###.##");
        return (
            <span>{percentage} %</span>
        );
    }

    return (
        <div className="h-100 w-100">
            <div className="h-10">
                <h2>{formatMessage("Contractors")}</h2>
            </div>

            <div className="h-90">
                <DataGrid
                    dataSource={dataSource}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    showColumnLines={false}
                    hoverStateEnabled={false}
                    rowAlternationEnabled={false}
                    showRowLines={true}
                    width={'100%'}
                    height={'100%'}
                    onCellPrepared={onCellPrepared}
                >
                    <Scrolling
                        mode="infinite"
                        useNative={true}
                    />
                    <LoadPanel enabled={true} />
                    <Sorting mode="none" />
                    <Column
                        dataField="fleetName"
                        dataType="string"
                        caption={""}
                    />
                    <Column
                        dataField="registered"
                        dataType="string"
                        caption={formatMessage("Registered")}
                    />
                    <Column
                        dataField="seenToday"
                        dataType="string"
                        caption={formatMessage("SeenToday")}
                    />
                    <Column
                        dataField="liveNow"
                        dataType="string"
                        caption={formatMessage("LiveNow")}
                    />
                    <Column
                        dataField="activityRate"
                        dataType="string"
                        caption={formatMessage("FleetActivePercentage")}
                        cellRender={percentageCell}
                    />
                </DataGrid>
            </div>

        </div>
    );
}

export default FleetOverview;