import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/history";

const HistoryService = {

    getFleets: function () {
        return axiosInstance.get(
            `${URL_API}/fleets`
        );
    },

    getVehicleIdentifiers: function (fleetIds, manufacturerId, componentType, year) {
        return axiosInstance.get(
            `${URL_API}/vehicleidentifiers`,
            {
                params: {
                    fleetIds: fleetIds,
                    manufacturerId: manufacturerId,
                    componentTypeId: componentType,
                    year: year
                }
            }
        );
    },

    getVehicleManufacturers: function (fleetIds, vehicleId, componentType, year) {
        return axiosInstance.get(
            `${URL_API}/vehiclemanufacturers`,
            {
                params: {
                    fleetIds: fleetIds,
                    vehicleId: vehicleId,
                    componentTypeId: componentType,
                    year: year
                }
            }
        );
    },

    getVehicleTypes: function (fleetIds, vehicleId, manufacturerId, year) {
        return axiosInstance.get(
            `${URL_API}/vehicletypes`,
            {
                params: {
                    fleetIds: fleetIds,
                    vehicleId: vehicleId,
                    manufacturerId: manufacturerId,
                    year: year
                }
            }
        );
    },

    getVehicleYears: function (fleetIds, vehicleId, manufacturerId, componentType) {
        return axiosInstance.get(
            `${URL_API}/vehicleyears`,
            {
                params: {
                    fleetIds: fleetIds,
                    vehicleId: vehicleId,
                    manufacturerId: manufacturerId,
                    componentTypeId: componentType
                }
            }
        );
    },

    getVehicleEcus: function (fleetIds, vehicleId, manufacturerId, componentType, year) {
        return axiosInstance.get(
            `${URL_API}/vehicleecus`,
            {
                params: {
                    fleetIds: fleetIds,
                    vehicleId: vehicleId,
                    manufacturerId: manufacturerId,
                    componentTypeId: componentType,
                    year: year
                }
            }
        );
    },

    getVehiclesInfo: function (filter, fleetIds, since, until, vehicleId, manufacturerId, componentTypeId, year, errorLevel, ecus) {
        return axiosInstance.get(
            `${URL_API}/vehicles`,
            {
                params: {
                    filter: filter,
                    fleetIds: fleetIds,
                    since: since,
                    until: until,
                    vehicleId: vehicleId,
                    manufacturerId: manufacturerId,
                    componentTypeId: componentTypeId,
                    year: year,
                    errorLevel: errorLevel,
                    ecus: ecus
                }
            }
        );
    },

    getVehicleErrors: function (filter, vehicleId, since, until, ecus, errorLevel) {
        return axiosInstance.get(
            `${URL_API}/vehicleerrors`,
            {
                params: {
                    filter: filter,
                    vehicleId: vehicleId,
                    since: since,
                    until: until,
                    ecus: ecus,
                    errorLevel: errorLevel
                }
            }
        );
    },

    getErrorLevels: function () {
        return axiosInstance.get(
            `${URL_API}/errorlevels`
        );
    },

    deleteFilter: function (filterId) {
        return axiosInstance.delete(
            `${URL_API}/filterhistory/${filterId}`
        );
    },

    getAllFilters: function () {
        return axiosInstance.get(
            `${URL_API}/filterHistory`
        );
    },

    getFilter: function (filterId) {
        return axiosInstance.get(
            `${URL_API}/filterHistory/${filterId}`
        );
    },

    saveFilter: function (filterName, since, until, ecus, type, manufacturer, year, fleetIds, severity) {
        return axiosInstance.post(
            `${URL_API}/filterhistory`,
            {
                filterName: filterName,
                startTime: since,
                endTime: until,
                ecu: ecus,
                type: type,
                manufacturer: manufacturer,
                year: year,
                fleetIds: fleetIds,
                severity: severity
            }
        );
    },

    getAllSchedules: function () {
        return axiosInstance.get(
            `${URL_API}/filterschedule`
        );
    },

    getSchedule: function (scheduleId) {
        return axiosInstance.get(
            `${URL_API}/filterschedule/${scheduleId}`
        );
    },

    deleteSchedule: function (scheduleId) {
        return axiosInstance.delete(
            `${URL_API}/filterschedule/${scheduleId}`
        );
    },

    saveSchedule: function (filterId, email, since, frequency) {
        return axiosInstance.post(
            `${URL_API}/filterschedule`,
            {
                filterId: filterId,
                parameters: email,
                startTime: since,
                periodicity: frequency
            }
        );
    },

    export: function (format, locale, fleetIds, since, until, vehicleId, manufacturerId, componentTypeId, year, errorLevel, ecus) {
        return axiosInstance.get(
            `${URL_API}/export`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'arraybuffer',
                params: {
                    format: format,
                    locale: locale,
                    fleetIds: fleetIds,
                    since: since,
                    until: until,
                    vehicleId: vehicleId,
                    manufacturerId: manufacturerId,
                    componentTypeId: componentTypeId,
                    year: year,
                    errorLevel: errorLevel,
                    ecus: ecus
                }
            }
        );
    }

}

export default HistoryService;