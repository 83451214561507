import React, { useState } from 'react';
import './panel.css';

function Panel({ title, content }) {
    const [isOpen, setIsOpen] = useState(true);
    const [panelStateIcon, setPanelStateIcon] = useState("icon icon-direction-down");
    const [panelContentDisplay, setPanelContentDisplay] = useState("panel-content show");

    function setStateOfPanel(state) {
        setIsOpen(state);

        if (state) {
            setPanelStateIcon("icon icon-direction-down");
            setPanelContentDisplay("panel-content show");
        } else {
            setPanelStateIcon("icon icon-direction-up");
            setPanelContentDisplay("panel-content hide");
        }
    }

    return (
        <React.Fragment>
            <div className="panel-title" onClick={() => setStateOfPanel(!isOpen)}>
                <span className={"mr-1 " + panelStateIcon} /><h2>{title}</h2>
            </div>

            <div className={panelContentDisplay}>
                {content}
            </div>
        </React.Fragment>
    );
}

export default Panel;