import React, { useContext, useState } from 'react';
import NavItem from './NavItem';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import NavItemDropdown from './NavItemDropdown';
import NavSettingsItem from './NavSettingsItem';
import LanguageContext from '../../utils/contexts/language-context';
import TokenContext from '../../utils/contexts/token-context';
import AuthenticationService from '../../services/authentication.service';
import Reports from '../Reports/Reports';

function Navbar({ pageTitle }) {
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const [isPopupVisible_Reports, setIsPopupVisible_Reports] = useState(false);

    function logout() {
        AuthenticationService.logout(tokenObj.token)
            .then(() => {
                tokenObj.setToken(null);
            })
            .catch(() => {
                tokenObj.setToken(null);
            });
    }

    function openPopup_Reports() {
        setIsPopupVisible_Reports(true);
    }

    function changeLocale(newLocale) {
        if (newLocale !== languageObj.language) {
            var result = window.confirm(formatMessage("Warning_CultureChange"));

            if (result) {
                languageObj.setLanguage(newLocale);
            }
        }
    }

    const tabs = [
        <NavItem key="tab_Dashboard" id="tab_Dashboard" icon="icon-dashboard" tabTitle={formatMessage("Dashboard")} href="/dashboard"></NavItem>,
        <NavItem key="tab_ControlBoard" id="tab_ControlBoard" icon="icon-control-center" tabTitle={formatMessage("ControlBoard")} href="/controlboard"></NavItem>,
        <NavItem key="tab_History" id="tab_History" icon="icon-media-replay" tabTitle={formatMessage("History")} href="/history"></NavItem>,
        <NavItem key="tab_RealTimeCockpit" id="tab_RealTimeCockpit" icon="icon-speeding" tabTitle={formatMessage("RealTimeCockpit")} href="/realtimecockpit"></NavItem>,
        <NavItem key="tab_Fleet" id="tab_Fleet" icon="icon-database" tabTitle={formatMessage("Fleet")} href="/fleet"></NavItem>
    ];

    const settings = [
        <NavSettingsItem key="settings_help" id="settings_help" icon="icon-help" isDisabled={true}></NavSettingsItem>,
        <NavSettingsItem key="settings_user" id="settings_user" icon="icon-user" isDisabled={true}></NavSettingsItem>,
        <NavSettingsItem key="settings_langEN" id="settings_langEN" tabTitle={formatMessage("Lang_EN")} onClick={() => changeLocale("en-GB")} customClass={(languageObj.language == "en-GB" ? "active" : "")}></NavSettingsItem>,
        <NavSettingsItem key="settings_langDE" id="settings_langDE" tabTitle={formatMessage("Lang_DE")} onClick={() => changeLocale("de-DE")} customClass={(languageObj.language == "de-DE" ? "active" : "")}></NavSettingsItem>,
        <NavSettingsItem key="settings_reports" id="settings_reports" icon="icon-vehicle-supervisor" onClick={openPopup_Reports}></NavSettingsItem>,
        <NavSettingsItem key="settings_logout" id="settings_logout" icon="icon-logout" onClick={logout}></NavSettingsItem>
    ];

    return (
        <React.Fragment>

            <nav className="navbar navbar-expand-lg navbar-light">

                <ul className="navbar-nav d-none d-lg-flex">
                    {tabs}
                </ul>

                <div className="navbar-pageTitle d-lg-none">
                    <h3>{formatMessage("App_Name")}</h3>
                    <h1>{pageTitle}</h1>
                </div>

                <ul className="navbar-nav">
                    {settings}
                </ul>
            </nav>

            <Reports
                isPopupVisible={isPopupVisible_Reports}
                setPopupVisibility={setIsPopupVisible_Reports}
            />

        </React.Fragment>
    )
}

export default Navbar;