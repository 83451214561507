import React, { useContext, useEffect, useState } from 'react';
import DataGrid, { Column, Scrolling, LoadPanel, Paging, Sorting } from 'devextreme-react/data-grid';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import HistoryService from '../../services/history.service';
import CustomStore from 'devextreme/data/custom_store';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import _ from 'lodash-uuid';
import moment from 'moment';
import "../ControlBoard/controlBoard.css";
import TokenContext from '../../utils/contexts/token-context';

export function wrapHistoryGridDetail(Component, since, until, ecus, errorLevel) {
    return (props) => {
        return (
            <Component
                since={since}
                until={until}
                ecus={ecus}
                errorLevel={errorLevel}
                {...props} />
        );
    }
}

function HistoryGridDetail({ data, since, until, ecus, errorLevel }) {
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const [detailGridDataSource, setDetailGridDataSource] = useState([]);

    let componentId = data.data.componentId;

    useEffect(() => {
        getVehicleErrors(componentId, since, until, ecus, errorLevel);
    }, [data, tokenObj.token]);

    function getVehicleErrors(componentId, since, until, ecus, errorLevel) {
        let detailDataSource = new CustomStore({
            key: 'customKey',
            load: function (loadOptions) {
                let params = {};
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && devextremeUtils.isNotEmpty(loadOptions[i])) {
                        params[i] = loadOptions[i];
                    }
                });

                let encodedParams = btoa(JSON.stringify(params));

                return HistoryService.getVehicleErrors(encodedParams, componentId, since, until, ecus, errorLevel)
                    .then(response => {
                        let responseData = response.data;
                        let vehiclesList = responseData.data;

                        for (let i = 0; i < vehiclesList.length; i++) {
                            vehiclesList[i].customKey = _.uuid();
                        }

                        return {
                            data: vehiclesList,
                            totalCount: responseData.totalCount
                        }
                    })
                    .catch(error => {
                        if (error.response !== undefined && error.response.status !== 401) {
                            console.error("Could not retrieve data for History Grid Details.");
                        }
                    });
            }
        });

        setDetailGridDataSource(detailDataSource);
    }

    function cellRender_ErrorLevel(cellData) {
        let statusValue = cellData.value;

        let cellContent = <span></span>;

        if (statusValue) {
            switch (statusValue) {
                case 0: // OK
                    cellContent = <span className="icon icon-ok error-status-icon ok"></span>
                    break;

                case 1: // WARNING
                    cellContent = <span className="icon icon-warning error-status-icon warning"></span>
                    break;

                case 2: // ERROR
                    cellContent = <span className="icon icon-error error-status-icon error"></span>
                    break;

                case 3: // CRITICAL
                    cellContent = <span className="icon icon-warning error-status-icon critical"></span>
                    break;
            }
        }

        return (
            <div>
                {cellContent}
            </div>
        );
    }

    function cellRender_StartTime(cellData) {
        return (
            <div className="pl-3">{cellData.text}</div>
        );
    }

    function headerCellRender_StartTime(e) {
        return(
            <div className="pl-3">{e.column.caption}</div>
        );
    }

    function padLeadingZeros(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    function cellRender_Duration(cellData) {
        if (cellData.value === null) {
            return (
                <div className="pl-3"></div>
            );
        }
        let duration = moment.duration(cellData.value);

        const hours = Math.floor(duration.asHours());
        const hoursFormatted = `${hours}:`;

        const minutes = duration.minutes();
        const minutesFormatted = `${padLeadingZeros(minutes,2)}:`;

        const seconds = duration.seconds();
        const secondsFormatted = `${padLeadingZeros(seconds,2)}`;

        let result = "";

        if (hours > 0) {
            result += hoursFormatted;
        }

        result += minutesFormatted;

        result += secondsFormatted;
        return (
            <div className="pl-3">{result}</div>
        );
    }

    function cellValue_Duration(rowData) {
        if (rowData.resolveTime === null) {
            return Number.MAX_SAFE_INTEGER;
        }

        let startTime = moment(rowData.startTime);
        let resolveTime = moment(rowData.resolveTime);

        let result = moment(resolveTime, "DD/MM/YYYY HH:mm").diff(moment(startTime, "DD/MM/YYYY HH:mm"));
        return result;
    }

    function headerCellRender_Duration(e) {
        return (
            <div className="pl-3">{e.column.caption}</div>
        );
    }

    return (
        <React.Fragment>
            <DataGrid
                dataSource={detailGridDataSource}
                remoteOperations={true}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={false}
                width={'100%'}
                wordWrapEnabled={true}
            >
                <Paging defaultPageSize={5} />
                <Sorting mode="single" />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                />
                <LoadPanel enabled={true} />
                <Column
                    dataField="startTime"
                    dataType="datetime"
                    caption={formatMessage("StartTime")}
                    width={'18%'}
                    cellRender={cellRender_StartTime}
                    headerCellRender={headerCellRender_StartTime}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="duration"
                    dataType="number"
                    caption={formatMessage("Duration")}
                    width={'10%'}
                    cellRender={cellRender_Duration}
                    headerCellRender={headerCellRender_Duration}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="componentName"
                    dataType="string"
                    caption={formatMessage("Component")}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="errorCode"
                    dataType="string"
                    caption={formatMessage("ErrorCode")}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="errorLevel"
                    dataType="string"
                    caption={formatMessage("Status")}
                    cellRender={cellRender_ErrorLevel}
                    width={'10%'}
                    cssClass="child-table-cell"
                />
                <Column
                    dataField="message"
                    dataType="string"
                    caption={formatMessage("Message")}
                    width={'35%'}
                    cssClass="child-table-cell"
                />
            </DataGrid>
        </React.Fragment>
    );

}

export default HistoryGridDetail;