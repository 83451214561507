import React, { useContext, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import Navbar from '../Navbar/Navbar';
import Toolbar from '../Toolbar/Toolbar';
import PageHeader from './PageHeader';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import './layout.css';
import LanguageContext from '../../utils/contexts/language-context';

function Layout({ routes }) {
    const languageObj = useContext(LanguageContext);

    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const [pageTitle, setPageTitle] = useState(formatMessage("App_Name"));
    const pageTitleStateObj = { pageTitle, setPageTitle };

    return (
        <PageTitleContext.Provider value={pageTitleStateObj}>
            <div className="body-container">
                <div className="layout-screen">

                    {/* Navigation Bar */}
                    <div className="navigation-bar">
                        <Navbar pageTitle={pageTitle}></Navbar>
                    </div>

                    {/* Breadcrumbs */}
                    <div className="breadcrum-wrapper vhm-page-header">
                        <PageHeader />
                    </div>

                    {/* Content Placeholder */}
                    <div className="content vhm-content">
                        {routes}
                    </div>

                    {/* Toolbar on Tablet & Mobile (bottom) */}
                    <Toolbar />

                </div>
            </div>
        </PageTitleContext.Provider>
    );

}

export default Layout;