const tokenUtils = {

    getUserToken: function () {
        return localStorage.getItem('token');
    },

    storeUserToken: function (token) {
        if (token === null) {
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    }

}

export default tokenUtils;