import { formatMessage } from 'devextreme/localization';
import React, { useContext, useState } from 'react';
import feedbackMessages from '../../language/resources/feedback';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import LanguageContext from '../../utils/contexts/language-context';
import { CircularGauge, Scale, Label, RangeContainer, Range, ValueIndicator, Animation } from 'devextreme-react/circular-gauge';
import { Tick } from 'devextreme-react/chart';
import CockpitLamp from '../../utils/elements/CockpitLamp/CockpitLamp';

function SpeedBattery({
    isParkingBrakeOn,
    isBrakeSwitchOn,
    isIdlingOn,
    isIgnitionOn,
    speedValue,
    speedUnits,
    socValue
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    function customizeSpeedScale({ valueText }) {
        switch (valueText) {
            case "0":
                return "";

            default:
                return valueText;
        }
    }

    function customizeBatteryScale({ valueText }) {
        return valueText;
    }

    return (
        <React.Fragment>
            <div className="container-fluid speed-battery-content">
                <div className="row">

                    <div className="col-sm-5">
                        <div className="gauge-value">
                            <div className="content">
                                <p className="amount">{speedValue}</p>
                                <p className="units">{speedUnits}</p>
                            </div>
                        </div>
                        <div id="gauget">
                            <CircularGauge
                                id="gauge"
                                value={speedValue}
                            >
                                <ValueIndicator
                                    type="rectangleNeedle"
                                    color="#63A6DF"
                                    offset={-10}
                                />
                                <RangeContainer offset={-5}>
                                    <Range startValue={0} endValue={speedValue} color="#63A6DF" />
                                    <Range startValue={speedValue} endValue={120} color="#9C9C9C" />
                                </RangeContainer>
                                <Scale
                                    startValue={0}
                                    endValue={120}
                                    tickInterval={10}>
                                    <Tick color="#9C9C9C" />
                                    <Label customizeText={customizeSpeedScale} />
                                </Scale>
                                <Animation
                                    enabled={false}
                                />
                            </CircularGauge>
                        </div>
                    </div>

                    <div className="col-sm-2">
                        <div className="h-100 text-center cockpit-lights-panel">

                            <CockpitLamp
                                icon="icon-braking"
                                isActive={isParkingBrakeOn}
                                lampName={formatMessage("ParkingBrake")}
                            />

                            <span className="mt-1" />

                            <CockpitLamp
                                icon="icon-braking"
                                isActive={isBrakeSwitchOn}
                                lampName={formatMessage("BrakeSwitch")}
                            />

                            <span className="mt-1" />

                            <CockpitLamp
                                icon="icon-idling"
                                isActive={isIdlingOn}
                                lampName={formatMessage("Idling")}
                            />

                            <span className="mt-1" />

                            <CockpitLamp
                                icon="icon-ignition-on"
                                isActive={isIgnitionOn}
                                lampName={formatMessage("Ignition")}
                            />

                        </div>
                    </div>

                    <div className="col-sm-5">
                        <div className="gauge-value">
                            <div className="content">
                                <p className="amount">{socValue}%</p>
                                <p className="units">{formatMessage("SoC")}</p>
                            </div>
                        </div>
                        <div id="gauget">
                            <CircularGauge
                                id="gauge"
                                value={socValue}
                            >
                                <ValueIndicator
                                    type="rectangleNeedle"
                                    color="#63A6DF"
                                    offset={-10}
                                />
                                <RangeContainer offset={-5}>
                                    <Range startValue={0} endValue={socValue} color="#63A6DF" />
                                    <Range startValue={socValue} endValue={100} color="#9C9C9C" />
                                </RangeContainer>
                                <Scale
                                    startValue={0}
                                    endValue={100}
                                    tickInterval={5}
                                >
                                    <Tick color="#9C9C9C" />
                                    <Label customizeText={customizeBatteryScale} />
                                </Scale>
                                <Animation
                                    enabled={false}
                                />
                            </CircularGauge>
                        </div>

                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default SpeedBattery;