const telltaleIso = {
    "1": "0027",
    "2": "0082",
    "3": "0083",
    "4": "0084",
    "5": "0085",
    "6": "0100",
    "7": "0238",
    "8": "0239",
    "9": "0242",
    "10": "0245",
    "11": "0246",
    "12": "0247",
    "13": "0248",
    "14": "0456",
    "15": "0633",
    "16": "0634",
    "17": "0637a",
    "18": "0640",
    "19": "0717",
    "20": "1168b",
    "21": "1396b",
    "22": "1407",
    "23": "1408",
    "24": "1422",
    "25": "1434a",
    "26": "1603b",
    "27": "2426",
    "28": "2427",
    "29": "2429",
    "30": "2440",
    "31": "2441",
    "32": "2461",
    "33": "2574",
    "34": "2596a",
    "35": "2630",
    "36": "",
    "37": "",
    "38": "",
    "39": "",
    "40": "",
    "41": "2946",
    "42": "",
    "43": "",
    "44": "",
    "45": "",
    "46": "",
    //"47": "2432",
    "47": "",
    "48": "2452",
    "49": "0249",
    "50": "",
    "51": "2682",
    "52": "",
    "53": "2581",
    "54": "",
    "55": "2444",
    "56": "2108",
    "57": "",
    "58": "",
    "59": "2649",
    "60": "",
    "61": "2433",
    "62": "2633",
    "63": "",
    "64": "",
    //"65": "2632",
    "65": "",
    //"66": "6042",
    "66": "",
    //"67": "3129",
    "67": "",
    //"68": "2639",
    "68": "",
    /*"69": "2455",*/
    "69": "",
    "70": "",
    "71": "",
    "72": "",
    "73": "",
    "74": "",
    "75": ""
};

export default telltaleIso;