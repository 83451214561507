import { formatMessage } from 'devextreme/localization';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import SelectBox from 'devextreme-react/select-box';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import './realTimeCockpit.css';
import Lamps from './Lamps';
import Performance from './Performance';
import Panel from '../../utils/elements/Panel/Panel';
import SpeedBattery from './SpeedBattery';
import TokenContext from '../../utils/contexts/token-context';
import RealTimeCockpitService from '../../services/realTimeCockpit.service';
import { HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';
import languageUtils from '../../utils/configs/languageUtils';
import unitUtils from '../../utils/configs/unitUtils';
import telltaleNames from '../../language/resources/telltales';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import LanguageContext from '../../utils/contexts/language-context';
import UnitContext from '../../utils/contexts/unit-context';
import telltaleIso from '../../utils/dictionaries/telltaleIso';
import RouteMap from './RouteMap';

function RealTimeCockpit() {
    const tokenObj = useContext(TokenContext);
    const pageTitleObj = useContext(PageTitleContext);
    const languageObj = useContext(LanguageContext);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages, telltaleNames], languageObj.language)

    const unitObj = useContext(UnitContext);

    const [hubConnection, setHubConnection] = useState(null);
    const [isConnectedToBroker, setIsConnectedToBroker] = useState(false);
    const [connectionHubStarted, setConnectionHubStarted] = useState(false);

    const [onlineVehiclesDataSource, setOnlineVehiclesDataSource] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);

    const [isStartRTC, setIsStartRTC] = useState(false);
    const [isStopRTC, setIsStopRTC] = useState(false);

    // For enabling/disabling Start/Stop buttons:
    const [startRtcEnabled, setStartRtcEnabled] = useState(false);
    const [stopRtcEnabled, setStopRtcEnabled] = useState(false);

    // General stream data
    const [streamingSince, setStreamingSince] = useState(null);
    const [dataUsage, setDataUsage] = useState(0);

    // Cockpit
    const [isParkingBrakeOn, setIsParkingBrakeOn] = useState(0);
    const [isBrakeSwitchOn, setIsBrakeSwitchOn] = useState(0);
    const [isIdlingOn, setIsIdlingOn] = useState(0);
    const [isIgnitionOn, setIsIgnitionOn] = useState(0);
    const [speedValue, setSpeedValue] = useState(0);
    const [speedUnits, setSpeedUnits] = useState(unitUtils.getSpeedUnit());


    const [socValue, setSoCValue] = useState(0);

    // Lamps
    const [dataSource_Lamps, setDataSource_Lamps] = useState([]);

    // Map
    const [dataSource_RouteMap, setDataSource_RouteMap] = useState([]);

    // Performance values
    const [dataSource_Performance, setDataSource_Performance] = useState([]);

    const hubConnectionReference = useRef(null);
    const selectedVehicleReference = useRef(null);

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("RealTimeCockpit"));
    }, [languageObj.language]);

    useEffect(() => {
        let connection = new HubConnectionBuilder()
            .withUrl("/realtimecockpithub")
            .build();
        setHubConnection(connection);
        hubConnectionReference.current = connection;

        connectToBroker(); // Warns broker that the user accessed the page
    }, [tokenObj.token]);

    useEffect(() => {
        if (isConnectedToBroker) {
            getOnlineVehicles();
        }
    }, [tokenObj.token, isConnectedToBroker]);

    useEffect(() => {
        if (isConnectedToBroker) {
            hubConnection
                .start()
                .then(() => {
                    console.log("Hub connection started. Connection Id = " + hubConnection.connectionId);
                    setConnectionHubStarted(true);
                })
                .catch(error => {
                    console.error("Error while establishing hub connection.");
                });
        }
    }, [isConnectedToBroker]);

    useEffect(() => {
        if (connectionHubStarted) {
            if (isStartRTC) {
                startStream(hubConnection.connectionId, selectedVehicleId);
                console.log("Stream START. \nConnection Id = " + hubConnection.connectionId + "; Vehicle Id = " + selectedVehicleId);
            }

            if (isStopRTC) {
                stopStream(hubConnection.connectionId, selectedVehicleId);
                console.log("Stream STOP. \nConnection Id = " + hubConnection.connectionId + "; Vehicle Id = " + selectedVehicleId);
            }
        }
    }, [tokenObj.token, connectionHubStarted, isStartRTC, isStopRTC]);

    useEffect(() => {
        return () => {
            // On component dismount (i.e. change of tab)
            stopStream(hubConnectionReference.current.connectionId, selectedVehicleReference.current);
            console.log("Stream AUTO-STOP. \nConnection Id = " + hubConnectionReference.current.connectionId + "; Vehicle Id = " + selectedVehicleReference.current);
        }
    }, []);


    function connectToBroker() {
        RealTimeCockpitService.connect()
            .then(response => {
                let responseData = response.data;
                setIsConnectedToBroker(responseData);

                console.log("Connected to broker: " + responseData);

                if (!responseData) {
                    devextremeUtils.displayNotification(formatMessage("Failure_ConnectBroker"), 'error');
                }
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not connect to the broker.");
                    devextremeUtils.displayNotification(formatMessage("Failure_ConnectBroker"), 'error');
                }
            });
    }

    function getOnlineVehicles() {
        RealTimeCockpitService.getOnlineVehicles()
            .then(response => {
                let responseData = response.data;
                setOnlineVehiclesDataSource(responseData);

                if (responseData.length === 0) {
                    console.log("No vehicles online.");
                }
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve list of online vehicles.");
                }
            });
    }

    function startStream(connectionId, vehicleId) {
        RealTimeCockpitService.startStream(connectionId, vehicleId)
            .then(response => {
                resetValues();
                console.log("Stream has started.");
                hubConnection.on('ReceiveRealTimeUpdateMessage', message => {
                    let jsonMessage = JSON.parse(message);
                    processMessage(jsonMessage);
                });
            })
            .catch(error => {
                console.error("Could not start the stream.");
            });
    }

    function stopStream(connectionId, vehicleId) {
        RealTimeCockpitService.stopStream(connectionId, vehicleId)
            .then(response => {
                console.info("Stream has stopped.");
            })
            .catch(error => {
                console.error("Could not stop the stream.");
            });
    }

    function resetValues() {
        setDataSource_RouteMap([]);
        setDataSource_Lamps([]);
        setDataSource_Performance([]);
    }

    function processMessage(message) {
        setStreamingSince(prev => {
            if (prev == null) {
                let timestampStreamingSince = moment(message.T).format("hh:mm A");
                return timestampStreamingSince;
            }
            return prev;
        });

        let dataSize = message.DataSize / 125000;
        setDataUsage(languageUtils.localizeNumber(dataSize, languageObj.language));

        // The timestamp can be in milliseconds (most of the time) or in seconds
        let messageTimestamp = message.T;

        // For detecting if the timestamp is in s or ms, we count the number of non-decimal digits as per https://stackoverflow.com/a/23982005
        let timestampLength = messageTimestamp.toString().split('.', 1)[0].length;

        if (timestampLength >= 12) { // timestamp is in milliseconds
            messageTimestamp = messageTimestamp / 1000;
        }

        let timestamp = moment.unix(messageTimestamp).format("HH:mm:ss");

        let latitude = null;
        let longitude = null;

        // Process VALUES:
        if (message.VALUES !== null && message.VALUES.length > 0) {
            let values = message.VALUES;

            for (let i = 0; i < values.length; i++) {
                if (values[i].Name === "GPS_Latitude") {
                    latitude = Number(values[i].Value);
                }
                else if (values[i].Name === "GPS_Longitude") {
                    longitude = Number(values[i].Value);
                }
                else {
                    processValues(values[i], timestamp);
                }
            }
        }

        // Process GPS:       
        if (latitude !== null && longitude !== null) {
            setDataSource_RouteMap(prev => {
                let coordinates = [...prev];
                coordinates.push([longitude, latitude]);
                return coordinates;
            });
        }
        
        // Process LAMPS:
        if (message.LAMPS !== null && message.LAMPS.length > 0) {
            let lamps = message.LAMPS;
            let lampsList = [];
            for (let i = 0; i < lamps.length; i++) {
                let processedLamp = processLamps(lamps[i]);
                lampsList.push(processedLamp);
            }

            // We need to keep the existing lamps unless they are off
            setDataSource_Lamps(prev => {
                let prevLampsList = [...prev];

                // This merges the current lamps into the previous list, updating values if needed
                const result = Object.values([...prevLampsList, ...lampsList].reduce((result, {
                    name,
                    ...rest
                }) => {
                    result[name] = {
                        ...(result[name] || {}),
                        name,
                        ...rest
                    };


                    return result;
                }, {}));

                // The lamps that are off, invalid, etc. are removed
                return result.filter(lamp => (lamp.condition !== 'Invalid' && lamp.condition !== 'Reserved' && lamp.condition !== 'Not Available' && lamp.condition !== 'Off'));
            });
        }

        // Process PERFORMANCE
        if (message.PERFORMANCE !== null && message.PERFORMANCE.length > 0) {
            let performanceValues = message.PERFORMANCE;
            for (let i = 0; i < performanceValues.length; i++) {
                processPerformanceValues(performanceValues[i], timestamp);
            }
        }
    }

    function processLamps(lamp) {
        let name = "",
            condition = "",
            isoLamp = "";

        if (lamp.hasOwnProperty("TelltaleId")) {
            name = formatMessage((lamp.TelltaleId).toString());
            condition = lamp.Condition;
            isoLamp = telltaleIso[(lamp.TelltaleId).toString()];
        }

        return ({
            name: name,
            condition: condition,
            isoLamp: isoLamp
        });
    }

    function processValues(value, timestamp) {
        switch (value.Name) {
            case "Ignition":
                setIsIgnitionOn(value.Value);
                break;

            case "BrakeSwitch":
                setIsBrakeSwitchOn(value.Value);
                break;

            case "Idling":
                setIsIdlingOn(value.Value);
                break;

            case "ParkingBrake":
                setIsParkingBrakeOn(value.Value);
                break;

            case "SoC":
                setSoCValue(parseInt(value.Value));
                break;

            case "Speed":
                setSpeedValue(unitUtils.convertUnits(parseInt(value.Value), unitObj.unit, 1));
                break;

            default: // In case a signal comes as VALUE instead of PERFORMANCE
                processPerformanceValues(value, timestamp);
                break;
        }
    }

    function processPerformanceValues(origValue, timestamp) {
        //Special cases unit conversion
        var value = origValue;

        if (origValue.Name === "Speed") {
            value.Value = unitUtils.convertUnits(origValue.Value, unitObj.unit, 1);
            value.Unit = unitUtils.getSpeedUnit();
        }
        else if (origValue.Name === "TotalVehicleDist") {
            value.Value = unitUtils.convertUnits(origValue.Value, unitObj.unit, 1);
            value.Unit = unitUtils.getDistanceUnit();
        }

        setDataSource_Performance(prev => {
            let performanceValues = [...prev];
            let index = performanceValues.findIndex(x => x.parameterName == value.Name);

            if (index === -1) { // Performance Value is not on the list
                performanceValues.push({
                    parameterName: value.Name,
                    valuesList: [{ value: Number(value.Value), timestamp: timestamp }],
                    parameterUnits: value.Unit
                });
            } else {
                performanceValues[index].valuesList.push({ value: Number(value.Value), timestamp: timestamp });
            }

            return performanceValues;
        });
    }

    function onSelectionChanged_VehicleId(e) {
        if (e.selectedItem === null) {
            setSelectedVehicleId(null);
            selectedVehicleReference.current = null;

            setStartRtcEnabled(false);
            setStopRtcEnabled(false);
        } else {
            setSelectedVehicleId(e.selectedItem);
            selectedVehicleReference.current = e.selectedItem;

            setStartRtcEnabled(true);
            setStopRtcEnabled(false);
        }
    }

    function startRtc() {
        setStartRtcEnabled(false);
        setStopRtcEnabled(true);

        setIsStartRTC(true);
        setIsStopRTC(false);
    }

    function stopRtc() {
        setStartRtcEnabled(true);
        setStopRtcEnabled(false);

        setIsStartRTC(false);
        setIsStopRTC(true);
    }

    return (
        <React.Fragment>
            <div className="container-fluid rtc">

                <div className="row h-5">

                    <div className="col-sm-6 text-left rtc-controls">

                        <SelectBox
                            items={onlineVehiclesDataSource}
                            width={"300px"}
                            searchEnabled={true}
                            onSelectionChanged={onSelectionChanged_VehicleId}
                            onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                            showClearButton={true}
                            placeholder={formatMessage("SearchVehicleNumberOrName") + " ..."}
                        />

                        <span className="mr-2" />

                        <button type="button" className={"btn btn-primary" + (startRtcEnabled ? "" : " disabled")} onClick={startRtc}>
                            {formatMessage("Start")}
                        </button>

                        <span className="mr-2" />

                        <button type="button" className={"btn btn-secondary" + (stopRtcEnabled ? "" : " disabled")} onClick={stopRtc}>
                            {formatMessage("Stop")}
                        </button>

                    </div>

                    <div className="col-sm-6 text-right">

                        {formatMessage("StreamingSince")}: {streamingSince} <span className="mr-3" /> {formatMessage("DataUsage")}: {dataUsage} mbit

                    </div>

                </div>

                <div className="row h-95 rtc-panels-content">

                    <div className="col-sm-8 rtc-background">

                        <div className="space" />

                        <div className="rtc-panel speed-battery">
                            <SpeedBattery
                                isParkingBrakeOn={isParkingBrakeOn}
                                isBrakeSwitchOn={isBrakeSwitchOn}
                                isIdlingOn={isIdlingOn}
                                isIgnitionOn={isIgnitionOn}
                                speedValue={speedValue}
                                speedUnits={speedUnits}
                                socValue={socValue}
                            />
                        </div>

                        <div className="space" />

                        <div className="rtc-panel lamps">
                            <Panel
                                key="panel_lamps"
                                title={formatMessage("Lamps")}
                                content={<Lamps dataSource={dataSource_Lamps} />}
                            />
                        </div>

                        <div className="space" />

                        <div className="rtc-panel performance">
                            <Panel
                                key="panel_performance"
                                title={formatMessage("Performance")}
                                content={<Performance dataSource={dataSource_Performance} />}
                            />
                        </div>

                    </div>

                    <div className="col-sm-4 pl-0 pr-0">
                        <RouteMap dataSource={dataSource_RouteMap} />
                    </div>

                </div>

            </div>
        </React.Fragment>
    );

}

export default RealTimeCockpit;