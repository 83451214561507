import React, { useContext, useEffect, useState } from 'react';
import PageTitleContext from '../../utils/contexts/pageTitle-context';
import LanguageContext from '../../utils/contexts/language-context';
import TokenContext from '../../utils/contexts/token-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import { formatMessage } from 'devextreme/localization';
import './reports.css';
import { Popup } from 'devextreme-react/popup';
import TreeView from 'devextreme-react/tree-view';
import HistoryFilterSchedule from './ReportTypes/HistoryFilterSchedule';
import EmailAlert from './ReportTypes/EmailAlert';
import devextremeUtils from '../../utils/configs/devextremeUtils';
import ReportsService from '../../services/reports.service';
import SelectBox from 'devextreme-react/select-box';

function Reports({
    isPopupVisible,
    setPopupVisibility
}) {
    const pageTitleObj = useContext(PageTitleContext);
    const languageObj = useContext(LanguageContext);
    const tokenObj = useContext(TokenContext);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    const [selectedReportType, setSelectedReportType] = useState(null);

    const [emailReportsDataSource, setEmailReportsDataSource] = useState([]);
    const [selectedEmailReportId, setSelectedEmailReportId] = useState(null);

    const reportTypes = [
        {
            "id": "schedule",
            "name": "History Filter Schedule"
        },
        {
            "id": "email",
            "name": "Email Alerts"
        }
    ];

    useEffect(() => {
        pageTitleObj.setPageTitle(formatMessage("Reports"));
    }, [tokenObj.token]);

    useEffect(() => {
        if (selectedReportType === "email") {
            getEmailReports();
        }
    }, [tokenObj.token, selectedReportType]);

    function onSelectionChanged_ReportSelection(e) {
        let treeView = e.component;

        let nodes = treeView.getSelectedNodes().map((node) => node.itemData.id);
        setSelectedReportType(nodes[0]);
    }

    function onHiding_ReportsPopup() {
        setPopupVisibility(false);
    }

    function getEmailReports() {
        ReportsService.getEmailReports()
            .then(response => {
                let responseData = response.data;
                setEmailReportsDataSource(responseData.data);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve Email Reports.");
                }
            });
    }

    function onSelectionChanged_EmailReportId(e) {
        e.selectedItem === null ? setSelectedEmailReportId(null) : setSelectedEmailReportId(e.selectedItem.reportId);
    }

    return (
        <Popup
            visible={isPopupVisible}
            dragEnabled={false}
            showCloseButton={true}
            showTitle={true}
            title={formatMessage("Reports")}
            width={'90%'}
            height={'80%'}
            onHiding={onHiding_ReportsPopup}
        >
            <div className="popup-content h-100">

                <div className="container-fluid h-100 inner-report">
                    <div className="row h-100">

                        <div className="col-sm-12 col-md-12 col-lg-3 h-100">
                            <h2 style={{ padding: ".5rem" }}>Report Type</h2>
                            <TreeView
                                height={'50%'}
                                searchEnabled={true}
                                searchMode="contains"
                                items={reportTypes}
                                dataStructure="plain"
                                keyExpr="id"
                                displayExpr="name"
                                selectByClick={true}
                                selectionMode="single"
                                onSelectionChanged={onSelectionChanged_ReportSelection}
                            />

                            <br /><br />

                            {
                                selectedReportType === "email" &&
                                <div className="container-fluid">

                                    <h3>Reports</h3>

                                    <SelectBox
                                        items={emailReportsDataSource}
                                        value={selectedEmailReportId}
                                        width={"100%"}
                                        displayExpr="reportName"
                                        valueExpr="reportId"
                                        onSelectionChanged={onSelectionChanged_EmailReportId}
                                        onOpened={devextremeUtils.selectBox_displayNativeScrolling}
                                        showClearButton={true}
                                    />

                                </div>
                            }

                        </div>

                        <div className="col-lg-9 h-100 scroll-v">
                            {
                                selectedReportType === "schedule" &&
                                <HistoryFilterSchedule />
                            }
                            {
                                selectedReportType === "email" &&
                                <EmailAlert
                                    getEmailReports={getEmailReports}
                                    selectedReportId={selectedEmailReportId}
                                    setSelectedReportId={setSelectedEmailReportId}
                                />
                            }
                        </div>

                    </div>
                </div>

            </div>

        </Popup>
    );
}

export default Reports;