import { formatMessage } from 'devextreme/localization';
import React, { useContext } from 'react';
import globalMessages from '../../language/resources/global';
import languageUtils from '../../utils/configs/languageUtils';
import unitUtils from '../../utils/configs/unitUtils';
import LanguageContext from '../../utils/contexts/language-context';
import UnitContext from '../../utils/contexts/unit-context';

function VehicleInfo({ info }) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages], languageObj.language);

    const unitObj = useContext(UnitContext);

    return (
        <div className="container-fluid h-100">
            <div className="row details-row">
                <div className="col-4 title"><b>{info.manufacturer}</b></div>
                <div className="col-4">{formatMessage("TechnicalId")}: <b>{info.technicalID}</b></div>
                <div className="col-4">{formatMessage("OpHours")}: <b>{info.operatingHours}</b></div>
            </div>
            <div className="row details-row">
                <div className="col-4 title"><b>{info.model}</b></div>
                <div className="col-4">{formatMessage("OperationalId")}: <b>{info.operationalID}</b></div>
                <div className="col-4">{formatMessage("TotalDistance")}: <b>{languageUtils.localizeNumber(unitUtils.convertUnits(info.totalDistance, unitObj.unit), languageObj.language)}</b></div>
            </div>
        </div>
    );
}

export default VehicleInfo;