import React, { useContext, useEffect, useState } from 'react';
import DataGrid, { Column, Scrolling, LoadPanel, Sorting, Paging } from 'devextreme-react/data-grid';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { formatMessage } from 'devextreme/localization';
import feedbackMessages from '../../language/resources/feedback';
import languageUtils from '../../utils/configs/languageUtils';
import DashboardService from '../../services/dashboard.service';
import TokenContext from '../../utils/contexts/token-context';
import LanguageContext from '../../utils/contexts/language-context';
import globalMessages from '../../language/resources/global';

function LatestErrors() {
    const tokenObj = useContext(TokenContext);
    const languageObj = useContext(LanguageContext);

    const [dataSource, setDataSource] = useState([]);

    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    useEffect(() => {
        getLatestErrors();
    }, [tokenObj.token]);

    function getLatestErrors() {
        DashboardService.getLatestErrors()
            .then(response => {
                let responseData = response.data;

                let vehicleErrorList = responseData.vehicleErrorList;
                for (let i = 0; i < vehicleErrorList.length; i++) {
                    let formatedDate = moment(vehicleErrorList[i].lastChange);
                    vehicleErrorList[i].lastChange = formatedDate;
                }

                setDataSource(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    const errorMessage = languageUtils.stringFormat(formatMessage("Failure_DataRetrieveForWidget"), formatMessage("LatestErrors"));
                    console.error(errorMessage);
                }
            });
    }

    function cellRender_LastModification(data) {
        let now = moment();
        let lastModification = moment(data.value);

        let calculation = moment(now, "DD/MM/YYYY HH:mm").diff(moment(lastModification, "DD/MM/YYYY HH:mm"));

        let result = humanizeDuration(calculation, { language: languageUtils.getLocale().split("-")[0], largest: 2, round: true, fallbacks: ["en"]  });

        return (
            <span title={result}>{result}</span>
        );
    }

    function cellRender_Critical(cellData) {
        let cellContent = <span />

        let activeCriticalErrors = cellData.row.data.totalCritical;

        if (activeCriticalErrors > 0) {
            cellContent = <div className="number-display critical active">{activeCriticalErrors}</div>;
        } else {
            cellContent = <div />;
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function cellRender_Error(cellData) {
        let cellContent = <span />

        let activeErrorErrors = cellData.row.data.totalError;

        if (activeErrorErrors > 0) {
            cellContent = <div className="number-display error active">{activeErrorErrors}</div>;
        } else {
            cellContent = <div />;
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function cellRender_Warning(cellData) {
        let cellContent = <span />

        let activeWarningErrors = cellData.row.data.totalWarning;

        if (activeWarningErrors > 0) {
            cellContent = <div className="number-display warning active">{activeWarningErrors}</div>;
        } else {
            cellContent = <div />;
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function cellRender_Lamp(cellData) {
        let cellContent = [];

        let amberWarning = cellData.row.data.warning;
        let malFunctionIndicator = cellData.row.data.mil;
        let protectLamp = cellData.row.data.protect;
        let redStop = cellData.row.data.stop;

        if (amberWarning) {
            cellContent.push(<span key="amberWarning" className="p-1"><span className="icon icon-warning latest-errors-icon warning" title={formatMessage("AmberWarning")} /></span>);
        }

        if (malFunctionIndicator) {
            cellContent.push(<span key="malFunctionIndicator" className="p-1"><span className="icon icon-error latest-errors-icon mil" title={formatMessage("MalfunctionIndicator")} /></span>);
        }

        if (protectLamp) {
            cellContent.push(<span key="protectLamp" className="p-1"><span className="icon icon-alarm latest-errors-icon protect" title={formatMessage("ProtectLamp")} /></span>);
        }

        if (redStop) {
            cellContent.push(<span key="redStop" className="p-1"><span className="icon icon-warning latest-errors-icon stop" title={formatMessage("RedStop")} /></span>);
        }

        return (
            <div>
                {cellContent}
            </div>
        );
    }

    return (
        <div className="h-100 w-100">
            <div className="h-30">

                <div className="row latest-errors-error-list">
                    <div className="col-sm-4 pl-0">
                        <div className="error-container critical-errors">
                            <span className="icon icon-error"></span> {formatMessage("CriticalErrors")}
                            <br />
                            <br />
                            <span className="error-number">{dataSource.errorCount?.critical}</span>
                        </div>
                    </div>
                    <div className="col-sm-4 pr-0 pl-0">
                        <div className="error-container errors">
                            <span className="icon icon-warning"></span> {formatMessage("Errors")}
                            <br />
                            <br />
                            <span className="error-number">{dataSource.errorCount?.errors}</span>
                        </div>
                    </div>
                    <div className="col-sm-4 pr-0">
                        <div className="error-container warnings">
                            <span className="icon icon-alarm"></span> {formatMessage("Warnings")}
                            <br />
                            <br />
                            <span className="error-number">{dataSource.errorCount?.warnings}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="h-70 vhm-dashboard-widget-content">
                <div className="latest-errors-error-grid h-100">

                    <div className="h-15">
                        <h2>{formatMessage("LatestErrors")}</h2>
                    </div>

                    <div className="h-85">
                        <DataGrid
                            dataSource={dataSource.vehicleErrorList}
                            remoteOperations={true}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            hoverStateEnabled={false}
                            rowAlternationEnabled={false}
                            showRowLines={true}
                            height={'100%'}
                            width={'100%'}
                        >
                            <Paging defaultPageSize={5} />
                            <Sorting mode="single" />
                            <Scrolling
                                mode="virtual"
                                rowRenderingMode="standard"
                                useNative={true}
                            />
                            <LoadPanel enabled={true} />
                            <Column
                                dataField="vehicleNumber"
                                dataType="string"
                                caption={formatMessage("VehicleNumber")}
                            />
                            <Column
                                dataField="lastChange"
                                dataType="string"
                                caption={formatMessage("LastChange")}
                                cellRender={cellRender_LastModification}
                                defaultSortOrder="desc"
                            />
                            <Column
                                dataField="manufacturerName"
                                dataType="string"
                                caption={formatMessage("Manufacturer")}
                            />
                            <Column
                                dataField="vehicleType"
                                dataType="string"
                                caption={formatMessage("VehicleModel")}
                            />
                            <Column
                                dataField="totalCritical"
                                dataType="bool"
                                caption={formatMessage("Critical")}
                                allowSorting={false}
                                width={70}
                                allowSearch={false}
                                cellRender={cellRender_Critical}
                            />
                            <Column
                                dataField="totalError"
                                dataType="bool"
                                caption={formatMessage("Error")}
                                allowSorting={false}
                                width={55}
                                cellRender={cellRender_Error}
                            />
                            <Column
                                dataField="totalWarning"
                                dataType="bool"
                                caption={formatMessage("Warning")}
                                allowSorting={false}
                                width={72}
                                cellRender={cellRender_Warning}
                            />
                            <Column
                                dataType="string"
                                caption={formatMessage("Lamps")}
                                allowSorting={false}
                                allowFiltering={false}
                                width={145}
                                cellRender={cellRender_Lamp}
                            />
                        </DataGrid>
                    </div>

                    

                </div>
            </div>
        </div>
    );
}

export default LatestErrors;