import React, { useContext } from 'react';
import TextBox from 'devextreme-react/text-box';
import LanguageContext from '../../utils/contexts/language-context';
import languageUtils from '../../utils/configs/languageUtils';
import globalMessages from '../../language/resources/global';
import feedbackMessages from '../../language/resources/feedback';
import HistoryService from '../../services/history.service';
import { formatMessage } from 'devextreme/localization';

function HistoryGridToolbar({
    dataGridRef,
    fleetIds,
    since,
    until,
    vehicleId,
    manufacturerId,
    componentTypeId,
    year,
    errorLevel,
    ecus,
    saveFilterPopup,
    resetAllFilters,
}) {
    const languageObj = useContext(LanguageContext);
    languageUtils.loadDictionaries([globalMessages, feedbackMessages], languageObj.language);

    function onValueChanged_Search(data) {
        dataGridRef.current.instance.searchByText(data.value);
    }

    function exportGrid(fileFormat) {
        dataGridRef.current.instance.beginCustomLoading();

        let locale = languageUtils.getLocale();

        HistoryService.export(fileFormat, locale, fleetIds, since, until, vehicleId, manufacturerId, componentTypeId, year, errorLevel, ecus)
            .then(response => {
                let responseData = response.data;

                const encodedString = Buffer.from(responseData).toString('base64');

                let fileTitle = `vhmHistory_${vehicleId != null ? vehicleId : "All"}_${since}_${until}`;
                let fileName = fileFormat === 0 ? fileTitle + ".pdf" : fileTitle + ".csv";

                var link = document.createElement('a');
                link.download = fileName;
                link.href = 'data:application/octet-stream;base64,' + encodedString;
                link.click();
                URL.revokeObjectURL(link.href);

                dataGridRef.current.instance.endCustomLoading();
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not export grid.");
                    dataGridRef.current.instance.endCustomLoading();
                }
            });
    }

    return (
        <React.Fragment>
            <div className="col-sm-8 toolbar-display">
                <div className="search-container">
                    <TextBox
                        placeholder={formatMessage("Search")}
                        width={'350px'}
                        showClearButton={true}
                        onValueChanged={onValueChanged_Search}
                    />
                </div>&nbsp;

                <button className="btn btn-secondary" onClick={saveFilterPopup} title={formatMessage("SaveFilter")}>
                    <span className="icon icon-save-custom-settings" /> {formatMessage("SaveFilter")}
                </button>&nbsp;

                <button className="btn btn-secondary" onClick={resetAllFilters} title={formatMessage("ResetAllFilters")}>
                    <span className="icon icon-save-custom-settings" /> {formatMessage("ResetAllFilters")}
                </button>
            </div>

            <div className="col-sm-4 toolbar-display right">

                <button className="btn btn-secondary" onClick={() => exportGrid(1)} title={languageUtils.stringFormat(formatMessage("Export_Extension"), "CSV")}>
                    <span className="icon icon-csv-export" /> {languageUtils.stringFormat(formatMessage("Export_Extension"), "CSV")}
                </button>&nbsp;

                <button className="btn btn-secondary" onClick={() => exportGrid(0)} title={languageUtils.stringFormat(formatMessage("Export_Extension"), "PDF")}>
                    <span className="icon icon-pdf-export" /> {languageUtils.stringFormat(formatMessage("Export_Extension"), "PDF")}
                </button>

            </div>
        </React.Fragment>
    );
}

export default HistoryGridToolbar;