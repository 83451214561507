import axiosInstance from '../utils/configs/axiosInstance';

const URL_API = "api/realtimecockpit";

const RealTimeCockpitService = {

    getOnlineVehicles: function () {
        return axiosInstance.get(
            `${URL_API}/vehicleslist`
        );
    },

    startStream: function (connectionId, vehicleId) {
        return axiosInstance.post(
            `${URL_API}/startstream`,
            {
                connectionId: connectionId,
                vehicleId: vehicleId
            }
        );
    },

    stopStream: function (connectionId, vehicleId) {
        return axiosInstance.post(
            `${URL_API}/stopstream`,
            {
                connectionId: connectionId,
                vehicleId: vehicleId
            }
        );
    },

    connect: function () {
        return axiosInstance.post(
            `${URL_API}/connect`
        );
    }

}

export default RealTimeCockpitService;