import React, { useEffect, useState } from 'react';
import Layout from './components/Layout/Layout';
import { Redirect, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import ControlBoard from './components/ControlBoard/ControlBoard';
import History from './components/History/History';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './external/ergosign/css/styles_bootstrap.css';
import './external/ergosign/css/styles_devextreme.css';
import TokenContext from './utils/contexts/token-context';
import tokenUtils from './utils/configs/tokenUtils';
import AuthenticationService from './services/authentication.service';
import axiosInstance from './utils/configs/axiosInstance';
import languageUtils from './utils/configs/languageUtils';
import unitUtils from './utils/configs/unitUtils';
import GlobalService from './services/global.service';
import LanguageContext from './utils/contexts/language-context';
import UnitContext from './utils/contexts/unit-context';
import RealTimeCockpit from './components/RealTimeCockpit/RealTimeCockpit';
import Fleet from './components/Fleet/Fleet';

let isRefreshing = false;

function App() {
    const appRoutes = [
        <Route key="route_default" exact path="/"><Redirect to="/dashboard" /></Route>,
        <Route key="route_dashboard" path="/dashboard" component={Dashboard} />,
        <Route key="route_controlboard" path="/controlboard" component={ControlBoard} />,
        <Route key="route_history" path="/history" component={History} />,
        <Route key="route_realtimecockpit" path="/realtimecockpit" component={RealTimeCockpit} />,
        <Route key="route_fleet" path="/fleet" component={Fleet} />
    ];

    const [language, setLanguage] = useState(languageUtils.getLocale());
    const [unit, setUnit] = useState(unitUtils.getUnit());
    const [token, setToken] = useState(tokenUtils.getUserToken());

    function setGlobalLanguage(lang) {
        setLanguage(lang);
        languageUtils.setLocale(lang);
    }


    useEffect(() => {
        if (languageUtils.getLocale() === null) {
            GlobalService.getDefaultCulture()
                .then(response => {
                    setGlobalLanguage(response.data);
                })
                .catch(error => {
                    console.error(error);
                    console.log("Setting default language as En-GB...");
                    setGlobalLanguage("en-GB");
                });
        }

        GlobalService.getMeasurementSystem()
            .then(response => {
                setGlobalUnit(response.data);
            })
            .catch(error => {
                console.error(error);
                console.log("Setting default units as metric...");
                setGlobalUnit("metric");
            });


    }, []);

    function setGlobalToken(tok) {
        setToken(tok);
        tokenUtils.storeUserToken(tok);
    }

    function setGlobalUnit(unit) {
        setUnit(unit);
        unitUtils.setUnit(unit);
    }

    axiosInstance.interceptors.request.use(
        request => {
            request.headers['Authorization'] = `Bearer ${tokenUtils.getUserToken()}`;
            return request;
        }
    );

    axiosInstance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response.status === 401 && originalRequest.url.endsWith('/refreshtoken')) {
                setGlobalToken(null);
            }

            if (error.response.status === 401 && !originalRequest._retry) {
                if (!isRefreshing) {
                    originalRequest._retry = true;
                    isRefreshing = true;

                    return AuthenticationService.refreshToken()
                        .then((tokenRefreshResponse) => {
                            setGlobalToken(tokenRefreshResponse.data.token);
                            return axiosInstance.request(error.config);
                        })
                        .finally(() => { isRefreshing = false });
                }
            } else {
                console.error(error);
            }

            return Promise.reject(error);
        }
    );

    const tokenStateObj = {
        token: token,
        setToken: setGlobalToken
    };

    const languageStateObj = {
        language: language,
        setLanguage: setGlobalLanguage
    };

    const unitStateObj = {
        unit: unit,
        setUnit: setGlobalUnit
    };

    return (
        <LanguageContext.Provider value={languageStateObj}>
            <UnitContext.Provider value={unitStateObj}>
                <TokenContext.Provider value={tokenStateObj}>
                {
                    token ? <Layout routes={appRoutes} /> : <Login />
                }
                </TokenContext.Provider>
            </UnitContext.Provider>
        </LanguageContext.Provider>
    );
}

export default App;