const applicationUtils = {

    renameKey: function (obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    },

    sortArray: function (array, key) {
        return array.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
    }

}

export default applicationUtils;