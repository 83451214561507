const globalMessages = {
    "en-GB": {
        // A
        "ActiveSince": "Active Since",
        "AmberWarning": "Amber Warning",
        "Any": "Any",
        "App_Name": "MOBILEvhm",
        "App_Version": "0.0",
        "Apply": "Apply",
        "ActiveVehicles": "Active Vehicles",

        // B
        "Battery": "Battery",
        "Blue": "Blue",
        "BrakeSwitch": "Brake Switch",

        // C
        "Close": "Close",
        "Condition": "Condition",
        "Contractor": "Contractor",
        "Contractors": "Contractors",
        "ControlBoard": "Control Board",
        "Component": "Component",
        "CreateNewScheduledExport": "Create New Scheduled Export",
        "Critical": "Critical",
        "CriticalErrors": "Critical Errors",

        // D
        "Dashboard": "Dashboard",
        "DataUsage": "Data Usage",
        "Date": "Date",
        "DateRange": "Date Range",
        "Delete": "Delete",
        "DeleteFilter": "Delete Filter",
        "Description": "Description",
        "Download": "Download",
        "Downloading": "Downloading",
        "DTC": "DTC",
        "Duration": "Duration",

        // E
        "ECUFilter": "ECU's",
        "EmailReport": "Email Report",
        "EndDate": "End Date",
        "Error": "Error",
        "ErrorCode": "Error Code",
        "Errors": "Errors",
        "ErrorStatus": "Error Status",
        "Export_Extension": "Export {0}", // {0}: Extension name

        // F
        "FaultCode": "Fault Code",
        "Filter": "Filter",
        "FilterName": "Filter",
        "Fleet": "Fleet",
        "FleetActivePercentage": "Activity %",
        "FleetName": "Fleet",
        "FleetOverview": "Fleet Overview",
        "FleetSelection": "Fleet Selection",
        "FMI": "FMI",
        "Frequency": "Frequency",

        // G
        "Global_Version": "Version",

        // H
        "History": "History",
        "Hour": "Hour",
        "Hours": "Hours",

        // I
        "Idling": "Idling",
        "Ignition": "Ignition",
        "Ignore": "Ignore",
        "Info": "Info",
        "IsoLamp": "ISO Lamp",

        // J

        // K

        // L
        "Lamp": "Lamp",
        "Lamps": "Lamps",
        "Lang_DE": "DE",
        "Lang_EN": "EN",
        "Lang_English": "English",
        "Lang_German": "German",
        "LatestErrors": "Latest Errors",
        "LastChange": "Last Change",
        "LastData": "Last Message",
        "LastMonth": "Last Month",
        "LastWeek": "Last Week",
        "LastXWeeks": "Last {0} Weeks", // {0}: Number of weeks
        "LiveNow": "Live Now",

        // M
        "MalfunctionIndicator": "Malfunction Indicator",
        "Manufacturer": "Manufacturer",
        "Manufacturers": "Manufacturers",
        "Measurements": "Measurements",
        "Message": "Message",
        "MIL": "MIL",
        "ModelYear": "Model Year",

        // N
        "Name": "Name",

        // O
        "Off": "Off",
        "Offline": "Offline",
        "On": "On",
        "Online": "Online",
        "OperationalId": "Operational ID",
        "Operators": "Operators",
        "OpHours": "Working Hours",
        "Orange": "Orange",

        // P
        "Parameter": "Parameter",
        "ParkingBrake": "Parking Brake",
        "Performance": "Performance",
        "ProtectLamp": "Protect Lamp",

        // Q

        // R
        "RealTimeCockpit": "Live View",
        "Recordings": "Recordings",
        "Red": "Red",
        "RedStop": "Red Stop",
        "Registered": "Registered",
        "Report": "Report",
        "ReportName": "Report Name",
        "Reports": "Reports",
        "ResetAllFilters": "Reset all Filters",

        // S
        "Satellite": "Satellite",
        "Save": "Save",
        "Save_X": "Save {0}", // {0}: Name of element
        "SaveFilter": "Save Filter",
        "SavedFilter": "Saved Filter",
        "Schedule": "Schedule",
        "ScheduledExports": "Scheduled Exports",
        "Search": "Search",
        "SearchVehicleNumberOrName": "Search Vehicle Number or Name",
        "SeenToday": "Seen Today",
        "Select": "Select",
        "Select_X": "Select {0}", // {0}: Name of element
        "ShowActiveErrors": "Errors",
        "ShowActiveFleet": "Show Active Only",
        "ShowErrors": "Show Errors Only",
        "SoC": "SoC",
        "SPN": "SPN",
        "Start": "Start", 
        "StartDate": "Start Date",
        "StartTime": "Start Time",
        "Status": "Status",
        "StreamingSince": "Streaming Since",
        "Streets": "Streets",
        "String": "String",
        "Stop": "Stop",

        // T
        "TechnicalId": "Technical ID",
        "ThisYear": "This Year",
        "To": "To",
        "Total": "Total",
        "TotalDistance": "Total Distance",
        "TotalErrors": "Total Errors",
        "Type": "Type",
        "TypeName": "Type Name",

        // U
        "Unassigned": "Unassigned",
        "Update": "Update",
        "Update_X": "Update {0}", // {0}: Name of element
        "Users": "Users",

        // V
        "Value": "Value",
        "VehicleName": "Vehicle",
        "VehicleDetails": "Vehicle Details",
        "VehicleErrorsGrid": "Vehicle Errors Grid",
        "VehicleFilter": "Vehicles",
        "VehiclesGrid": "Vehicles Grid",
        "VehicleId": "Vehicle Id",
        "VehicleModel": "Vehicle Model",
        "VehicleNumber": "Vehicle No.",

        // W
        "Warning": "Warning",
        "Warnings": "Warnings",
        "WorkingHours": "Working Hours",

        // X
        "XDays": "{0} Days", // {0}: Number of days
        "XHour": "{0} Hour", // {0}: Number of hours
        "XHours": "{0} Hours", // {0}: Number of hours

        // Y
        "Year": "Year",
        "YearOfConstruction": "Year of Construction",

        // Z
    },
    "de-DE": {
        // A
        "ActiveSince": "Aktiv Seit",
        "AmberWarning": "Warnung",
        "Any": "Alle",
        "App_Name": "MOBILEvhm",
        "App_Version": "0.0",
        "Apply": "Anwenden",
        "ActiveVehicles": "Aktive Fahrzeuge",

        // B
        "Battery": "Batterie",
        "Blue": "Blau",
        "BrakeSwitch": "Bremse",

        // C
        "Close": "Schließen",
        "Component": "Komponente",
        "Condition": "Bedingung",
        "Contractor": "Betreiber",
        "Contractors": "Betreiber",
        "ControlBoard": "Leitstand",
        "CreateNewScheduledExport": "Neuer zeitlicher Export",
        "Critical": "Kritische",
        "CriticalErrors": "Kritische Fehler",

        // D
        "Dashboard": "Dashboard",
        "DataUsage": "Datenverbrauch",
        "Date": "Datum",
        "DateRange": "Datumsbereich",
        "Delete": "Lösche/en",
        "DeleteFilter": "Lösche Filter",
        "Description": "Beschreibung",
        "Download": "Download",
        "Downloading": "Downloading",
        "DTC": "DTC",
        "Duration": "Dauer",

        // E
        "ECUFilter": "Steuergeräte",
        "EmailReport": "E-Mail Report",
        "EndDate": "Ende",
        "Error": "Fehler",
        "ErrorCode": "Fehlerkode",
        "Errors": "Fehler",
        "ErrorStatus": "Fehlerstatus",
        "Export_Extension": "Export {0}",

        // F
        "FaultCode": "Fehlercode",
        "Filter": "Filter",
        "FilterName": "Filter",
        "Fleet": "Flotte",
        "FleetActivePercentage": "Aktivität %",
        "FleetName": "Flotte",
        "FleetOverview": "Flottenübersicht",
        "FleetSelection": "Flottenauswahl",
        "FMI": "FMI",
        "Frequency": "Häufigkeit",

        // G
        "Global_Version": "Version",

        // H
        "History": "Historie",
        "Hour": "Stunde",
        "Hours": "Stunden",

        // I
        "Idling": "Leerlauf",
        "Ignition": "Zündung",
        "Ignore": "Ignoriere",
        "Info": "Info",
        "IsoLamp": "ISO-Lampe",

        // J

        // K

        // L
        "Lamp": "Warnlamp",
        "Lamps": "Warnlampen",
        "Lang_DE": "DE",
        "Lang_EN": "EN",
        "Lang_English": "Englisch",
        "Lang_German": "Deutsch",
        "LatestErrors": "Neueste Fehler",
        "LastChange": "Letzte Änderung",
        "LastData": "Letzte Nachricht",
        "LastMonth": "Letzter Monat",
        "LastWeek": "Letzte Woche",
        "LastXWeeks": "Letzte {0} Wochen",
        "LiveNow": "Jetzt aktiv",

        // M
        "MalfunctionIndicator": "Störungsanzeige",
        "Manufacturer": "Hersteller",
        "Manufacturers": "Hersteller",
        "Measurements": "Messungen",
        "Message": "Nachricht",
        "MIL": "MIL",
        "ModelYear": "Baujahr",

        // N
        "Name": "Name",

        // O
        "Off": "Aus",
        "Offline": "Offline",
        "On": "An",
        "Online": "Online",
        "OperationalId": "Operative ID",
        "Operators": "Betreiber",
        "OpHours": "Betriebsstunden",
        "Orange": "Orange",

        // P
        "Parameter": "Parameter",
        "ParkingBrake": "Feststellbremse",
        "Performance": "Leistung",
        "ProtectLamp": "Hinweislampe",

        // Q

        // R
        "RealTimeCockpit": "Live View",
        "Recordings": "Aufzeichnungen",
        "Red": "Rot",
        "RedStop": "Schwerer Fehler",
        "Registered": "Registriert",
        "Report": "Bericht",
        "ReportName": "Bericht",
        "Reports": "Berichte",
        "ResetAllFilters": "Alle Filter zurücksetzen",

        // S
        "Satellite": "Satellit",
        "Save": "Speichern",
        "Save_X": "Speichern {0}",
        "SaveFilter": "Filter speichern",
        "SavedFilter": "Gespeicherte Filter",
        "Schedule": "Zeitplanung",
        "ScheduledExports": "Geplante Berichte",
        "Search": "Suche",
        "SearchVehicleNumberOrName": "Fahrzeugnummer oder Name suchen",
        "SeenToday": "Heute aktiv",
        "Select": "Auswahl",
        "Select_X": "Auswahl {0}",
        "ShowActiveFleet": "Filter nach Aktive",
        "ShowActiveErrors": "Fehler",
        "ShowErrors": "Filter nach Fehler",
        "SoC": "SoC",
        "SPN": "SPN",
        "Start": "Start",
        "StartDate": "Beginn",
        "StartTime": "Beginn",
        "Status": "Status",
        "StreamingSince": "Gestartet um",
        "Streets": "Straßen",
        "String": "Zeichenkette",
        "Stop": "Stopp",

        // T
        "TechnicalId": "Technische ID",
        "ThisYear": "Fahrzeuge",
        "To": "An",
        "Total": "Total",
        "TotalDistance": "Kilometerstand",
        "TotalErrors": "Fehler total",
        "Type": "Typ",
        "TypeName": "Typname",

        // U
        "Unassigned": "Nicht Zugewiesen",
        "Update": "Aktualisiere",
        "Update_X": "Aktualisiere {0}",
        "Users": "Benutzer",

        // V
        "Value": "Wert",
        "VehicleName": "Fahrzeug",
        "VehicleDetails": "Fahrzeugdetails",
        "VehicleErrorsGrid": "Fahrzeugfehlertabelle",
        "VehicleFilter": "Fahrzeuge",
        "VehiclesGrid": "Fahrzeugtabelle",
        "VehicleId": "Fahrzeug Id",
        "VehicleModel": "Fahrzeugmodell",
        "VehicleNumber": "Fahrzeug-Nr.",

        // W
        "Warning": "Warnung",
        "Warnings": "Warnungen",
        "WorkingHours": "Betriebsstunden",

        // X
        "XDays": "{0} Tage",
        "XHour": "{0} Stunde",
        "XHours": "{0} Stunden",

        // Y
        "Year": "Jahr",
        "YearOfConstruction": "Baujahr",

        // Z
    }
};

export default globalMessages;