import React from 'react';
import { formatMessage } from 'devextreme/localization';
import './controlBoard.css';
import languageUtils from '../../utils/configs/languageUtils';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import ArrayStore from 'devextreme/data/array_store';
import DataGrid, { Column, Scrolling, FilterRow, Paging, Sorting, Lookup } from 'devextreme-react/data-grid';
import ControlBoardService from '../../services/controlBoard.service';
import { LoadPanel } from 'devextreme-react/load-panel';

function VehiclesGrid({
    vehiclesGridRef,
    gridDataSource,
    selectedVehicle,
    selectedVehicleRowIndex,
    setSelectedVehicle,
    setSelectedVehicleRowIndex,
    setVehicleDetails,
    workingHours,
}) {

    function getHours(maximum) {
        let hours = [];
        let i = 0
        let hour = 0;
        while ((hour = Math.pow(2, i)) < maximum) {
            hours.push(hour)
            i++;
        }

        hours.push(maximum)
        return hours;
    }

    const hourThresholds = new ArrayStore({
        data: getHours(workingHours).map(key => ({ value: key, text: key + "h" }))
    });

    function onEditorPreparing(e) {
        if ((e.dataField === 'totalCritical' || e.dataField === 'totalError' || e.dataField === 'totalWarning')
            && e.parentType === 'filterRow') {
            e.editorName = 'dxCheckBox';

            let setValue = e.editorOptions.value;

            switch (setValue) {
                case "true":
                    e.editorOptions.value = true;
                    break;

                case "false":
                    e.editorOptions.value = false;
                    break;
            }
        }
    }

    function onFocusedRowChanged(e) {
        if (e.row == null) {
            return;
        }

        let selectedKey = e.row.key;

        setSelectedVehicle(e.component.option('focusedRowKey'));
        setSelectedVehicleRowIndex(e.row.rowIndex);

        ControlBoardService.getVehicleDetails(selectedKey)
            .then(response => {
                let responseData = response.data;
                setVehicleDetails(responseData);
            })
            .catch(error => {
                if (error.response !== undefined && error.response.status !== 401) {
                    console.error("Could not retrieve data for Vehicle Details.");
                }
            });
    }

    function cellRender_LastModification(data) {
        let now = moment();
        let lastModification = moment(data.value);

        let calculation = moment(now, "DD/MM/YYYY HH:mm").diff(moment(lastModification, "DD/MM/YYYY HH:mm"));

        let result = humanizeDuration(calculation, { language: languageUtils.getLocale().split("-")[0], largest: 2, round: true, fallbacks: ["en"] });

        return (
            <div className="w-100 text-left" title={result}>{result}</div>
        );
    }

    function calculateFilterExpression_LastModification(value, selectedFilterOperation, target) {
        selectedFilterOperation = '>=';
        target = "lastModification";

        let lastModification = (moment().subtract('h', value)).toDate();
        let fullSecondsTime = moment(lastModification).format('YYYY-MM-DD[T]HH:mm:ss');

        return [[target, selectedFilterOperation, fullSecondsTime]];
    }

    function cellRender_Critical(cellData) {
        let cellContent = <span />

        let totalCriticalErrors = cellData.row.data.totalCritical;
        let activeCriticalErrors = cellData.row.data.activeCritical;

        if (activeCriticalErrors === 0 && totalCriticalErrors === 0) {
            cellContent = <div />
        } else {
            if (activeCriticalErrors > 0) {
                cellContent = <div className="number-display critical active">{activeCriticalErrors}</div>;
            } else {
                cellContent = <div className="number-display critical">{totalCriticalErrors}</div>;
            }
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function cellRender_Error(cellData) {
        let cellContent = <span />

        let totalErrorErrors = cellData.row.data.totalError;
        let activeErrorErrors = cellData.row.data.activeError;

        if (activeErrorErrors === 0 && totalErrorErrors === 0) {
            cellContent = <div />
        } else {
            if (activeErrorErrors > 0) {
                cellContent = <div className="number-display error active">{activeErrorErrors}</div>;
            } else {
                cellContent = <div className="number-display error">{totalErrorErrors}</div>;
            }
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function cellRender_Warning(cellData) {
        let cellContent = <span />

        let totalWarningErrors = cellData.row.data.totalWarning;
        let activeWarningErrors = cellData.row.data.activeWarning;

        if (activeWarningErrors === 0 && totalWarningErrors === 0) {
            cellContent = <div />
        } else {
            if (activeWarningErrors > 0) {
                cellContent = <div className="number-display warning active">{activeWarningErrors}</div>;
            } else {
                cellContent = <div className="number-display warning">{totalWarningErrors}</div>;
            }
        }

        return (
            <div className="text-center error-display">
                {cellContent}
            </div>
        );
    }

    function calculateFilterExpression_Critical(value, selectedFilterOperations, target) {
        if (target === 'filterRow' && value === true) {
            return [["totalCritical", '>', 0]];
        }

        return;
    }

    function calculateFilterExpression_Error(value, selectedFilterOperations, target) {
        if (target === 'filterRow' && value === true) {
            return [["totalError", '>', 0]];
        }

        return;
    }

    function calculateFilterExpression_Warning(value, selectedFilterOperations, target) {
        if (target === 'filterRow' && value === true) {
            return [["totalWarning", '>', 0]];
        }

        return;
    }

    function cellRender_Lamp(cellData) {
        let cellContent = [];

        let amberWarning = cellData.row.data.amberWarning;
        let malFunctionIndicator = cellData.row.data.malFunctionIndicator;
        let protectLamp = cellData.row.data.protectLamp;
        let redStop = cellData.row.data.redStop;

        if (amberWarning) {
            cellContent.push(<span key="amberWarning" className="p-1"><span className="icon icon-warning latest-errors-icon warning" title={formatMessage("AmberWarning")} /></span>);
        }

        if (malFunctionIndicator) {
            cellContent.push(<span key="malFunctionIndicator" className="p-1"><span className="icon icon-error latest-errors-icon mil" title={formatMessage("MalfunctionIndicator")} /></span>);
        }

        if (protectLamp) {
            cellContent.push(<span key="protectLamp" className="p-1"><span className="icon icon-alarm latest-errors-icon protect" title={formatMessage("ProtectLamp")} /></span>);
        }

        if (redStop) {
            cellContent.push(<span key="redStop" className="p-1"><span className="icon icon-warning latest-errors-icon stop" title={formatMessage("RedStop")} /></span>);
        }

        return (
            <div className="text-center">
                {cellContent}
            </div>
        );
    }

    return (
        <React.Fragment>

            <DataGrid
                ref={vehiclesGridRef}
                dataSource={gridDataSource}
                remoteOperations={true}
                columnAutoWidth={false}
                columnHidingEnabled={false}
                showColumnLines={false}
                hoverStateEnabled={false}
                rowAlternationEnabled={false}
                showRowLines={true}
                height={'100%'}
                width={'100%'}
                key={'componentId'}
                focusedRowEnabled={true}
                focusedRowKey={selectedVehicle}
                focusedRowIndex={selectedVehicleRowIndex}
                onFocusedRowChanged={onFocusedRowChanged}
                onEditorPreparing={onEditorPreparing}
            >
                <Paging defaultPageSize={20} />
                <Sorting mode="single" />
                <Scrolling
                    mode="virtual"
                    rowRenderingMode="standard"
                    useNative={true}
                    legacyMode={true}
                />
                <LoadPanel enabled={true} />
                <FilterRow
                    visible={true}
                    showOperationChooser={false}
                />
                <Column
                    dataField="contractorName"
                    dataType="string"
                    caption={formatMessage("Contractor")}
                />
                <Column
                    dataField="vehicleNo"
                    dataType="string"
                    caption={formatMessage("VehicleNumber")}
                />
                <Column
                    dataField="lastModification"
                    dataType="string"
                    caption={formatMessage("LastChange")}
                    allowHeaderFiltering="false"
                    cellRender={cellRender_LastModification}
                    calculateFilterExpression={calculateFilterExpression_LastModification}
                    defaultSortOrder="desc"
                >
                    <Lookup
                        dataSource={hourThresholds}
                        valueExpr="value"
                        displayExpr="text"
                    />
                </Column>
                <Column
                    dataField="manufacturerLabel"
                    dataType="string"
                    caption={formatMessage("Manufacturer")}
                />
                <Column
                    dataField="componentTypeLabel"
                    dataType="string"
                    caption={formatMessage("VehicleModel")}
                />
                <Column
                    dataField="totalCritical"
                    dataType="bool"
                    caption={formatMessage("Critical")}
                    allowSorting={false}
                    width={70}
                    allowSearch={false}
                    cellRender={cellRender_Critical}
                    calculateFilterExpression={calculateFilterExpression_Critical}
                />
                <Column
                    dataField="totalError"
                    dataType="bool"
                    caption={formatMessage("Error")}
                    allowSorting={false}
                    width={55}
                    cellRender={cellRender_Error}
                    calculateFilterExpression={calculateFilterExpression_Error}
                />
                <Column
                    dataField="totalWarning"
                    dataType="bool"
                    caption={formatMessage("Warning")}
                    allowSorting={false}
                    width={72}
                    cellRender={cellRender_Warning}
                    calculateFilterExpression={calculateFilterExpression_Warning}
                />
                <Column
                    dataType="string"
                    caption={formatMessage("Lamps")}
                    allowSorting={false}
                    allowFiltering={false}
                    width={145}
                    cellRender={cellRender_Lamp}
                />
            </DataGrid>

        </React.Fragment>
    );

}

export default VehiclesGrid;