import notify from "devextreme/ui/notify";

const displayTime = 4000; // Change this time if needed

const devextremeUtils = {

    displayNotification: function (text, type) {
        if (type !== 'success' && type !== 'info' && type !== 'error' && type !== 'warning') {
            console.error("Notification type = " + type + " not available.");
        }

        notify({
            message: text,
            type: type,
            position: {
                at: 'right top',
                my: 'right top'
            },
            displayTime: displayTime,
            closeOnClick: true,
        });
    },

    isNotEmpty: function (value) {
        return value !== undefined && value !== null && value !== '';
    },

    selectBox_displayNativeScrolling: function (e) {
        let list = e.component._list;
        if (!list.option('useNativeScrolling')) {
            list.option('useNativeScrolling', true);
            list._scrollView.option('useNative', true);
            list.reload();
        }
    }

}

export default devextremeUtils;