import React, { useContext } from 'react';
import PageTitleContext from '../../utils/contexts/pageTitle-context';

function PageHeader() {
    const pageTitleObj = useContext(PageTitleContext);
    const initLogo = require('../../utils/images/logoINIT.png');

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 align-self-center">
                    <h1>{pageTitleObj.pageTitle}</h1>
                </div>
                <div className="col-sm-6 text-right align-self-center">
                    <img src={initLogo} className="img-fluid vhm-init-logo" alt="INIT" />
                </div>
            </div>
        </div>
    );
}

export default PageHeader;